import { Box, Stack, Tab, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DocumentiTable from './components/DocumentiTable';
import { useState } from 'react';
import { downloadDocumento, downloadFattura } from '../../redux/noleggi/api';
import { useDispatch } from 'react-redux';
import { showSnackbar } from '../../redux/app/slice';
import LoadingMask from '../../components/LoadingMask';
import { downloadBlob } from '../../utils/download';
import FattureTable from './components/FattureTable';
import { TabContext, TabList, TabPanel } from '@mui/lab';

export default function DocumentiFatture() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const onDownloadDocumento = async documento => {
    setIsLoading(true);
    try {
      const pdfData = await downloadDocumento(documento.id);
      downloadBlob(pdfData, 'application/pdf', documento.nome + '.pdf');
    } catch (error) {
      dispatch(showSnackbar({ message: error, severity: 'error' }));
    } finally {
      setIsLoading(false);
    }
  };

  const onDownloadFattura = async fattura => {
    setIsLoading(true);
    try {
      const pdfData = await downloadFattura(fattura.id);
      downloadBlob(pdfData, 'application/pdf', fattura.titolo + '.pdf');
    } catch (error) {
      dispatch(showSnackbar({ message: error, severity: 'error' }));
    } finally {
      setIsLoading(false);
    }
  };

  const onOpenRichiesta = documento => {
    navigate('/noleggi/noleggio/edit', {
      state: { id: documento.id_richiesta },
    });
  };

  const [tabValue, setTabValue] = useState('documenti');
  const onTabChange = newValue => {
    setTabValue(newValue);
  };
  const tabs = [
    {
      key: 'documenti',
      text: 'Documenti',
    },
    {
      key: 'fatture',
      text: 'Fatture',
    },
  ];

  return (
    <Box sx={{ pt: 2 }}>
      {isLoading && <LoadingMask />}

      <TabContext value={tabValue}>
        <TabList
          variant="scrollable"
          scrollButtons="auto"
          value={tabValue}
          onChange={(event, newValue) => onTabChange(newValue)}
          textColor="primary"
          TabIndicatorProps={{ style: { display: 'none' } }}
          indicatorColor="transparent"
          sx={{
            borderBottom: '1px solid',
            borderColor: 'divider',
            px: 3,
            mx: 0,
          }}
        >
          {tabs.map((tab, index) => (
            <Tab
              key={index}
              sx={{
                m: 1,
                mb: 0,
              }}
              label={
                <Box display="flex" alignItems="center">
                  {tab.text}
                </Box>
              }
              value={tab.key}
            />
          ))}
        </TabList>

        <TabPanel value="documenti" sx={{ pl: 4 }}>
          <Stack
            direction="row"
            spacing={2}
            sx={{ mb: 2, justifyContent: 'space-between' }}
          >
            <Box>
              <Typography component="h2" variant="h4">
                Documenti
              </Typography>
              <Typography variant="body1">
                Visualizza tutti i documenti relativi alle pratiche di noleggio.
              </Typography>
            </Box>
          </Stack>

          <DocumentiTable
            pageSize={10}
            onOpenRichiesta={onOpenRichiesta}
            onDownload={onDownloadDocumento}
          />
        </TabPanel>
        <TabPanel value="fatture" sx={{ pl: 4 }}>
          <Stack
            direction="row"
            spacing={2}
            sx={{ mb: 2, justifyContent: 'space-between' }}
          >
            <Box>
              <Typography component="h2" variant="h4">
                Fatture
              </Typography>
              <Typography variant="body1">
                Visualizza tutte le fatture emesse per i noleggi.
              </Typography>
            </Box>
          </Stack>

          <FattureTable
            pageSize={10}
            onOpenRichiesta={onOpenRichiesta}
            onDownload={onDownloadFattura}
          />
        </TabPanel>
      </TabContext>
    </Box>
  );
}
