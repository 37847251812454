const formatNumber = (value, symbol) => {
  return (
    new Intl.NumberFormat('it-IT', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value) + (symbol ? symbol : '')
  );
};

const formatCurrency = value => {
  return new Intl.NumberFormat('it-IT', {
    style: 'currency',
    currency: 'EUR',
  }).format(value);
};

export { formatNumber, formatCurrency };
