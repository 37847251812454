import { Box, Chip, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { DataGrid } from '@mui/x-data-grid';
import { formatCurrency } from '../../../utils/formatNumber';
import { formatStringDate } from '../../../utils/formadDate';
import { Paid } from '@mui/icons-material';

export default function ScadenziarioTable({ rows }) {
  const columns = [
    { field: 'ordine', headerName: 'N. Rata', flex: 0.5, maxWidth: 100 },
    {
      field: 'flg_rescissione',
      headerName: 'Causale',
      flex: 1,
      renderCell: params => {
        return (
          <Stack
            sx={{
              justifyContent: 'center',
              height: '100%',
              textAlign: 'center',
            }}
          >
            <Typography variant="body2">
              {params.row.flg_rescissione ? 'Penale Rescissione' : 'Rata'}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: 'data',
      headerName: 'Data',
      maxWidth: 150,
      flex: 1,
      valueFormatter: value => formatStringDate(value),
    },
    {
      field: 'importo',
      headerName: 'Importo',
      headerClassName: 'align-right',
      maxWidth: 150,
      flex: 1,
      renderCell: params => {
        return (
          <Stack
            sx={{
              justifyContent: 'center',
              height: '100%',
              textAlign: 'right',
            }}
          >
            <Typography variant="body2">
              {formatCurrency(params.row.importo)}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: 'flg_pagato',
      headerName: 'Pagato',
      minWidth: 150,
      renderCell: params => {
        return (
          <Stack
            sx={{
              justifyContent: 'center',
              height: '100%',
              alignItems: 'center',
            }}
          >
            <Box>
              {params.value ? (
                <Chip label="Pagato" color="success" size="small" />
              ) : (
                <>
                  {new Date(params.row.data) < new Date() ? (
                    <Chip label="Non Pagato" color="error" size="small" />
                  ) : (
                    <></>
                  )}
                </>
              )}
            </Box>
          </Stack>
        );
      },
    },
  ];

  return (
    <Grid container spacing={2} columns={12}>
      <Grid size={{ xs: 12, md: 12, lg: 12 }} sx={{ width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          getRowClassName={params =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
          }
          initialState={{
            pagination: { paginationModel: { pageSize: 100 } },
          }}
          autoHeight
          disableColumnMenu
          disableColumnSorting
          disableColumnResize
          density="standard"
          sx={{
            '.MuiDataGrid-footerContainer': { display: 'none' },
          }}
        />
      </Grid>
    </Grid>
  );
}
