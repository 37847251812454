import { createSlice } from '@reduxjs/toolkit';
import { getNotifiche } from './operations';
import {
  createAsyncState,
  handlePending,
  handleFulfilled,
  handleRejected,
} from '../base';

const initialState = {
  notifiche: [],
  getNotificheAction: createAsyncState(),
};

const notificheSlice = createSlice({
  name: 'notifiche',
  initialState,
  reducers: {
    clearAllNotifiche(state) {
      state.notifiche = [];
    },
    clearNotifica(state, action) {
      state.notifiche = state.notifiche.filter(
        notifica => notifica.id !== action.payload
      );
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getNotifiche.pending, state => {
        handlePending(state.getNotificheAction);
      })
      .addCase(getNotifiche.fulfilled, (state, action) => {
        state.notifiche = action.payload;
        handleFulfilled(state.getNotificheAction, action);
      })
      .addCase(getNotifiche.rejected, (state, action) => {
        handleRejected(state.getNotificheAction, action);
      });
  },
});

export const { clearAllNotifiche, clearNotifica } = notificheSlice.actions;

export const notificheReducer = notificheSlice.reducer;
