import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import OutlinedInput from '@mui/material/OutlinedInput';
import { resetPassword } from '../../../redux/auth/api';
import ActionErrorMessage from '../../../components/ActionErrorMessage';
import { LoadingButton } from '@mui/lab';
import { useEffect, useState } from 'react';

function ForgotPassword({ open, handleClose }) {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async () => {
    setError(null);
    if (email === '') {
      setError('Campo obbligatorio');
      return;
    }

    setIsLoading(true);
    try {
      await resetPassword({ email: email });
      handleClose();
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setEmail('');
    setError(null);
  }, [open]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Reset password</DialogTitle>
      <DialogContent
        sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}
      >
        <DialogContentText>
          Inserisci l&apos;indirizzo email del tuo account e ti invieremo un
          link per reimpostare la password.
        </DialogContentText>
        <OutlinedInput
          autoFocus
          required
          margin="dense"
          value={email}
          onChange={e => setEmail(e.target.value)}
          name="email"
          label="Email address"
          placeholder="Email address"
          type="email"
          fullWidth
        />
        <ActionErrorMessage error={error} />
      </DialogContent>
      <DialogActions sx={{ pb: 3, px: 3 }}>
        <Button onClick={handleClose}>Annulla</Button>
        <LoadingButton
          variant="contained"
          onClick={handleSubmit}
          loading={isLoading}
        >
          Continua
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

ForgotPassword.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default ForgotPassword;
