import {
  Box,
  Button,
  Card,
  Grid2 as Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import useAuth from '../../hooks/useAuth';
import IntestazioneNoleggioDashboard from '../Noleggi/components/IntestazioneNoleggioDashboard';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowForwardIos, Search } from '@mui/icons-material';
import NoleggiTable from '../Noleggi/components/NoleggiTable';
import useNoleggiTable from '../Noleggi/hook/useNoleggiTable';
import HighlightText from '../../components/HighlightText';
import useOperazioniData from '../Report/hook/useOperazioniData';
import LoadingMask from '../../components/LoadingMask';
import TotaleComponent from '../Report/components/TotaleComponent';
import GraficoTorta from '../Report/components/GraficoTorta';
import useInoltriData from '../Report/hook/useInoltriData';
import IntestazioneNoleggioVerticale from '../Noleggi/components/IntestazioneNoleggioVerticale';
import GraficoBarre from '../Report/components/GraficoBarre';
import Slider from './components/Slider';
import { useDispatch } from 'react-redux';
import { showSnackbar } from '../../redux/app/slice';

export default function DashboardFornitore() {
  const theme = useTheme();
  const matchXl = useMediaQuery(theme.breakpoints.up('xl'));
  const matchLg = useMediaQuery(theme.breakpoints.up('lg'));

  const { user } = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [noleggio, setNoleggio] = useState({ durata_mesi: 24, importo: 0 });
  const { recenti, confermati, filter, getNoleggiAction } = useNoleggiTable([
    'confermati',
    'recenti',
  ]);
  const { isLoadingOperazioniData, operazioniData } = useOperazioniData();
  const {
    isLoadingInoltriData,
    inoltriData,
    dataInizioInoltri,
    dataFineInoltri,
  } = useInoltriData();

  const onCompletaProposta = () => {
    if (noleggio.importo == 0 || noleggio.importo == null) {
      dispatch(
        showSnackbar({
          message: 'Inserire un importo valido',
          severity: 'error',
        })
      );
      return;
    }

    navigate('/noleggi/noleggio/completa', {
      state: {
        durata_mesi: noleggio.durata_mesi,
        importo: noleggio.importo,
        id_stato: 'DC',
      },
    });
  };

  const onVisualizzaReport = () => {
    navigate('/report');
  };

  const Grafici = () => {
    return (
      <Grid size={{ xs: 12, sm: 12, md: 12, xl: 5 }}>
        <Card sx={{ backgroundColor: 'background.default', height: '100%' }}>
          <Stack flexDirection="row" justifyContent="space-between">
            <Box>
              <Stack flexDirection="row">
                <Typography sx={{ fontFamily: 'Poppins-Bold' }}>
                  Visualizza&nbsp;
                  <Typography
                    component={'span'}
                    variant="body1"
                    color="primary"
                    sx={{ fontFamily: 'Poppins-Bold' }}
                  >
                    Report Pratiche
                  </Typography>
                </Typography>
              </Stack>
              <Typography variant="caption">
                Tutti i report sono visibili nella sezione pratiche
              </Typography>
            </Box>
            <Button
              variant="contained"
              color="white"
              size="small"
              onClick={onVisualizzaReport}
              endIcon={<ArrowForwardIos />}
              sx={{ fontFamily: 'Poppins-Bold' }}
            >
              VISUALIZZA REPORT
            </Button>
          </Stack>
          <Box>
            <Stack sx={{ flex: 1, position: 'relative', minHeight: 250 }}>
              <Slider>
                <>
                  {isLoadingOperazioniData ? <LoadingMask /> : <></>}
                  {operazioniData && (
                    <Stack>
                      <Stack sx={{ flex: 1, marginTop: 2, marginBottom: 2 }}>
                        <TotaleComponent
                          titolo={'Tot. Operazioni Attive'}
                          value={operazioniData.attive?.counter}
                        />
                      </Stack>
                      <Stack sx={{ flex: 1, marginTop: 2, marginBottom: 2 }}>
                        <GraficoTorta
                          labelPos="left"
                          small={true}
                          value={operazioniData.attive?.stati}
                        />
                      </Stack>
                    </Stack>
                  )}
                </>

                <>
                  {isLoadingInoltriData ? <LoadingMask /> : <></>}
                  {inoltriData && (
                    <Stack>
                      <Stack sx={{ flex: 1, marginTop: 2, marginBottom: 2 }}>
                        <TotaleComponent
                          titolo={'Esito Pratiche Inoltrate'}
                          sottotitolo={
                            'Report dal ' +
                            dataInizioInoltri?.format('DD/MM/YYYY') +
                            ' al ' +
                            dataFineInoltri?.format('DD/MM/YYYY')
                          }
                          value={inoltriData.counter}
                        />
                      </Stack>
                      <Stack sx={{ flex: 1, marginTop: 2, marginBottom: 2 }}>
                        <GraficoTorta
                          labelPos="bottom"
                          labelAlign="center"
                          small={true}
                          value={inoltriData.confermate_declinate}
                        />
                      </Stack>
                    </Stack>
                  )}
                </>

                <>
                  {isLoadingInoltriData ? <LoadingMask /> : <></>}
                  {inoltriData && (
                    <Stack>
                      <Stack sx={{ flex: 1, marginTop: 2, marginBottom: 2 }}>
                        <TotaleComponent
                          titolo={'Status Pratiche Confermate'}
                          sottotitolo={
                            'Report dal ' +
                            dataInizioInoltri?.format('DD/MM/YYYY') +
                            ' al ' +
                            dataFineInoltri?.format('DD/MM/YYYY')
                          }
                          value={
                            inoltriData.confermate_declinate?.CONF?.counter
                          }
                        />
                      </Stack>
                      <Stack sx={{ flex: 1, marginTop: 2, marginBottom: 2 }}>
                        <GraficoBarre value={inoltriData.stati} horizontal />
                      </Stack>
                    </Stack>
                  )}
                </>
              </Slider>
            </Stack>
          </Box>
        </Card>
      </Grid>
    );
  };

  return (
    <Box sx={{ backgroundColor: 'background.paper' }}>
      <Grid container spacing={1}>
        <Grid size={{ xs: 12, sm: 12, md: 12, xl: 7 }}>
          <Card sx={{ backgroundColor: 'background.default', height: '100%' }}>
            <Typography component="h2" variant="h4">
              Benvenuto {user?.ragione_sociale},
            </Typography>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              sx={{ mb: 1, mt: 4 }}
            >
              <Box>
                <Stack flexDirection="row">
                  <Typography sx={{ fontFamily: 'Poppins-Bold' }}>
                    Effettua un&nbsp;
                    <Typography
                      component="span"
                      variant="body1"
                      sx={{ fontFamily: 'Poppins-Bold' }}
                      color="primary"
                    >
                      Preventivo di Noleggio
                    </Typography>
                  </Typography>
                </Stack>
                <Typography variant="caption">
                  Completa la proposta di noleggio per avere il costo totale del
                  servizio
                </Typography>
              </Box>

              {matchLg && (
                <Button
                  variant="contained"
                  size="small"
                  onClick={onCompletaProposta}
                  sx={{ fontFamily: 'Poppins-Bold' }}
                >
                  COMPLETA PROPOSTA
                </Button>
              )}
            </Stack>

            {matchLg ? (
              <IntestazioneNoleggioDashboard
                noleggio={noleggio}
                setNoleggio={setNoleggio}
              />
            ) : (
              <>
                <IntestazioneNoleggioVerticale
                  noleggio={noleggio}
                  setNoleggio={setNoleggio}
                  importoRow={true}
                  assicurazioneRow={true}
                  durataRow={matchLg ? false : true}
                />
                <Stack
                  sx={{ mt: 2 }}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={onCompletaProposta}
                      sx={{ fontFamily: 'Poppins-Bold' }}
                    >
                      COMPLETA PROPOSTA
                    </Button>
                  </Box>
                </Stack>
              </>
            )}
          </Card>
        </Grid>

        {matchXl ? <Grafici /> : <></>}

        {(confermati?.noleggiConfermate?.length ?? 0) > 0 && (
          <>
            <Grid size={{ xs: 12, sm: 12, md: 12, lg: 12 }}>
              <Card
                sx={{ backgroundColor: 'background.default', height: '100%' }}
              >
                <Stack
                  flexDirection="row"
                  alignItems={'center'}
                  justifyContent={'space-between'}
                >
                  <Stack flexDirection="row" alignItems={'center'}>
                    <Box>
                      <Stack flexDirection="row">
                        <Typography sx={{ fontFamily: 'Poppins-Bold' }}>
                          Visualizza&nbsp;
                          <Typography
                            component={'span'}
                            variant="body1"
                            color="primary"
                            sx={{ fontFamily: 'Poppins-Bold' }}
                          >
                            le Pratiche Confermate
                          </Typography>
                        </Typography>
                      </Stack>
                      <Typography variant="caption">
                        Organizza e pianifica la consegna del materiale
                      </Typography>
                    </Box>
                    {(confermati?.noleggiConfermate?.length ?? 0) > 0 && (
                      <HighlightText
                        text={confermati?.noleggiConfermate.length}
                        backgroundColor="success.main"
                        color="white"
                        height={30}
                        width={30}
                      />
                    )}
                  </Stack>
                  <TextField
                    onChange={e => filter(e.target.value, 'confermati')}
                    sx={{ mb: 1 }}
                    placeholder="Cerca"
                    size="small"
                    variant="outlined"
                    slotProps={{
                      input: {
                        startAdornment: (
                          <InputAdornment position="start">
                            <Search sx={{ width: 20, height: 20 }} />
                          </InputAdornment>
                        ),
                      },
                    }}
                  />
                </Stack>
                <Box sx={{ mt: 1 }}>
                  <NoleggiTable
                    noleggi={confermati?.noleggiConfermate ?? []}
                    isLoading={getNoleggiAction?.loading ?? false}
                    initialState={{
                      pagination: { paginationModel: { pageSize: 5 } },
                    }}
                    pageSizeOptions={[5, 10, 20, 50]}
                    showSearch={false}
                  />
                </Box>
              </Card>
            </Grid>
          </>
        )}

        <Grid size={{ xs: 12, sm: 12, md: 12, lg: 12 }}>
          <Card sx={{ backgroundColor: 'background.default', height: '100%' }}>
            <Stack
              flexDirection="row"
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Box>
                <Stack flexDirection="row">
                  <Typography sx={{ fontFamily: 'Poppins-Bold' }}>
                    Visualizza&nbsp;
                    <Typography
                      component={'span'}
                      variant="body1"
                      color="primary"
                      sx={{ fontFamily: 'Poppins-Bold' }}
                    >
                      le Operazioni Recenti
                    </Typography>
                  </Typography>
                </Stack>
                <Typography variant="caption">
                  Visualizza e monitora le operazioni effettuate recentemente
                </Typography>
              </Box>
              <TextField
                onChange={e => filter(e.target.value, 'recenti')}
                sx={{ mb: 1 }}
                placeholder="Cerca"
                size="small"
                variant="outlined"
                slotProps={{
                  input: {
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search sx={{ width: 20, height: 20 }} />
                      </InputAdornment>
                    ),
                  },
                }}
              />
            </Stack>
            <Box sx={{ mt: 1 }}>
              <NoleggiTable
                noleggi={recenti?.noleggiRecenti ?? []}
                isLoading={getNoleggiAction?.loading ?? false}
                initialState={{
                  pagination: { paginationModel: { pageSize: 5 } },
                }}
                pageSizeOptions={[5, 10, 20, 50]}
                showSearch={false}
              />
            </Box>
          </Card>
        </Grid>

        {!matchXl ? <Grafici /> : <></>}
      </Grid>
    </Box>
  );
}
