import { Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ActionErrorMessage from '../../components/ActionErrorMessage';
import ProduttoreCard from './components/ProduttoreCard';
import { useDispatch } from 'react-redux';
import { getProduttori } from '../../redux/produttori/operations';

export default function Produttore() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [produttoreData, setProduttoreData] = useState(null);
  const initialState = { id: null };
  const [loadingError, setLoadingError] = useState(null);

  const onPageLoad = async () => {
    if (location.pathname.includes('new')) {
      setProduttoreData(initialState);
    } else if (location.pathname.includes('edit')) {
      try {
        setProduttoreData(location.state.produttore);
      } catch (error) {
        setLoadingError(error);
      }
    } else {
      navigate(-1);
    }
  };

  const onSaved = () => {
    dispatch(getProduttori());
    navigate(-1);
  };

  useEffect(() => {
    onPageLoad();
  }, [location.pathname]);

  return (
    <Stack>
      {loadingError != null && <ActionErrorMessage error={loadingError} />}
      {produttoreData != null ? (
        <ProduttoreCard
          isNewProduttore={produttoreData.id == null}
          produttoreData={produttoreData}
          onSaved={onSaved}
        />
      ) : (
        <></>
      )}
    </Stack>
  );
}
