import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { Close } from '@mui/icons-material';
import FirmatarioCard from './FirmatarioCard';

export default function CreaFirmatarioDialog({
  idFornitore,
  onSaved,
  open,
  onClose,
}) {
  const [firmatarioData, setFirmatarioData] = useState();
  const firmatarioCardRef = useRef(null);

  const handleSave = () => {
    firmatarioCardRef.current.onSalva();
  };

  useEffect(() => {
    setFirmatarioData(null);
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'hidden',
        }}
      >
        <DialogTitle>
          <Stack
            direction="row"
            spacing={2}
            justifyContent={'space-between'}
            alignItems="center"
          >
            <Typography variant="h6" color="primary">
              Nuovo Firmatario
            </Typography>
            <Stack direction="row" spacing={2}>
              <Button variant="contained" size="small" onClick={handleSave}>
                SALVA
              </Button>

              <IconButton size="small" onClick={onClose}>
                <Close />
              </IconButton>
            </Stack>
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ flexGrow: 1, overflowY: 'auto' }}>
          <FirmatarioCard
            ref={firmatarioCardRef}
            isNewFirmatario
            idFornitore={idFornitore}
            firmatarioData={firmatarioData}
            onSaved={onSaved}
            embedded
          />
        </DialogContent>
      </Box>
    </Dialog>
  );
}
