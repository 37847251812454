import { useEffect } from 'react';

const useResizer = (ref, condition = null) => {
  useEffect(() => {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect();

      const visibileHeight = window.innerHeight - rect.top - 8; //8 sono il padding e margin del Layout
      if (condition == null || window.matchMedia(condition).matches) {
        ref.current.style.height = `${visibileHeight}px`;
      } else {
        ref.current.style.height = `auto`;
      }
    }
  }, []);

  //on window resize
  useEffect(() => {
    const handleResize = () => {
      if (ref.current) {
        const rect = ref.current.getBoundingClientRect();
        const visibileHeight = window.innerHeight - rect.top - 8; //8 sono il padding e margin del Layout
        if (condition == null || window.matchMedia(condition).matches) {
          ref.current.style.height = `${visibileHeight}px`;
        } else {
          ref.current.style.height = `auto`;
        }
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [ref]);
};

export default useResizer;
