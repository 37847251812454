import {
  Grid2 as Grid,
  Typography,
  Box,
  Stack,
  TextField,
  FormHelperText,
} from '@mui/material';
import {
  forwardRef,
  useEffect,
  useId,
  useImperativeHandle,
  useState,
} from 'react';
import DecimalInputDecorated from '../../../components/DecimalInputDecorated';
import { useSelector } from 'react-redux';
import { formatCurrency } from '../../../utils/formatNumber';

const PersonalizzazioneRata = forwardRef(
  ({ noleggio, setNoleggio, onCompletedChanged = null }, ref) => {
    const [noteError, setNoteError] = useState(null);
    const [importoError, setImportoError] = useState(null);

    const { rataNoleggioCorrente } = useSelector(state => state.noleggi);

    const emitChanges = () => {
      var flgPersonalizzaRata = false;
      if (
        noleggio.rata_personalizzata == null ||
        noleggio.rata_personalizzata == 0
      ) {
        flgPersonalizzaRata = false;
      } else {
        flgPersonalizzaRata =
          noleggio.note_personalizzazione != '' &&
          noleggio.note_personalizzazione != null;
      }

      if (
        !noleggio.flg_personalizza_rata &&
        noleggio.flg_step_personalizzazione_avanzato
      ) {
        flgPersonalizzaRata = true;
      }

      if (onCompletedChanged) {
        onCompletedChanged(flgPersonalizzaRata);
      }

      setNoleggio(prev => {
        return {
          ...prev,
          rata_personalizzata: noleggio.rata_personalizzata,
          note_personalizzazione: noleggio.note_personalizzazione,
        };
      });
    };

    const getStepName = () => {
      return 'personalizzazione-rata';
    };

    const validate = switchPersonalizzaRata => {
      setNoteError(null);
      setImportoError(null);

      if (!switchPersonalizzaRata) {
        return true;
      }

      var result = true;
      if (
        noleggio.rata_personalizzata == null ||
        noleggio.rata_personalizzata == 0
      ) {
        setImportoError('Inserire un importo');
        result = false;
      }

      if (
        noleggio.note_personalizzazione == '' ||
        noleggio.note_personalizzazione == null
      ) {
        setNoteError('Inserire una nota');
        result = false;
      }

      if (!result) {
        return false;
      }

      return true;
    };

    useImperativeHandle(ref, () => ({
      validate,
      getStepName,
    }));

    useEffect(() => {
      emitChanges();
    }, [
      noleggio.flg_personalizza_rata,
      noleggio.rata_personalizzata,
      noleggio.note_personalizzazione,
      noleggio.flg_step_personalizzazione_avanzato,
      noteError,
    ]);

    useEffect(() => {
      emitChanges();
    }, []);

    const importoInputId = useId();

    return (
      <Box ref={ref}>
        <Grid container spacing={2}>
          <Grid size={{ xs: 12, md: 12, lg: 4 }}>
            <Typography variant="caption">
              Ti occorre pagare una rata più bassa? Richiedi una
              personalizzazione del canone mensile inserendo l’importo che
              vorresti pagare.
            </Typography>
            <Stack
              direction="row"
              spacing={2}
              sx={{ mt: 2 }}
              justifyContent={'start'}
            >
              <Typography variant="caption">
                Il tuo Canone Mensile attuale è di:
              </Typography>
              <Typography color="primary" fontWeight={'bold'}>
                {formatCurrency(rataNoleggioCorrente?.rataTotale)}/mese per{' '}
                {rataNoleggioCorrente?.n_mesi} Mesi
              </Typography>
            </Stack>
          </Grid>
          <Grid size={{ xs: 0, lg: 2 }}></Grid>
          <Grid size={{ xs: 12, lg: 6 }}>
            <Box>
              <Grid container spacing={2}>
                <Grid size={{ xs: 12, sm: 4 }}>
                  <Typography variant="caption">
                    Indica il canone mensile che vorresti pagare (€ senza iva) *
                  </Typography>
                </Grid>
                <Grid size={{ xs: 12, sm: 8 }}>
                  <Box sx={{ maxWidth: '200px' }}>
                    <DecimalInputDecorated
                      id={importoInputId}
                      format={'EUR'}
                      step={1}
                      value={
                        noleggio.rata_personalizzata != null
                          ? parseFloat(noleggio.rata_personalizzata)
                          : null
                      }
                      max={9999999}
                      min={0}
                      onChange={value =>
                        setNoleggio(prev => ({
                          ...prev,
                          rata_personalizzata: value,
                        }))
                      }
                      style={{ justifyContent: 'start' }}
                    />
                  </Box>
                  {importoError && (
                    <FormHelperText sx={{ color: 'error.main' }}>
                      {importoError}
                    </FormHelperText>
                  )}
                </Grid>

                <Grid size={{ xs: 12, sm: 4 }}>
                  <Typography variant="caption">Note *</Typography>
                </Grid>
                <Grid size={{ xs: 12, sm: 8 }}>
                  <Box sx={{ minWidth: '200px' }}>
                    <TextField
                      multiline
                      fullWidth
                      rows={3}
                      placeholder="Note"
                      name="note"
                      variant="outlined"
                      value={noleggio.note_personalizzazione ?? ''}
                      onChange={e =>
                        setNoleggio(prev => ({
                          ...prev,
                          note_personalizzazione: e.target.value,
                        }))
                      }
                      sx={{ maxWidth: '500px', width: '100%' }}
                      helperText={noteError}
                      error={noteError !== ''}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  }
);

export default PersonalizzazioneRata;
