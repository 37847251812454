import { Navigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

/**
 * - If the route is private and the user is logged in, render the component
 * - Otherwise render <Navigate> to redirectTo
 */

export const PrivateRoute = ({
  component: Component,
  redirectTo = '/login',
  isSetNewPasswordPage = false,
  forceRedirect = false,
}) => {
  const { user } = useAuth();
  const shouldRedirect = user == null;

  if (shouldRedirect || forceRedirect) {
    return <Navigate to={redirectTo} />;
  }

  if (user.flg_reset_password === 1 && !isSetNewPasswordPage) {
    return <Navigate to="/reset-password" />;
  }

  return Component;
};
