import { Card, Grid2 as Grid, Stack, Typography } from '@mui/material';
import LoadingMask from '../../../components/LoadingMask';
import ActionErrorMessage from '../../../components/ActionErrorMessage';
import TotaleComponent from './TotaleComponent';
import GraficoTorta from './GraficoTorta';
import useOperazioniData from '../hook/useOperazioniData';

export default function OperazioniPanel() {
  const {
    isLoadingOperazioniData,
    operazioniData,
    loadingErrorOperazioniData,
  } = useOperazioniData();

  return (
    <Stack sx={{ position: 'relative' }}>
      <Card sx={{ backgroundColor: '#FAFAFA' }}>
        <Typography component="h3" variant="h5" sx={{ color: '#284BAA' }}>
          Operazioni
        </Typography>
        {operazioniData ? (
          <Grid container spacing={2} columns={12}>
            <Grid size={{ xs: 12, md: 6 }}>
              <TotaleComponent
                titolo={'Tot. Operazioni Attive'}
                sottotitolo={'N. di Operazioni Attive Attuali'}
                value={operazioniData.attive?.counter}
              />
              <GraficoTorta
                labelPos="left"
                value={operazioniData.attive?.stati}
              />
            </Grid>
            <Grid size={{ xs: 12, md: 6 }}>
              <TotaleComponent
                titolo={'Tot. Operazioni NON Attive'}
                sottotitolo={'N. di Operazioni Non Attive Attuali'}
                value={operazioniData.nonAttive?.counter}
              />
              <GraficoTorta
                labelPos="left"
                value={operazioniData.nonAttive?.stati}
              />
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
      </Card>
      {loadingErrorOperazioniData != null && (
        <ActionErrorMessage error={loadingErrorOperazioniData} />
      )}
      {isLoadingOperazioniData ? <LoadingMask /> : <></>}
    </Stack>
  );
}
