import { createSlice } from '@reduxjs/toolkit';
import { getFornitori } from './operations';
import {
  createAsyncState,
  handlePending,
  handleFulfilled,
  handleRejected,
} from '../base';

const initialState = {
  fornitori: [],
  getFornitoriAction: createAsyncState(),
};

const fornitoriSlice = createSlice({
  name: 'fornitori',
  initialState,

  extraReducers: builder => {
    builder
      .addCase(getFornitori.pending, state => {
        handlePending(state.getFornitoriAction);
      })
      .addCase(getFornitori.fulfilled, (state, action) => {
        state.fornitori = action.payload;
        handleFulfilled(state.getFornitoriAction, action);
      })
      .addCase(getFornitori.rejected, (state, action) => {
        handleRejected(state.getFornitoriAction, action);
      });
  },
});

export const fornitoriReducer = fornitoriSlice.reducer;
