import api from '../../config/axiosConfig';
import { handleAsyncApiRequest } from '../asyncWrapper';

/*
 * GET @ /preventivatore/report/operazioni
 * response: { report }
 */
export const getOperazioniReport = handleAsyncApiRequest(async () => {
  const res = await api.get(`/preventivatore/report/operazioni`);
  return res.data.data;
});

/*
 * GET @ /preventivatore/report/pagamenti
 * response: { report }
 */
export const getOperazioniReportPagamenti = handleAsyncApiRequest(async () => {
  const res = await api.get(`/preventivatore/report/pagamenti`);
  return res.data.data;
});

/*
 * GET @ /preventivatore/report/inoltri
 * response: { report }
 */
export const getInoltriReport = handleAsyncApiRequest(
  async (data_inizio, data_fine) => {
    const res = await api.get(
      `/preventivatore/report/inoltri?data_inizio=${data_inizio}&data_fine=${data_fine}`
    );
    return res.data.data;
  }
);

/*
 * GET @ /preventivatore/report/clienti
 * response: { report }
 */
export const getClientiReport = handleAsyncApiRequest(
  async (data_inizio, data_fine, id_azienda) => {
    let url = `/preventivatore/report/clienti?data_inizio=${data_inizio}&data_fine=${data_fine}`;
    if (id_azienda) url += `&id_azienda=${id_azienda}`;
    const res = await api.get(url);
    return res.data.data;
  }
);

/*
 * GET @ /preventivatore/report/account
 * response: { report }
 */
export const getAccountReport = handleAsyncApiRequest(
  async (data_inizio, data_fine) => {
    const res = await api.get(
      `/preventivatore/report/account?data_inizio=${data_inizio}&data_fine=${data_fine}`
    );
    return res.data.data;
  }
);
