import api from '../../config/axiosConfig';
import { handleAsyncApiRequest } from '../asyncWrapper';

/*
 * GET @ /preventivatore/fornitore/{id}
 * response: { fornitore }
 */
export const getFornitore = handleAsyncApiRequest(async id => {
  const res = await api.get(`/preventivatore/fornitore/${id}`);
  return res.data.data;
});

/*
 * POST @ preventivatore/fornitore
 * body: { fornitore }
 * response: { }
 */
export const nuovoFornitore = handleAsyncApiRequest(async data => {
  const res = await api.post('/preventivatore/fornitore', data);
  return res.data.data;
});

/*
 * POST @ preventivatore/firmatario
 * body: { firmatario }
 * response: { }
 */
export const nuovoFirmatario = handleAsyncApiRequest(async data => {
  const res = await api.post(`/preventivatore/firmatario`, data);
  return res.data.data;
});
