import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  Divider,
  Grid2 as Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { ArrowBackIos, ExpandMore } from '@mui/icons-material';
import DocumentiGrid from '../../Noleggi/components/DocumentiGrid';
import { useState } from 'react';
import { downloadDocumento } from '../../../redux/noleggi/api';
import LoadingMask from '../../../components/LoadingMask';
import { DataGrid } from '@mui/x-data-grid';
import columnsData from '../../Noleggi/data/noleggi_columns';
import { showSnackbar } from '../../../redux/app/slice';
import { useDispatch } from 'react-redux';
import HighlightText from '../../../components/HighlightText';
import { downloadBlob } from '../../../utils/download';
import { formatCurrency } from '../../../utils/formatNumber';

export default function ClienteCard({ clienteData }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const onDownloadDocumento = async (name, id) => {
    setIsLoading(true);
    try {
      const pdfData = await downloadDocumento(id);
      downloadBlob(pdfData, 'application/pdf', name + '.pdf');
    } catch (error) {
      dispatch(showSnackbar({ message: error, severity: 'error' }));
    } finally {
      setIsLoading(false);
    }
  };

  const onNoleggioEdit = id => {
    navigate('/noleggi/noleggio/edit', { state: { id: id } });
  };

  const noleggiColumns = columnsData({
    onEdit: id => onNoleggioEdit(id),
  }).filter(c => c.field !== 'cliente');

  const noleggiAttivi = clienteData?.noleggi.filter(
    noleggio => noleggio.id_stato == 'TE'
  );

  const totaleImportoAttivi = noleggiAttivi.reduce((acc, noleggio) => {
    return acc + noleggio.importo;
  }, 0);

  return (
    <Box sx={{ backgroundColor: 'background.paper' }}>
      {isLoading && <LoadingMask />}
      <Stack
        justifyContent="space-between"
        flexDirection="row"
        sx={{
          backgroundColor: 'background.primaryLight',
          p: 2,
          borderRadius: '4px',
        }}
      >
        <Stack flexDirection="row" alignItems="center" gap={1}>
          <IconButton
            sx={{ border: 'none' }}
            size="small"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIos />
          </IconButton>
          <Box sx={{ ml: 1 }}>
            <Typography
              component="h4"
              variant="h6"
              sx={{ fontSize: 14 }}
              color="primary"
            >
              Clienti
            </Typography>
            <Stack flexDirection="row">
              <Typography component="h3" variant="h6" sx={{ fontSize: 17 }}>
                Azienda {clienteData?.ragione_sociale}
              </Typography>
            </Stack>
          </Box>
        </Stack>
      </Stack>

      <Grid container spacing={1} alignItems={'stretch'}>
        <Grid size={{ xs: 12, lg: 12, xl: 6 }} sx={{ height: 'auto' }}>
          <Card sx={{ backgroundColor: 'background.default', height: '100%' }}>
            <Box>
              <Typography
                component="h4"
                variant="h6"
                sx={{ fontSize: 14 }}
                color="primary"
              >
                DATI CLIENTE
              </Typography>
              <Grid container spacing={1} sx={{ mt: 1 }}>
                <Grid size={{ xs: 12, md: 3 }}>
                  <Typography sx={{ fontFamily: 'Poppins-Bold' }}>
                    Dati Azienda
                  </Typography>
                </Grid>
                <Grid size={{ xs: 12, md: 3 }}>
                  <Typography>Ragione Sociale:</Typography>
                  <Typography>Codice Fiscale:</Typography>
                  <Typography>P.IVA:</Typography>
                </Grid>
                <Grid size={{ xs: 12, md: 3 }}>
                  <Typography>{clienteData.ragione_sociale}</Typography>
                  <Typography>{clienteData.cf}</Typography>
                  <Typography>{clienteData.piva}</Typography>
                </Grid>
              </Grid>

              <Divider sx={{ mt: 2, mb: 2 }} />

              <Grid container spacing={1} sx={{ mt: 1 }}>
                <Grid size={{ xs: 12, md: 3 }}>
                  <Typography sx={{ fontFamily: 'Poppins-Bold' }}>
                    Contatti
                  </Typography>
                </Grid>
                <Grid size={{ xs: 12, md: 3 }}>
                  <Typography>Email:</Typography>
                  <Typography>Telefono:</Typography>
                </Grid>
                <Grid size={{ xs: 12, md: 3 }}>
                  <Typography>{clienteData.mail}</Typography>
                  <Typography>{clienteData.telefono}</Typography>
                </Grid>
              </Grid>
            </Box>
          </Card>
        </Grid>
        <Grid size={{ xs: 12, lg: 12, xl: 6 }} sx={{ height: 'auto' }}>
          <Card sx={{ backgroundColor: 'background.default' }}>
            <Box>
              <Typography
                component="h4"
                variant="h6"
                sx={{ fontSize: 14, mb: 2 }}
                color="primary"
              >
                Noleggi Attivi
              </Typography>
              <Stack flexDirection="row" alignItems={'center'} sx={{ mb: 1 }}>
                <Typography sx={{ fontSize: 14 }}>Totale Importo:</Typography>
                <HighlightText
                  text={
                    <Typography
                      sx={{ fontFamily: 'Poppins-Bold', fontSize: 14 }}
                    >
                      {formatCurrency(totaleImportoAttivi)}
                    </Typography>
                  }
                  backgroundColor="background.primaryLight"
                  borderRadius="4px"
                />
              </Stack>
              <DataGrid
                onRowDoubleClick={params => onNoleggioEdit(params.row.id)}
                autoHeight
                rows={noleggiAttivi ?? []}
                columns={noleggiColumns}
                getRowClassName={params =>
                  params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                }
                initialState={{
                  pagination: { paginationModel: { pageSize: 5 } },
                }}
                pageSizeOptions={[5]}
                disableColumnFilter
                disableColumnMenu
                disableColumnSorting
                disableColumnResize
                density="compact"
              />
            </Box>
          </Card>
          {/* <Card sx={{ backgroundColor: 'background.default', mt: 1 }}>
            <Box>
              <Stack flexDirection="row" alignItems={'center'}>
                 <Typography
                  component="h4"
                  variant="h6"
                  sx={{ fontSize: 14 }}
                  color="primary"
                >
                  Totale Importo Azienda:
                </Typography>
                <HighlightText
                  text={
                    <Typography sx={{ fontFamily: 'Poppins-Bold' }}>
                      {formatCurrency(totaleImporto)}
                    </Typography>
                  }
                  backgroundColor="background.primaryLight"
                  borderRadius="4px"
                />
              </Stack>

              <Stack sx={{ mt: 2 }}>
                {noleggiImportoProgressBar.map(noleggio => (
                  <Stack
                    key={noleggio.codice}
                    flexDirection="row"
                    justifyContent={'start'}
                    alignItems={'center'}
                    sx={{ mb: 1 }}
                  >
                    <Typography sx={{ flex: 1 }}>{noleggio.codice}</Typography>
                    <Box sx={{ flex: 4, mr: 1 }}>
                      <LinearProgress
                        sx={{
                          height: 10,
                          borderRadius: 0,
                        }}
                        variant="determinate"
                        value={noleggio.percentuale}
                      />
                    </Box>
                    <Typography sx={{ flex: 1, textAlign: 'right' }}>
                      {formatCurrency(noleggio.importo)}
                    </Typography>
                  </Stack>
                ))}
              </Stack>
            </Box>
          </Card> */}
        </Grid>
      </Grid>

      <Grid container alignItems={'stretch'} sx={{ mt: 1 }}>
        <Grid size={{ xs: 12 }}>
          <Box>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography
                  component="h4"
                  variant="h6"
                  sx={{ fontSize: 14 }}
                  color="primary"
                >
                  OPERAZIONI
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <DataGrid
                  onRowDoubleClick={params => onNoleggioEdit(params.row.id)}
                  autoHeight
                  rows={clienteData?.noleggi ?? []}
                  columns={noleggiColumns}
                  getRowClassName={params =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                  }
                  initialState={{
                    pagination: { paginationModel: { pageSize: 5 } },
                  }}
                  pageSizeOptions={[5]}
                  disableColumnFilter
                  disableColumnMenu
                  disableColumnSorting
                  disableColumnResize
                  density="compact"
                />
              </AccordionDetails>
            </Accordion>
          </Box>
        </Grid>
        <Grid size={{ xs: 12 }}>
          <Box>
            <Accordion sx={{ mt: 1 }}>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography
                  component="h4"
                  variant="h6"
                  sx={{ fontSize: 14 }}
                  color="primary"
                >
                  DOCUMENTI
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <DocumentiGrid
                  data={clienteData?.documenti ?? []}
                  onDownload={onDownloadDocumento}
                />
              </AccordionDetails>
            </Accordion>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
