import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useAuth from '../hooks/useAuth';
import { ReactComponent as OpAttiveSvg } from '../assets/img/OpAttive.svg';
import { ReactComponent as OpNonAttiveSvg } from '../assets/img/OpNonAttive.svg';
import { ReactComponent as RichiestaModificaMenuSvg } from '../assets/img/RichiestaModificaMenu.svg';
import { ReactComponent as ReportSvg } from '../assets/img/Report.svg';
import { ReactComponent as SupportoSvg } from '../assets/img/Supporto.svg';
import { ReactComponent as DashboardSvg } from '../assets/img/Dashboard.svg';
import { ReactComponent as NewsSvg } from '../assets/img/News.svg';

import {
  Button,
  Card,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  AddCircle,
  ArrowForwardIos,
  Description,
  HomeWork,
  PeopleAlt,
  Settings,
  Warehouse,
} from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toggleDrawerSupporto } from '../redux/app/slice';

export default function SideMenuContent({ onNavigate, onItemClick }) {
  const { user } = useAuth();
  const location = useLocation();
  const dispatch = useDispatch();
  // const theme = useTheme();
  // var menuColor = theme.palette.primary.main;
  var menuColor = '#274BAA';
  const { drawerSupportoOpen, sideMenuOpen } = useSelector(state => state.app);

  const theme = useTheme();
  const matchMd = useMediaQuery(theme.breakpoints.up('md'));

  const showItemText = () => {
    return sideMenuOpen || !matchMd;
  };

  const styleCardNoActive = {
    p: 0,
    pt: 1,
    border: 'none',
    backgroundColor: 'transparent',
  };
  const styleCardActive = {
    pl: showItemText() ? 1 : 0,
    pr: showItemText() ? 1 : 0,
    py: showItemText() ? 1 : 0,
    mt: showItemText() ? 0 : 1,
    border: 'none',
    backgroundColor: 'background.primaryLight',
  };

  const iconItemStyle = {
    color: '#274BAA !important',
    height: showItemText() ? 'auto' : '22px!important',
    width: showItemText() ? 'auto' : '22px!important',
  };

  const iconSvgItemStyle = {
    color: '#274BAA !important',
    height: showItemText() ? '14' : '20',
    width: showItemText() ? '14' : '20',
  };

  const textItemStyle = {
    color: '#274BAA !important',
    display: showItemText() ? 'visible' : 'none',
  };

  const iconButtonStyle = {
    justifyContent: 'center',
    marginBottom: showItemText() ? '0' : '8px',
  };

  const getSideMenuAuth = () => {
    switch (user.tipologia) {
      case 'F':
      case 'M':
        return (
          <>
            <ListItem disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                selected={location.pathname === '/clienti'}
                onClick={() => onNavigate('/clienti')}
                sx={iconButtonStyle}
              >
                <ListItemIcon>
                  <PeopleAlt sx={iconItemStyle} />
                </ListItemIcon>
                <ListItemText sx={textItemStyle} primary="Clienti" />
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                selected={location.pathname === '/produttori'}
                onClick={() => onNavigate('/produttori')}
                sx={iconButtonStyle}
              >
                <ListItemIcon>
                  <Warehouse
                    sx={{
                      ...iconItemStyle,
                    }}
                  />
                </ListItemIcon>
                <ListItemText sx={textItemStyle} primary="Produttori" />
              </ListItemButton>
            </ListItem>
            {user.tipologia === 'M' && (
              <ListItem disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                  selected={location.pathname === '/fornitori'}
                  onClick={() => onNavigate('/fornitori')}
                  sx={iconButtonStyle}
                >
                  <ListItemIcon>
                    <HomeWork sx={iconItemStyle} />
                  </ListItemIcon>
                  <ListItemText sx={textItemStyle} primary="Fornitori" />
                </ListItemButton>
              </ListItem>
            )}

            <ListItem disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                selected={location.pathname === '/documenti-fatture'}
                onClick={() => onNavigate('/documenti-fatture')}
                sx={iconButtonStyle}
              >
                <ListItemIcon>
                  <Description sx={iconItemStyle} />
                </ListItemIcon>
                <ListItemText
                  sx={textItemStyle}
                  primary="Documenti e Fatture"
                />
              </ListItemButton>
            </ListItem>

            <Divider sx={{ my: 1 }} />
            <ListItem disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                selected={location.pathname === '/report'}
                onClick={() => onNavigate('/report')}
                sx={iconButtonStyle}
              >
                <ListItemIcon>
                  <ReportSvg
                    width={iconSvgItemStyle.width}
                    height={iconSvgItemStyle.height}
                    style={{ color: menuColor }}
                  />
                </ListItemIcon>
                <ListItemText sx={textItemStyle} primary="Report" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                selected={location.pathname === '/comunicazioni'}
                onClick={() => onNavigate('/comunicazioni')}
                sx={iconButtonStyle}
              >
                <ListItemIcon>
                  <NewsSvg
                    width={iconSvgItemStyle.width}
                    height={iconSvgItemStyle.height}
                    style={{ color: menuColor }}
                  />
                </ListItemIcon>
                <ListItemText
                  sx={textItemStyle}
                  primary="News e Comunicazioni"
                />
              </ListItemButton>
            </ListItem>
          </>
        );
      case 'A':
        return (
          <>
            <ListItem disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                selected={location.pathname === '/documenti-fatture'}
                onClick={() => onNavigate('/documenti-fatture')}
                sx={iconButtonStyle}
              >
                <ListItemIcon>
                  <Description sx={iconItemStyle} />
                </ListItemIcon>
                <ListItemText
                  sx={textItemStyle}
                  primary="Documenti e Fatture"
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                selected={location.pathname === '/comunicazioni'}
                onClick={() => onNavigate('/comunicazioni')}
                sx={iconButtonStyle}
              >
                <ListItemIcon>
                  <NewsSvg
                    width={iconSvgItemStyle.width}
                    height={iconSvgItemStyle.height}
                    style={{ color: menuColor }}
                  />
                </ListItemIcon>
                <ListItemText
                  sx={textItemStyle}
                  primary="News e Comunicazioni"
                />
              </ListItemButton>
            </ListItem>

            {/* <ListItem disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                selected={location.pathname === '/report-pagamenti'}
                onClick={() => onNavigate('/report-pagamenti')}
                sx={iconButtonStyle}
              >
                <ListItemIcon>
                  <ReportSvg
                    width={iconSvgItemStyle.width} height={iconSvgItemStyle.height} 
                    style={{ color: menuColor }}
                  />
                </ListItemIcon>
                <ListItemText sx={itemStyle} primary="Report Pagamenti" />
              </ListItemButton>
            </ListItem> */}
            <Divider sx={{ my: 1 }} />
            <ListItem disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                selected={drawerSupportoOpen}
                onClick={() => {
                  onItemClick(() => {
                    dispatch(toggleDrawerSupporto());
                  });
                }}
                sx={iconButtonStyle}
              >
                <ListItemIcon>
                  <SupportoSvg
                    width={iconSvgItemStyle.width}
                    height={iconSvgItemStyle.height}
                    style={{ color: menuColor }}
                  />
                </ListItemIcon>
                <ListItemText sx={textItemStyle} primary="Supporto" />
              </ListItemButton>
            </ListItem>
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <Stack sx={{ flexGrow: 1 }}>
      {showItemText() ? (
        <Button
          startIcon={<AddCircle />}
          variant="contained"
          onClick={() =>
            onNavigate('/noleggi/noleggio/new/' + Date.now(), { replace: true })
          }
          sx={{ pt: 3, pb: 3 }}
        >
          <Typography
            variant="body2"
            sx={{ fontWeight: 500, lineHeight: '16px', textAlign: 'left' }}
          >
            <span>
              Nuova
              <br />
              Proposta Noleggio
            </span>
          </Typography>
        </Button>
      ) : (
        <IconButton
          sx={{ borderRadius: '100%', border: 'none' }}
          onClick={() =>
            onNavigate('/noleggi/noleggio/new/' + Date.now(), { replace: true })
          }
        >
          <AddCircle color="primary" />
        </IconButton>
      )}

      <ListItem disablePadding sx={{ display: 'block', mt: 2 }}>
        <ListItemButton
          selected={location.pathname === '/dashboard'}
          onClick={() => onNavigate('/dashboard')}
          sx={iconButtonStyle}
        >
          <ListItemIcon>
            <DashboardSvg
              width={iconSvgItemStyle.width}
              height={iconSvgItemStyle.height}
              style={{ color: menuColor }}
            />
          </ListItemIcon>
          <ListItemText sx={textItemStyle} primary="Dashboard" />
        </ListItemButton>
      </ListItem>

      <Divider sx={{ my: 1 }} />

      <Card
        sx={
          location.pathname.includes('/noleggi/attivi')
            ? styleCardActive
            : styleCardNoActive
        }
      >
        <ListItem disablePadding sx={{ display: 'block' }}>
          <ListItemButton
            onClick={() => onNavigate('/noleggi/attivi')}
            sx={{ ...iconButtonStyle, marginBottom: '0' }}
          >
            <ListItemIcon>
              <OpAttiveSvg
                width={iconSvgItemStyle.width}
                height={iconSvgItemStyle.height}
                style={{ color: menuColor }}
              />
            </ListItemIcon>
            <ListItemText
              sx={{
                ...textItemStyle,
                '& span': {
                  fontWeight: location.pathname.includes('/noleggi/attivi')
                    ? 'bold !important'
                    : 'auto',
                },
              }}
              primary="Operazioni Attive"
            />
          </ListItemButton>
        </ListItem>

        {location.pathname.includes('/noleggi/attivi') && showItemText() && (
          <List dense sx={{ p: 0, m: 0, ml: 3 }}>
            {[
              { name: 'Proposte In Bozza', path: '/noleggi/attivi/bozza' },
              {
                name: 'Pratiche Inoltrate',
                path: '/noleggi/attivi/inoltrate',
              },
              {
                name: 'Pratiche Confermate',
                path: '/noleggi/attivi/confermate',
              },
              { name: 'Noleggi Attivi', path: '/noleggi/attivi' },
            ].map((link, index) => (
              <ListItem key={index} disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                  key={index}
                  selected={location.pathname === link.path}
                  onClick={() => onNavigate(link.path)}
                  sx={iconButtonStyle}
                >
                  <ListItemText
                    secondary={link.name}
                    sx={{
                      ...textItemStyle,
                      '& p': {
                        color: '#274BAA',
                        fontWeight:
                          location.pathname === link.path
                            ? 'bold !important'
                            : '',
                      },
                    }}
                  />
                  <ListItemIcon>
                    <ArrowForwardIos sx={iconItemStyle} />
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        )}
      </Card>

      <Card
        sx={
          location.pathname.includes('/noleggi/non-attivi')
            ? styleCardActive
            : styleCardNoActive
        }
      >
        <ListItem disablePadding sx={{ display: 'block' }}>
          <ListItemButton
            onClick={() => onNavigate('/noleggi/non-attivi')}
            sx={{ ...iconButtonStyle, marginBottom: '0' }}
          >
            <ListItemIcon>
              <OpNonAttiveSvg
                width={iconSvgItemStyle.width}
                height={iconSvgItemStyle.height}
                style={{ color: menuColor }}
              />
            </ListItemIcon>
            <ListItemText
              sx={{
                ...textItemStyle,
                '& span': {
                  fontWeight: location.pathname.includes('/noleggi/non-attivi')
                    ? 'bold !important'
                    : 'auto',
                },
              }}
              primary="Operazioni Non Attive"
            />
          </ListItemButton>
        </ListItem>

        {location.pathname.includes('/noleggi/non-attivi') &&
          showItemText() && (
            <List dense sx={{ p: 0, m: 0, ml: 3 }}>
              {[
                {
                  name: 'Proposte Scadute',
                  path: '/noleggi/non-attivi',
                },
                {
                  name: 'Pratiche Declinate',
                  path: '/noleggi/non-attivi/declinate',
                },
                {
                  name: 'Noleggi Scaduti',
                  path: '/noleggi/non-attivi/noleggi-scaduti',
                },
              ].map((link, index) => (
                <ListItem key={index} disablePadding sx={{ display: 'block' }}>
                  <ListItemButton
                    key={index}
                    selected={location.pathname === link.path}
                    onClick={() => onNavigate(link.path)}
                    sx={{ ...iconButtonStyle }}
                  >
                    <ListItemText
                      secondary={link.name}
                      sx={{
                        ...textItemStyle,
                        '& p': {
                          fontWeight:
                            location.pathname === link.path
                              ? 'bold !important'
                              : '',
                        },
                      }}
                    />
                    <ListItemIcon>
                      <ArrowForwardIos sx={iconItemStyle} />
                    </ListItemIcon>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          )}
      </Card>

      <ListItem disablePadding sx={{ display: 'block', mt: 1 }}>
        <ListItemButton
          selected={location.pathname === '/richieste-modifica'}
          onClick={() => onNavigate('/richieste-modifica')}
          sx={iconButtonStyle}
        >
          <ListItemIcon>
            <RichiestaModificaMenuSvg
              width={iconSvgItemStyle.width}
              height={iconSvgItemStyle.height}
              style={{ color: menuColor }}
            />
          </ListItemIcon>
          <ListItemText sx={textItemStyle} primary="Richieste di Modifica" />
        </ListItemButton>
      </ListItem>

      <Divider sx={{ my: 1 }} />

      {getSideMenuAuth()}

      <Box sx={{ flexGrow: 1 }} />
      {user?.flg_admin == 1 && (
        <ListItem disablePadding sx={{ display: 'block', mb: 1 }}>
          <ListItemButton
            selected={location.pathname === '/impostazioni'}
            onClick={() => {
              onNavigate('/impostazioni');
            }}
            sx={iconButtonStyle}
          >
            <ListItemIcon>
              <Settings sx={iconItemStyle} />
            </ListItemIcon>
            <ListItemText sx={textItemStyle} primary="Impostazioni" />
          </ListItemButton>
        </ListItem>
      )}
    </Stack>
  );
}
