import { useEffect, useState } from 'react';
import {
  Button,
  Typography,
  Box,
  Card,
  CardContent,
  Stack,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { Add, Edit } from '@mui/icons-material';
import ConfiguraBene from './ConfiguraBene';
import BeniTable from './BeniTable';

const ConfiguraBeni = ({ noleggio, beni, setBeni, onCompletedChanged }) => {
  const theme = useTheme();
  const matchMd = useMediaQuery(theme.breakpoints.up('md'));
  const [beneKey, setBeneKey] = useState(0);

  const [isAddingNewBene, setIsAddingNewBene] = useState(false);
  const [isEditingBene, setIsEditingBene] = useState(false);

  const [beneToEdit, setBeneToEdit] = useState(null);

  useEffect(() => {
    if (onCompletedChanged) {
      onCompletedChanged(beni.length > 0);
    }
  }, [beni]);

  const onAddNewBene = () => {
    setBeneToEdit(null);
    setIsAddingNewBene(true);
    setBeneKey(prev => prev + 1);
  };

  const onEditBene = bene => {
    setBeneToEdit(bene);
    setIsEditingBene(true);
    setBeneKey(prev => prev + 1);
  };

  const onCancelDetail = () => {
    if (isAddingNewBene) {
      setIsAddingNewBene(false);
    } else {
      setIsEditingBene(false);
    }
  };

  const onSaveDetail = bene => {
    let newBeni = [];
    if (isAddingNewBene) {
      //genero random id
      bene.id = Math.random().toString(36).substr(2, 9);
      newBeni = [...beni, bene];
      setIsAddingNewBene(false);
    } else {
      //modifico il bene esistente
      newBeni = beni.map(b => {
        if (b.id === bene.id) {
          return bene;
        }
        return b;
      });
      setIsEditingBene(false);
    }

    setBeni(newBeni);
  };

  const onQtaBeneChange = (id, qta) => {
    setBeni(
      beni.map(bene => {
        if (bene.id === id) {
          bene.quantita = qta;
        }
        return bene;
      })
    );
  };

  const onDeleteBene = id => {
    setBeni(beni.filter(bene => bene.id !== id));
    if (beneToEdit && beneToEdit.id === id) {
      setBeneToEdit(null);
      setIsEditingBene(false);
    }
  };

  const onClone = bene => {
    setBeneToEdit({ ...bene, id: Math.random().toString(36).substr(2, 9) });
    setIsEditingBene(false);
    setIsAddingNewBene(true);
    setBeneKey(prev => prev + 1);
  };

  return (
    <Box>
      <Box sx={{}}>
        {beni.length > 0 ? (
          <Box sx={{ mb: 2 }}>
            <BeniTable
              rows={beni}
              onQtaChange={onQtaBeneChange}
              onClone={onClone}
              onEdit={onEditBene}
              onDelete={onDeleteBene}
              readOnly={false}
            />
          </Box>
        ) : !isAddingNewBene ? (
          <Stack sx={{ justifyContent: 'center' }} flexDirection="row">
            <Typography variant="body2" color="textSecondary">
              Nessun bene aggiunto
            </Typography>
          </Stack>
        ) : (
          <></>
        )}
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'end', mt: 2 }}>
        {!isAddingNewBene && !isEditingBene && (
          <Button
            startIcon={<Add />}
            variant="contained"
            size="small"
            onClick={onAddNewBene}
          >
            Aggiungi nuovo
          </Button>
        )}
      </Box>

      {(isAddingNewBene || isEditingBene) && (
        <Card
          sx={{
            backgroundColor: 'background.default',
            position: 'relative',
          }}
        >
          <CardContent sx={{ p: matchMd ? 0 : 0 }}>
            <Stack
              sx={{
                flexDirection: 'row',
                alignContent: 'center',
                alignItems: 'center',
                mb: 2,
              }}
            >
              <Card
                sx={{
                  width: 20,
                  height: 20,
                  p: 0,
                  mr: 1,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: 'background.darkest',
                }}
              >
                {isEditingBene ? (
                  <Edit sx={{ color: 'white' }} fontSize="15" />
                ) : (
                  <Add sx={{ color: 'white' }} fontSize="15" />
                )}
              </Card>
              <Typography variant="h6" component="h6" sx={{ fontSize: 16 }}>
                {isAddingNewBene ? 'Aggiungi Bene' : 'Modifica Bene'}
              </Typography>
            </Stack>
            <ConfiguraBene
              key={beneKey}
              isEditing={isEditingBene}
              noleggio={noleggio}
              bene={beneToEdit}
              onCancel={onCancelDetail}
              onSave={onSaveDetail}
            />
          </CardContent>
        </Card>
      )}
    </Box>
  );
};

export default ConfiguraBeni;
