import {
  Grid2 as Grid,
  FormControl,
  Box,
  FormLabel,
  IconButton,
  Stack,
} from '@mui/material';
import SelectFornitore from './SelectFornitore';

import { Add } from '@mui/icons-material';
import CreaFornitoreDialog from '../../Fornitori/components/CreaFornitoreDialog';
import { useEffect, useState } from 'react';

const SelezionaDatiFornitore = ({
  noleggio,
  setNoleggio,
  viewMode = 'full',
  onCompletedChanged,
}) => {
  const [dialogCreaFornitoreOpen, setDialogCreaFornitoreOpen] = useState(false);
  const [fornitoreKey, setFornitoreKey] = useState(0);

  useEffect(() => {
    if (onCompletedChanged) {
      onCompletedChanged(noleggio?.id_fornitore != null);
    }
  }, [noleggio]);

  const onFornitoreSaved = fornitore => {
    setDialogCreaFornitoreOpen(false);
    setNoleggio(prev => {
      return {
        ...prev,
        id_fornitore: fornitore.id,
        fornitore_ragione_sociale: fornitore.ragione_sociale,
      };
    });
    setFornitoreKey(prev => prev + 1);
  };

  const onAddFornitore = () => {
    setDialogCreaFornitoreOpen(true);
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, sm: 6, md: 8, lg: 6, xl: 4 }}>
          <FormControl fullWidth>
            <FormLabel htmlFor="id_fornitore">Fornitore</FormLabel>
            <Stack flexDirection={'row'} sx={{ width: '100%', flex: 1 }}>
              <SelectFornitore
                key={fornitoreKey}
                name="id_fornitore"
                placeholder="Fornitore"
                value={noleggio?.id_fornitore}
                onChange={val => {
                  setNoleggio(prev => {
                    return {
                      ...prev,
                      id_fornitore: val?.target?.value,
                      fornitore_ragione_sociale: val?.target?.ragione_sociale,
                    };
                  });
                }}
                readOnly={viewMode === 'read' ? true : false}
              />
              <IconButton
                sx={{ ml: 2 }}
                size="small"
                onClick={onAddFornitore}
                color="secondary"
              >
                <Add />
              </IconButton>
            </Stack>
          </FormControl>
        </Grid>
      </Grid>
      <CreaFornitoreDialog
        open={dialogCreaFornitoreOpen}
        onClose={() => setDialogCreaFornitoreOpen(false)}
        onSaved={onFornitoreSaved}
      />
    </Box>
  );
};

export default SelezionaDatiFornitore;
