import { Box, Button, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ProduttoriTable from './components/ProduttoriTable';
import { Add } from '@mui/icons-material';

export default function Produttori() {
  const navigate = useNavigate();

  const onNuovoProduttore = () => {
    navigate('/produttori/new');
  };

  const onEdit = produttore => {
    navigate('/produttori/edit', { state: { produttore: produttore } });
  };

  return (
    <Box sx={{ p: 3 }}>
      <Stack
        direction="row"
        spacing={2}
        sx={{ mb: 2, justifyContent: 'space-between' }}
      >
        <Box>
          <Typography component="h2" variant="h4">
            Gestione Produttori
          </Typography>
          <Typography variant="body1">
            Qui puoi visualizzare i produttori censiti.
          </Typography>
        </Box>

        <Button
          onClick={onNuovoProduttore}
          startIcon={<Add />}
          variant="contained"
        >
          Nuovo produttore
        </Button>
      </Stack>

      <ProduttoriTable onEdit={onEdit} />
    </Box>
  );
}
