import {
  TextField,
  Grid2 as Grid,
  FormControl,
  Typography,
  Box,
  FormLabel,
} from '@mui/material';
import SelectProvince from './SelectProvince';
import SearchMap from '../../../components/SearchMap';
import { useEffect, useState } from 'react';

const InserisciDatiFornitore = ({ hook, onCompletedChanged = null }) => {
  const { formData, errors, handleInputChange, isValid } = hook;
  const [coordSede, setCoordSede] = useState({ lat: 44.390789, lng: 7.820823 });
  const [locationFieldsSede, setLocationFieldsSede] = useState([]);

  useEffect(() => {
    if (onCompletedChanged) {
      onCompletedChanged(isValid);
    }
  }, [isValid]);

  useEffect(() => {
    if (locationFieldsSede.length > 0) {
      let tmpFormData = {
        ...formData,
      };
      const indirizzo = locationFieldsSede.filter(item => {
        if (item.types[0] == 'route') {
          return item.long_name;
        }
      });
      if (indirizzo[0]) {
        tmpFormData.sede_indirizzo = indirizzo[0].long_name;
      }
      const civico = locationFieldsSede.filter(item => {
        if (item.types[0] == 'street_number') {
          return item.long_name;
        }
      });
      if (civico[0]) {
        tmpFormData.sede_civico = civico[0].long_name;
      }
      const provincia = locationFieldsSede.filter(item => {
        if (item.types[0] == 'administrative_area_level_2') {
          return item.long_name;
        }
      });
      if (provincia[0]) {
        tmpFormData.sede_provincia = provincia[0].short_name;
      }
      const citta = locationFieldsSede.filter(item => {
        if (item.types[0] == 'administrative_area_level_3') {
          return item.long_name;
        }
      });
      if (citta[0]) {
        tmpFormData.sede_citta = citta[0].long_name;
      }
      const cap = locationFieldsSede.filter(item => {
        if (item.types[0] == 'postal_code') {
          return item.long_name;
        }
      });
      if (cap[0]) {
        tmpFormData.sede_cap = cap[0].long_name;
      }
      if (coordSede) {
        tmpFormData.sede_latitudine = coordSede.lat.toString();
        tmpFormData.sede_longitudine = coordSede.lng.toString();
      }

      const fields = [
        'sede_indirizzo',
        'sede_civico',
        'sede_cap',
        'sede_citta',
        'sede_provincia',
        'sede_latitudine',
        'sede_longitudine',
      ];
      fields.forEach(field => {
        handleInputChange({
          target: {
            name: field,
            value: tmpFormData[field],
          },
        });
      });
    }
  }, [coordSede]);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, sm: 4 }}>
          <FormControl fullWidth>
            <FormLabel required htmlFor="ragione_sociale">
              Ragione Sociale
            </FormLabel>
            <TextField
              placeholder="Ragione Sociale"
              name="ragione_sociale"
              variant="outlined"
              value={formData?.ragione_sociale}
              onChange={handleInputChange}
              fullWidth
              error={!!errors.ragione_sociale}
              helperText={errors.ragione_sociale}
            />
          </FormControl>
        </Grid>

        <Grid size={{ xs: 12, sm: 4 }}>
          <FormControl fullWidth>
            <FormLabel required htmlFor="cf">
              Codice Fiscale
            </FormLabel>
            <TextField
              placeholder="Codice Fiscale"
              name="cf"
              value={formData?.cf}
              onChange={handleInputChange}
              fullWidth
              error={!!errors.cf}
              helperText={errors.cf}
            />
          </FormControl>
        </Grid>

        <Grid size={{ xs: 12, sm: 4 }}>
          <FormControl fullWidth>
            <FormLabel required htmlFor="piva">
              Partita Iva
            </FormLabel>
            <TextField
              placeholder="Partita IVA"
              name="piva"
              value={formData?.piva}
              onChange={handleInputChange}
              fullWidth
              error={!!errors.piva}
              helperText={errors.piva}
            />
          </FormControl>
        </Grid>
      </Grid>

      <Box sx={{ mt: 2, mb: 1 }}>
        <Typography
          variant="h6"
          sx={{ fontFamily: 'Poppins-Bold', fontSize: 16 }}
        >
          Inserisci Indirizzo:
        </Typography>
      </Box>

      <Grid container>
        <Box sx={{ display: 'block', width: '100%', maxWidth: '400px', mb: 1 }}>
          <SearchMap
            setCoord={setCoordSede}
            setLocationFields={setLocationFieldsSede}
          />
        </Box>
      </Grid>

      <Grid container spacing={2}>
        <Grid size={{ xs: 12, sm: 6, md: 6, lg: 6, xl: 3 }}>
          <FormControl fullWidth>
            <FormLabel required htmlFor="sede_indirizzo">
              Indirizzo Sede
            </FormLabel>
            <TextField
              placeholder="Indirizzo Sede"
              name="sede_indirizzo"
              value={formData?.sede_indirizzo}
              onChange={handleInputChange}
              fullWidth
              error={!!errors.sede_indirizzo}
              helperText={errors.sede_indirizzo}
            />
          </FormControl>
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 3, lg: 3, xl: 2 }}>
          <FormControl fullWidth>
            <FormLabel required htmlFor="sede_civico">
              N. Civico
            </FormLabel>
            <TextField
              placeholder="N. Civico"
              name="sede_civico"
              value={formData?.sede_civico}
              onChange={handleInputChange}
              fullWidth
              error={!!errors.sede_civico}
              helperText={errors.sede_civico}
            />
          </FormControl>
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 3, lg: 3, xl: 2 }}>
          <FormControl fullWidth>
            <FormLabel required htmlFor="sede_cap">
              CAP
            </FormLabel>
            <TextField
              placeholder="CAP"
              name="sede_cap"
              value={formData?.sede_cap}
              onChange={handleInputChange}
              fullWidth
              error={!!errors.sede_cap}
              helperText={errors.sede_cap}
            />
          </FormControl>
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 6, lg: 6, xl: 2 }}>
          <FormControl fullWidth>
            <FormLabel required htmlFor="sede_citta">
              Città
            </FormLabel>
            <TextField
              placeholder="Città"
              name="sede_citta"
              value={formData?.sede_citta}
              onChange={handleInputChange}
              fullWidth
              error={!!errors.sede_citta}
              helperText={errors.sede_citta}
            />
          </FormControl>
        </Grid>

        <Grid size={{ xs: 12, sm: 6, md: 6, lg: 6, xl: 3 }}>
          <FormControl fullWidth>
            <FormLabel required htmlFor="sede_provincia">
              Provincia
            </FormLabel>
            <SelectProvince
              name="sede_provincia"
              placeholder="Provincia"
              value={formData?.sede_provincia}
              onChange={handleInputChange}
              errors={errors.sede_provincia}
            />
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
};

export default InserisciDatiFornitore;
