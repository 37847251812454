import { useEffect, useState } from 'react';
import { FormHelperText, MenuItem, Select } from '@mui/material';
import { getListaFirmatari } from '../../../redux/noleggi/api';

const SelectFirmatario = ({
  _key,
  idFornitore,
  name,
  placeholder,
  value,
  onChange,
  errors,
}) => {
  const [listaFirmatari, setListaFirmatari] = useState([]);
  const [localKey, setLocalKey] = useState(0);

  const loadFirmatari = async () => {
    let response = await getListaFirmatari(idFornitore);
    setListaFirmatari(response);

    if (response.length > 0 && !value) {
      var defaultValue = null;
      for (var i = 0; i < response.length; i++) {
        if (response[i].flg_default) {
          defaultValue = response[i].id;
          break;
        }
      }

      if (defaultValue) {
        setTimeout(() => {
          onChange({
            target: {
              name: name,
              value: defaultValue,
              cognome_nome: response.find(
                firmatario => firmatario.id == defaultValue
              ).cognome_nome,
            },
          });
          setLocalKey(prevKey => prevKey + 1);
        }, 100);
      }
    }
  };

  useEffect(() => {
    if (idFornitore) {
      loadFirmatari();
    } else {
      setListaFirmatari([]);
      onChange(null);
    }
  }, [idFornitore, _key]);

  const _onChange = e => {
    onChange({
      target: {
        name: name,
        value: e.target.value,
        cognome_nome: listaFirmatari.find(
          firmatario => firmatario.id == e.target.value
        ).cognome_nome,
      },
    });
  };

  return (
    <>
      <Select
        key={localKey}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={_onChange}
        sx={{ flex: 1 }}
      >
        {listaFirmatari?.map(firmatario => (
          <MenuItem key={firmatario.id} value={firmatario.id}>
            {firmatario.cognome_nome}
          </MenuItem>
        ))}
      </Select>
      {errors && (
        <FormHelperText sx={{ color: 'error.main' }}>{errors}</FormHelperText>
      )}
    </>
  );
};

export default SelectFirmatario;
