import { useEffect, useState } from 'react';
import { Typography, Box, Stack, useTheme, Alert, Button } from '@mui/material';
import { Description, FileUpload } from '@mui/icons-material';
import Dropzone from 'react-dropzone';

const supportedFormatsMaps = {
  pdf: {
    type: 'application/pdf',
    extensions: ['.pdf'],
    label: 'PDF',
  },
  image: {
    type: 'image/*',
    extensions: ['.jpg', '.jpeg', '.png'],
    label: 'Immagini',
  },
};

const CaricaFile = ({
  isPresent,
  onLoad,
  onCancel,
  acceptedFormats = ['pdf'],
  alreadyPresentMessage = null,
  onCompletedChanged = null,
}) => {
  const theme = useTheme();
  const [file, setFile] = useState(null);

  const onDrop = acceptedFiles => {
    setFile(acceptedFiles[0]);
    onLoad(acceptedFiles[0]);
  };

  const onCancelFile = () => {
    setFile(null);
    onCancel();
  };

  const acceptedFormatsMaps = {};
  acceptedFormats.forEach(format => {
    acceptedFormatsMaps[supportedFormatsMaps[format].type] =
      supportedFormatsMaps[format].extensions;
  });

  let acceptedFormatsLabel = '';
  acceptedFormats.forEach(format => {
    acceptedFormatsLabel += `${supportedFormatsMaps[format].label}, `;
  });
  if (acceptedFormatsLabel.length > 0) {
    acceptedFormatsLabel = acceptedFormatsLabel.slice(0, -2);
  }

  useEffect(() => {
    if (onCompletedChanged) {
      onCompletedChanged(file != null || isPresent == true);
    }
  }, [file]);

  return (
    <Box>
      {isPresent && alreadyPresentMessage ? (
        <Alert severity="info" sx={{ mb: 2 }}>
          <Typography variant="body1">{alreadyPresentMessage}</Typography>
        </Alert>
      ) : (
        <></>
      )}
      <Dropzone onDrop={onDrop} accept={acceptedFormatsMaps}>
        {({ getRootProps, getInputProps }) => (
          <section>
            {!file ? (
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Box
                  {...getRootProps({
                    className: 'dropzone',
                    style: {
                      border: `2px dashed ${theme.palette.primary.main}`,
                      borderRadius: '8px',
                      padding: '20px',
                      maxWidth: '500px',
                      width: '100%',
                      textAlign: 'center',
                      cursor: 'pointer',
                    },
                  })}
                >
                  <input {...getInputProps()} />
                  <Box>
                    <FileUpload sx={{ color: theme.palette.primary.main }} />
                    <Typography variant="subtitle2">
                      Trascina o scegli il file da caricare
                    </Typography>
                    <Typography variant="caption">
                      {acceptedFormatsLabel}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            ) : (
              <></>
            )}
            {file && (
              <Stack
                flexDirection="row"
                sx={{
                  alignItems: 'center',
                  alignContent: 'center',
                  textAlign: 'center',
                }}
              >
                <Description color="primary" sx={{ width: 20, height: 20 }} />
                <Typography variant="subtitle2" sx={{ mt: 0, ml: 1, mr: 2 }}>
                  {file.name}
                </Typography>
                <Button
                  variant="contained"
                  onClick={onCancelFile}
                  size="xsmall"
                  color="white"
                >
                  Modifica
                </Button>
              </Stack>
            )}
          </section>
        )}
      </Dropzone>
    </Box>
  );
};

export default CaricaFile;
