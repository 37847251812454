import * as ReactDOM from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import AppRoute from './layout/AppRoute';
import theme from './theme';
import { persistor, store } from './redux/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter } from 'react-router-dom';
import setUpInterceptor from './config/setupInterceptors';
import { Box } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import './index.css';

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);
setUpInterceptor(store);

import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
} from 'chart.js';
import { LoadScript } from '@react-google-maps/api';
import { CONFIG } from './config/envConfig';

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

root.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <CssBaseline />
            <Box sx={{ minHeight: '100vh' }}>
              <LoadScript
                googleMapsApiKey={CONFIG.GOOGLE_MAPS_API_KEY}
                libraries={['places']}
              >
                <AppRoute />
              </LoadScript>
            </Box>
          </BrowserRouter>
        </PersistGate>
      </LocalizationProvider>
    </Provider>
  </ThemeProvider>
);
