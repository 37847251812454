import { Close, Description } from '@mui/icons-material';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  IconButton,
  Grid2 as Grid,
  Typography,
  TextField,
  Box,
  DialogActions,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { z } from 'zod';
import useZod from '../../../hooks/useZod';
import { DateField } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import CaricaFile from './CaricaFile';
import { creaIntervento, deleteIntervento } from '../../../redux/noleggi/api';
import ActionErrorMessage from '../../../components/ActionErrorMessage';
import { LoadingButton } from '@mui/lab';

const formSchema = z.object({
  descrizione: z.string().min(1, { message: 'La descrizione è obbligatoria' }),
  data: z.string().min(1, { message: 'La data è obbligatoria' }),
});

const DialogIntervento = ({
  idRichiesta,
  intervento,
  open,
  onClose,
  onSaved,
  onDeleted,
  onDownloadCertificato,
}) => {
  const initialFormData = intervento || {
    descrizione: '',
    data: new Date().toISOString().split('T')[0],
  };
  const [certificato, setCertificato] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const { formData, setFormData, errors, handleInputChange, validate } = useZod(
    {
      schema: formSchema,
      initialFormData: initialFormData,
    }
  );

  const onSave = async () => {
    const validation = validate();
    if (!validation.success) {
      return;
    }

    const _formData = new FormData();
    _formData.append('id_richiesta', idRichiesta);
    _formData.append('descrizione', formData.descrizione);
    _formData.append('data', formData.data);
    if (certificato != null) {
      _formData.append('allegato', certificato);
    }

    setIsLoading(true);
    try {
      const response = await creaIntervento(_formData);
      onSaved(response);
    } catch (e) {
      setError(e);
    } finally {
      setIsLoading(false);
    }
  };

  const onDelete = async () => {
    setIsLoading(true);
    try {
      const response = await deleteIntervento(intervento.id);
      onDeleted(response);
    } catch (e) {
      setError(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setError(null);
    setFormData(initialFormData);
  }, [open]);

  const readOnly = intervento != null;

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'hidden',
        }}
      >
        <DialogTitle>
          <Stack
            direction="row"
            spacing={2}
            justifyContent={'space-between'}
            alignItems="center"
          >
            <Typography variant="h6" color="primary">
              {intervento == null ? 'Aggiungi Manutenzione' : 'Manutenzione'}
            </Typography>
            <Stack direction="row" spacing={2}>
              {intervento != null && (
                <LoadingButton
                  variant="contained"
                  onClick={onDelete}
                  size="small"
                  loading={isLoading}
                >
                  Elimina
                </LoadingButton>
              )}
              <IconButton size="small" onClick={onClose}>
                <Close />
              </IconButton>
            </Stack>
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ flexGrow: 1, overflowY: 'auto', mx: 2 }}>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid size={{ xs: 12, md: 4 }}>
              <Typography sx={{ fontWeight: 'bold' }}>Descrizione</Typography>
              <Typography
                variant="caption"
                sx={{ display: 'block', lineHeight: 1.1 }}
              >
                Inserisci la descrizione dell'intervento
              </Typography>
            </Grid>
            <Grid size={{ xs: 12, md: 8 }}>
              {readOnly ? (
                <Typography>{formData.descrizione}</Typography>
              ) : (
                <TextField
                  multiline
                  rows={3}
                  value={formData.descrizione}
                  error={!!errors.descrizione}
                  helperText={errors.descrizione}
                  onChange={handleInputChange}
                  name="descrizione"
                  placeholder="Descrizione"
                  autoFocus
                  fullWidth
                  variant="outlined"
                />
              )}
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid size={{ xs: 12, md: 4 }}>
              <Typography sx={{ fontWeight: 'bold' }}>Data</Typography>
              <Typography
                variant="caption"
                sx={{ display: 'block', lineHeight: 1.1 }}
              >
                Inserisci la data di intervento
              </Typography>
            </Grid>
            <Grid size={{ xs: 12, md: 8 }}>
              {readOnly ? (
                <Typography>
                  {dayjs(formData.data).format('DD/MM/YYYY')}
                </Typography>
              ) : (
                <DateField
                  placeholder="Data"
                  name="data"
                  value={formData?.data ? dayjs(formData?.data) : null}
                  onChange={value => {
                    let _date = '';
                    if (value && value != '') {
                      _date = value.format('YYYY-MM-DD');
                      if (_date == 'Invalid Date') {
                        _date = '';
                      }
                    }
                    handleInputChange({
                      target: {
                        name: 'data',
                        value: _date,
                        type: 'date',
                      },
                    });
                  }}
                  format="DD/MM/YYYY"
                  fullWidth
                  error={!!errors.data}
                  helperText={errors.data}
                />
              )}
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid size={{ xs: 12, md: 4 }}>
              <Typography sx={{ fontWeight: 'bold' }}>Certificato</Typography>
              <Typography
                variant="caption"
                sx={{ display: 'block', lineHeight: 1.1 }}
              >
                Allega un certificato di avvenuta manutenzione
              </Typography>
            </Grid>
            <Grid size={{ xs: 12, md: 8 }}>
              {readOnly && (
                <>
                  {intervento?.has_image ? (
                    <Stack flexDirection="row" alignItems={'center'}>
                      <IconButton
                        sx={{
                          border: 'none',
                          color: 'primary.main',
                          mr: 1,
                        }}
                        onClick={() => {
                          onDownloadCertificato(intervento.id);
                        }}
                      >
                        <Description />
                      </IconButton>
                      Certificato di Manutenzione
                    </Stack>
                  ) : (
                    <Typography variant="caption">
                      Nessun file allegato
                    </Typography>
                  )}
                </>
              )}

              {!readOnly && (
                <CaricaFile
                  isPresent={intervento?.has_image}
                  alreadyPresentMessage="Hai già caricato un certificato. Per sovrascriverlo, carica un
                nuovo file."
                  acceptedFormats={['pdf', 'image']}
                  onLoad={file => {
                    setCertificato(file);
                  }}
                  onCancel={() => {
                    setCertificato(null);
                  }}
                />
              )}
            </Grid>
          </Grid>
          <Box sx={{ mt: 2 }}>
            <ActionErrorMessage error={error} />
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', m: 2, mb: 1, mt: 1 }}>
          <Stack direction="row" spacing={2} justifyContent={'center'}>
            {intervento == null && (
              <LoadingButton
                variant="contained"
                onClick={onSave}
                size="small"
                loading={isLoading}
              >
                AGGIUNGI NOTA
              </LoadingButton>
            )}
          </Stack>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default DialogIntervento;
