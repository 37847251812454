import Box from '@mui/material/Box';
import MuiCard from '@mui/material/Card';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { z } from 'zod';
import { IconButton, InputAdornment, Link } from '@mui/material';

import { styled } from '@mui/material/styles';

import { LoadingButton } from '@mui/lab';
import { Stack } from '@mui/material';
import useZod from '../../../hooks/useZod';
import { useState } from 'react';
import { login } from '../../../redux/auth/api';
import ActionErrorMessage from '../../../components/ActionErrorMessage';
import ForgotPassword from './ForgotPassword';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const Card = styled(MuiCard)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  width: '100%',
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  border: '0px',
  // boxShadow:
  //   'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
  [theme.breakpoints.up('sm')]: {
    width: '450px',
  },
  ...theme.applyStyles('dark', {
    // boxShadow:
    //   'hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px',
  }),
}));

const formSchema = z.object({
  email: z.string().email({ message: 'Email non valida' }),
  password: z.string().min(1, { message: 'Password richiesta' }),
});

export default function SignInCard({ onSignIn }) {
  const { errors, handleInputChange, validate } = useZod({
    schema: formSchema,
    initialFormData: {
      email: '',
      password: '',
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isForgotPasswordOpen, setIsForgotPasswordOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const onForgotPassword = () => {
    setIsForgotPasswordOpen(true);
  };

  const closeForgotPassword = () => {
    setIsForgotPasswordOpen(false);
  };

  const handleSubmit = async () => {
    const validation = validate();
    if (validation.success) {
      setIsLoading(true);
      try {
        const result = await login(validation.data);
        onSignIn(result);
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleClickShowPassword = () => setShowPassword(show => !show);

  return (
    <Card variant="outlined">
      <Stack>
        <Typography
          component="h1"
          variant="h4"
          sx={{ width: '100%', fontSize: 'clamp(2rem, 10vw, 2.15rem)' }}
        >
          Benvenuto
        </Typography>
        <Typography
          component="h2"
          variant="subtitle1"
          sx={{ width: '100%', color: 'text.secondary' }}
        >
          Inserisci le tue credenziali per accedere
        </Typography>
      </Stack>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 2 }}
      >
        <FormControl>
          <FormLabel htmlFor="email">Email</FormLabel>
          <TextField
            error={!!errors.email}
            helperText={errors.email}
            onChange={handleInputChange}
            onKeyDown={e => e.key === 'Enter' && handleSubmit()}
            type="email"
            name="email"
            placeholder="Email"
            autoComplete="email"
            autoFocus
            fullWidth
            variant="outlined"
            sx={{ ariaLabel: 'email' }}
            slotProps={{
              input: {
                endAdornment: (
                  //lo tengo perche cosi il textfield ha dimensione uguale a quello della password
                  <InputAdornment position="end">
                    <IconButton
                      sx={{ p: 0, m: 0, visibility: 'hidden' }}
                      size="xsmall"
                    ></IconButton>
                  </InputAdornment>
                ),
              },
            }}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="password">Password</FormLabel>
          <TextField
            error={!!errors.password}
            helperText={errors.password}
            onChange={handleInputChange}
            onKeyDown={e => e.key === 'Enter' && handleSubmit()}
            name="password"
            placeholder="••••••"
            type={showPassword ? 'text' : 'password'}
            autoComplete="current-password"
            autoFocus
            fullWidth
            variant="outlined"
            slotProps={{
              input: {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      sx={{ p: 0, m: 0 }}
                      size="xsmall"
                      aria-label={
                        showPassword
                          ? 'hide the password'
                          : 'display the password'
                      }
                      variant="contained"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              },
            }}
          />
        </FormControl>

        <LoadingButton
          onClick={handleSubmit}
          fullWidth
          color="secondary"
          variant="contained"
          loading={isLoading}
        >
          Accedi
        </LoadingButton>
        <Box sx={{ display: 'flex', justifyContent: 'end' }}>
          <Link
            component="button"
            onClick={onForgotPassword}
            variant="body2"
            sx={{ alignSelf: 'baseline' }}
          >
            Password dimenticata?
          </Link>
        </Box>
        <ActionErrorMessage error={error} />
      </Box>
      <ForgotPassword
        open={isForgotPasswordOpen}
        handleClose={closeForgotPassword}
      />
    </Card>
  );
}
