import { Box, Button, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import FornitoriTable from './components/FornitoriTable';
import { Add } from '@mui/icons-material';

export default function Fornitori() {
  const navigate = useNavigate();

  const onNuovoFornitore = () => {
    navigate('/fornitori/new');
  };

  const onEdit = fornitore => {
    navigate('/fornitori/edit', { state: { fornitore: fornitore } });
  };

  return (
    <Box sx={{ p: 3 }}>
      <Stack
        direction="row"
        spacing={2}
        sx={{ mb: 2, justifyContent: 'space-between' }}
      >
        <Box>
          <Typography component="h2" variant="h4">
            Gestione Fornitori
          </Typography>
          <Typography variant="body1">
            Qui puoi visualizzare i fornitori censiti.
          </Typography>
        </Box>

        <Button
          onClick={onNuovoFornitore}
          startIcon={<Add />}
          variant="contained"
        >
          Nuovo fornitore
        </Button>
      </Stack>

      <FornitoriTable onEdit={onEdit} />
    </Box>
  );
}
