import {
  Grid2 as Grid,
  Typography,
  Box,
  Stack,
  FormControl,
  FormLabel,
  IconButton,
} from '@mui/material';
import SelectFirmatario from './SelectFirmatario';
import { Add } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import CreaFirmatarioDialog from '../../Fornitori/components/CreaFirmatarioDialog';
import useAuth from '../../../hooks/useAuth';
import CustomCheckbox from './CustomCheckbox';

const FirmaDocumento = ({
  noleggio,
  setNoleggio,
  showSelectFirmatario = false,
  size = 4,
  spacing = 8,
  onCompletedChanged = null,
}) => {
  const { user } = useAuth();
  const [firmatarioKey, setFirmatarioKey] = useState(0);
  const [dialogCreaFirmatarioOpen, setDialogCreaFirmatarioOpen] =
    useState(false);

  const emitChanges = () => {
    if (onCompletedChanged) {
      if (showSelectFirmatario) {
        onCompletedChanged(noleggio?.id_firmatario != null);
      } else {
        onCompletedChanged(true);
      }
    }
  };

  useEffect(() => {
    emitChanges();
  }, []);

  useEffect(() => {
    emitChanges();
  }, [noleggio]);

  const onFirmatarioSaved = firmatario => {
    setDialogCreaFirmatarioOpen(false);
    setNoleggio(prev => {
      return {
        ...prev,
        id_firmatario: firmatario.id,
        firmatario: firmatario.cognome_nome,
      };
    });
    setFirmatarioKey(prev => prev + 1);
  };

  const onAddFirmatario = () => {
    setDialogCreaFirmatarioOpen(true);
  };

  return (
    <Box>
      {showSelectFirmatario && (
        <Grid container>
          <Grid size={{ xs: 12, sm: 6, md: 6, lg: 4 }}>
            <FormControl fullWidth>
              <FormLabel htmlFor="id_firmatario">Firmatario</FormLabel>
              <Stack flexDirection={'row'} sx={{ width: '100%', flex: 1 }}>
                <SelectFirmatario
                  _key={firmatarioKey}
                  idFornitore={user?.id_fornitore}
                  name="id_firmatario"
                  placeholder="Firmatario"
                  value={noleggio?.id_firmatario ?? ''}
                  onChange={val => {
                    setNoleggio(prev => {
                      return {
                        ...prev,
                        id_firmatario: val?.target?.value,
                        firmatario: val?.target?.cognome_nome,
                      };
                    });
                  }}
                />
                <IconButton
                  sx={{ ml: 2 }}
                  size="small"
                  onClick={onAddFirmatario}
                  color="secondary"
                >
                  <Add />
                </IconButton>
              </Stack>
            </FormControl>
          </Grid>
        </Grid>
      )}
      <Grid container justifyContent={'start'} spacing={spacing} sx={{ my: 4 }}>
        <Grid size={{ xs: 12, sm: size }}>
          <Stack
            flexDirection="row"
            alignItems={'start'}
            sx={{ opacity: '0.2', pointerEvents: 'none' }}
          >
            <CustomCheckbox
              checked={false}
              onChange={() => {}}
              size="20px"
              iconSize="16px"
              disabled
            />
            <Box sx={{ ml: 1 }}>
              <Typography variant="h6" fontWeight="bold">
                Firma Digitale
              </Typography>
              <Typography variant="body2">
                La firma dei documenti digitalmente prevede l'inserimento di un
                codice per autenticare l'utente
              </Typography>
            </Box>
          </Stack>
        </Grid>
        <Grid size={{ xs: 12, sm: size }}>
          <Stack flexDirection="row" alignItems={'start'}>
            <CustomCheckbox
              checked={true}
              onChange={() => {}}
              size="20px"
              iconSize="16px"
              disabled
            />

            <Box sx={{ ml: 1 }}>
              <Typography variant="h6" fontWeight="bold">
                Firma Materiale
              </Typography>
              <Typography variant="body2">
                La firma materiale dei documenti prevede l'invio da parte del
                cliente finale dei documenti originali firmati
              </Typography>
            </Box>
          </Stack>
        </Grid>
      </Grid>
      <CreaFirmatarioDialog
        idFornitore={noleggio?.id_fornitore}
        open={dialogCreaFirmatarioOpen}
        onClose={() => setDialogCreaFirmatarioOpen(false)}
        onSaved={onFirmatarioSaved}
      />
    </Box>
  );
};

export default FirmaDocumento;
