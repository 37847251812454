import { Alert, Box, Card, CircularProgress, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { calcolaRata } from '../../../redux/noleggi/api';
import { useDispatch } from 'react-redux';
import { showSnackbar } from '../../../redux/app/slice';
import { formatCurrency } from '../../../utils/formatNumber';
import { setRataNoleggioCorrente } from '../../../redux/noleggi/slice';

const ImportoCanone = ({
  dettagli = [],
  importo,
  mesi,
  assicurazione,
  backgroundColor = 'white',
  compactMode = false,
  minWidth = '400px',
}) => {
  const [canone, setCanone] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  let _importo = importo;
  if (dettagli.length > 0) {
    _importo = dettagli.reduce((acc, dettaglio) => {
      return acc + dettaglio.importo * dettaglio.quantita;
    }, 0);
  }

  const calcola = async () => {
    if (!_importo || !mesi || _importo == 0 || mesi == 0) return;

    setIsLoading(true);
    try {
      const result = await calcolaRata({
        importo: _importo,
        durata_mesi: mesi,
        flg_assicurazione: assicurazione ? 1 : 0,
        spese_incasso: 0,
      });
      setCanone(result);
      dispatch(setRataNoleggioCorrente(result));
    } catch (error) {
      console.error(error);
      dispatch(showSnackbar({ message: error, severity: 'error' }));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    calcola();
  }, [_importo, mesi, assicurazione]);

  const boxStyle =
    backgroundColor == 'transparent'
      ? { backgroundColor: 'transparent', p: 0, border: 'none' }
      : {
          backgroundColor: 'background.default',
          p: 4,
          minWidth: minWidth,
        };

  const textAlign = backgroundColor == 'white' ? 'center' : 'left';

  return (
    <>
      {(importo == null || importo == 0 || canone?.rataMensile == null) &&
      !isLoading ? (
        <>
          <Alert severity="info" sx={{ my: 4, justifyContent: 'center' }}>
            <Typography variant="body1" sx={{ textAlign: 'center' }}>
              Inserire l'importo per calcolare la rata
            </Typography>
          </Alert>
        </>
      ) : (
        <Card
          sx={{
            ...boxStyle,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {isLoading && (
            <CircularProgress size={30} sx={{ position: 'absolute' }} />
          )}
          <Box
            sx={{
              visibility: isLoading ? 'hidden' : 'visible',
              textAlign: textAlign,
            }}
          >
            {!compactMode && (
              <Typography
                variant="body1"
                sx={{ mb: 2, fontWeight: 'bold', textAlign: textAlign }}
              >
                Canone Rata (mensile):
              </Typography>
            )}
            <Typography
              variant="h5"
              component="span"
              sx={{
                color: 'primary.main',
                fontWeight: 'bold',
                textAlign: textAlign,
              }}
            >
              {formatCurrency(canone?.rataMensile)}
              <Typography
                variant="body1"
                component="span"
                sx={{ color: 'primary.main', textAlign: textAlign }}
              >
                /mese per {canone?.n_mesi} Mesi
              </Typography>
            </Typography>

            <br />
            <Typography variant="caption" sx={{ textAlign: textAlign }}>
              {formatCurrency(canone?.rataMensileIva)}/mese per {canone?.n_mesi}{' '}
              Mesi (+ IVA)
            </Typography>
          </Box>
        </Card>
      )}
    </>
  );
};

export default ImportoCanone;
