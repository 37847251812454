import {
  Alert,
  Box,
  Card,
  CircularProgress,
  Grid2 as Grid,
  Stack,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { calcolaRata } from '../../../redux/noleggi/api';
import { useDispatch } from 'react-redux';
import { showSnackbar } from '../../../redux/app/slice';
import { formatCurrency } from '../../../utils/formatNumber';
import { setRataNoleggioCorrente } from '../../../redux/noleggi/slice';

const ImportoCanoneConSpese = ({
  noleggio = null,
  setNoleggio = null,
  dettagli = [],
  importo,
  mesi,
  assicurazione,
  backgroundColor = 'white',
  minWidth = '400px',
  compactMode = false,
}) => {
  const [canone, setCanone] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  let _importo = importo;
  if (dettagli.length > 0) {
    _importo = dettagli.reduce((acc, dettaglio) => {
      return acc + dettaglio.importo * dettaglio.quantita;
    }, 0);
  }

  const calcola = async () => {
    if (!_importo || !mesi || _importo == 0 || mesi == 0) return;

    setIsLoading(true);
    try {
      const result = await calcolaRata({
        importo: _importo,
        durata_mesi: mesi,
        flg_assicurazione: assicurazione ? 1 : 0,
        spese_incasso: noleggio?.flg_sdd ? 1 : 10,
      });
      setCanone(result);
      dispatch(setRataNoleggioCorrente(result));
      if (setNoleggio) {
        setNoleggio(prev => {
          return {
            ...prev,
            rataMensile: result.rataMensile,
            rataMensileIva: result.rataMensileIva,
            rataTotale: result.rataTotale,
            n_mesi: result.n_mesi,
          };
        });
      }
    } catch (error) {
      console.error(error);
      dispatch(showSnackbar({ message: error, severity: 'error' }));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    calcola();
  }, [importo, mesi, assicurazione, noleggio?.flg_sdd]);

  const boxStyle =
    backgroundColor == 'transparent'
      ? { backgroundColor: 'transparent', p: 0, border: 'none' }
      : {
          backgroundColor: 'background.default',
          py: 1,
          px: 2,
          minWidth: minWidth,
          border: 'none',
        };

  const textAlign = backgroundColor == 'white' ? 'center' : 'left';

  return (
    <>
      {(importo == null || importo == 0 || canone?.rataMensile == null) &&
      !isLoading ? (
        <>
          <Alert severity="info" sx={{ my: 4, justifyContent: 'center' }}>
            <Typography variant="body1" sx={{ textAlign: 'center' }}>
              Inserire l'importo per calcolare la rata
            </Typography>
          </Alert>
        </>
      ) : (
        <Card
          sx={{
            ...boxStyle,
            display: 'flex',
            justifyContent: compactMode ? 'start' : 'center',
            alignItems: 'center',
            flexGrow: compactMode ? 1 : 0,
          }}
        >
          {isLoading && (
            <CircularProgress size={30} sx={{ position: 'absolute' }} />
          )}
          <Box
            sx={{
              visibility: isLoading ? 'hidden' : 'visible',
              textAlign: textAlign,
            }}
          >
            {!compactMode ? (
              <>
                <Grid container spacing={2} sx={{ width: '100%' }}>
                  <Grid size={{ xs: 12, sm: 4 }}>
                    <Typography
                      variant="body1"
                      sx={{ textAlign: 'left', fontSize: 12 }}
                    >
                      Canone Rata Mensile:
                    </Typography>
                  </Grid>
                  <Grid size={{ xs: 12, sm: 6 }}>
                    <Typography
                      variant="body1"
                      sx={{ textAlign: 'left', fontSize: 12 }}
                      color="primary"
                    >
                      {formatCurrency(canone?.rataMensile)}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  sx={{ width: '100%', mb: 1, fontSize: 12 }}
                >
                  <Grid size={{ xs: 12, sm: 4 }}>
                    <Typography
                      variant="body1"
                      sx={{ textAlign: 'left', fontSize: 12 }}
                    >
                      Spese di Incasso:
                    </Typography>
                  </Grid>
                  <Grid size={{ xs: 12, sm: 6 }}>
                    <Typography
                      variant="body1"
                      sx={{ textAlign: 'left', fontSize: 12 }}
                      color="primary"
                    >
                      {formatCurrency(noleggio?.flg_sdd ? 1 : 10)}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            ) : (
              <></>
            )}
            {!compactMode ? (
              <>
                <Grid container spacing={2} sx={{ width: '100%' }}>
                  <Grid size={{ xs: 12, sm: 4 }}>
                    <Typography
                      variant="body1"
                      sx={{ fontWeight: 'bold', textAlign: 'left' }}
                    >
                      Canone Rata Totale (mensile):
                    </Typography>
                  </Grid>
                  <Grid size={{ xs: 12, sm: 8 }} sx={{ textAlign: 'left' }}>
                    <Typography
                      variant="h5"
                      component="span"
                      sx={{
                        color: 'primary.main',
                        fontWeight: 'bold',
                        textAlign: textAlign,
                      }}
                    >
                      {formatCurrency(canone?.rataTotale)}
                      <Typography
                        variant="body1"
                        component="span"
                        sx={{ color: 'primary.main', textAlign: textAlign }}
                      >
                        /mese per {canone?.n_mesi} Mesi
                      </Typography>
                    </Typography>

                    <br />
                    <Typography
                      variant="caption"
                      sx={{ textAlign: textAlign }}
                      color="primary"
                    >
                      <b>{formatCurrency(canone?.rataMensileIva)}</b>/mese per{' '}
                      {canone?.n_mesi} Mesi (IVA inclusa)
                    </Typography>
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <Stack flexDirection="row" gap={2}>
                  <Box sx={{ minWidth: '235px' }}>
                    <Typography
                      variant="h5"
                      component="span"
                      sx={{
                        color: 'primary.main',
                        fontWeight: 'bold',
                        textAlign: 'textAlign',
                      }}
                    >
                      {formatCurrency(canone?.rataTotale)}
                      <Typography
                        variant="body1"
                        component="span"
                        sx={{ color: 'primary.main', textAlign: textAlign }}
                      >
                        /mese per {canone?.n_mesi} Mesi
                      </Typography>
                    </Typography>

                    <br />
                    <Typography
                      variant="caption"
                      sx={{ textAlign: textAlign }}
                      color="primary"
                    >
                      <b>{formatCurrency(canone?.rataMensileIva)}</b>/mese per{' '}
                      {canone?.n_mesi} Mesi (IVA inclusa)
                    </Typography>
                  </Box>
                </Stack>
              </>
            )}{' '}
          </Box>
        </Card>
      )}
    </>
  );
};

export default ImportoCanoneConSpese;
