import { Box, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ClientiTable from './components/ClientiTable';

export default function Clienti() {
  const navigate = useNavigate();

  const onEdit = cliente => {
    navigate('/clienti/edit', { state: { id: cliente.id } });
  };

  return (
    <Box sx={{ p: 3 }}>
      <Stack
        direction="row"
        spacing={2}
        sx={{ mb: 2, justifyContent: 'space-between' }}
      >
        <Box>
          <Typography component="h2" variant="h4">
            Gestione Clienti
          </Typography>
          <Typography variant="body1">
            Qui puoi visualizzare i clienti della tua attività.
          </Typography>
        </Box>
      </Stack>

      <ClientiTable onEdit={onEdit} />
    </Box>
  );
}
