import api from '../../config/axiosConfig';
import { handleAsyncApiRequest } from '../asyncWrapper';

/*
 * GET @ /preventivatore/produttore/{id}
 * response: { produttore }
 */
export const getProduttore = handleAsyncApiRequest(async id => {
  const res = await api.get(`/preventivatore/produttore/${id}`);
  return res.data.data;
});

/*
 * POST @ preventivatore/produttore
 * body: { produttore }
 * response: { }
 */
export const nuovoProduttore = handleAsyncApiRequest(async data => {
  const res = await api.post('/preventivatore/produttore', data);
  return res.data.data;
});

/*
 * GET @ /preventivatore/listaCategorie
 * response: { listaCategorie }
 */
export const getCategorie = handleAsyncApiRequest(async () => {
  const res = await api.get(`/preventivatore/listaCategorie`);
  return res.data.results.data;
});
