import {
  Box,
  Button,
  Card,
  CardContent,
  Grid2 as Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import CalcoloRataSvg from '../../../assets/img/CalcoloRata.svg';
import { formatCurrency } from '../../../utils/formatNumber';
import CalcolatoreSemplice from './CalcolatoreSemplice';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useState } from 'react';
import ImportoCanoneConSpese from './ImportoCanoneConSpese';
import { useSelector } from 'react-redux';

function InfoIntestazioneNoleggio({ setNoleggio, noleggio, dettagli }) {
  let importo = 0;
  if (dettagli.length > 0) {
    importo = dettagli.reduce((acc, dettaglio) => {
      return acc + dettaglio.importo * dettaglio.quantita;
    }, 0);
  } else {
    importo = noleggio?.importo;
  }

  const theme = useTheme();
  const matchLg = useMediaQuery(theme.breakpoints.up('lg'));
  const matchXl = useMediaQuery(theme.breakpoints.up('xl'));
  const [expanded, setExpanded] = useState(false);
  const { rataNoleggioCorrente } = useSelector(state => state.noleggi);

  const PreventivatoreButton = () => {
    return (
      <Button
        onClick={() => setExpanded(prev => !prev)}
        size="small"
        color="transparent"
        sx={{ color: 'primary.main', flex: 1, justifyContent: 'right' }}
      >
        {expanded ? (
          <>
            {matchLg ? 'Chiudi Preventivatore' : ''}
            <ExpandLess />
          </>
        ) : (
          <>
            {matchLg ? 'Apri Preventivatore' : ''} <ExpandMore />
          </>
        )}
      </Button>
    );
  };

  return (
    <Card
      sx={{
        backgroundColor: 'background.primaryLight',
        border: 'none',
        px: matchXl ? 3 : 2,
        py: matchXl ? 2 : 1,
      }}
    >
      <CardContent>
        <Stack flexDirection={'row'} alignItems={'center'}>
          <Stack
            flexDirection={'row'}
            justifyContent={'space-between'}
            sx={{ flexGrow: 1 }}
            gap={2}
            alignItems={'center'}
          >
            {matchXl ? (
              <>
                <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
                  <Card
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: 10,
                      height: 10,
                      bgcolor: 'background.darkest',
                      color: 'white',
                      zIndex: 1,
                      p: 1.5,
                    }}
                  >
                    <img
                      src={CalcoloRataSvg}
                      alt="Calcolo Rata"
                      width={26}
                      height={26}
                    />
                  </Card>
                  <Typography sx={{ ml: 2 }}>Importo Richiesto:</Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      backgroundColor: 'white',
                      borderRadius: '4px',
                      px: 1,
                      py: 0.5,
                      ml: 2,
                      minWidth: '150px',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography>{formatCurrency(importo)}</Typography>
                  </Box>
                </Box>
                <ImportoCanoneConSpese
                  noleggio={noleggio}
                  setNoleggio={setNoleggio}
                  importo={importo}
                  mesi={noleggio?.durata_mesi}
                  assicurazione={noleggio?.flg_assicurazione}
                  dettagli={dettagli}
                  backgroundColor={matchXl ? 'white' : 'transparent'}
                  compactMode={!matchXl}
                />
                <Box sx={{ flex: 1, textAlign: 'end' }}>
                  <PreventivatoreButton />
                </Box>
              </>
            ) : (
              <Grid container sx={{ width: '100%' }}>
                <Grid size={{ xs: 3 }}>
                  <Box>
                    <Typography sx={{ fontSize: 16, fontWeight: 'bold' }}>
                      Importo Richiesto:
                    </Typography>
                    <Typography sx={{ fontSize: 16 }}>
                      {formatCurrency(importo)}
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  size={{ xs: 8, lg: 7 }}
                  sx={{ display: 'flex', justifyContent: 'center' }}
                >
                  <Box
                    sx={{
                      backgroundColor: 'white',
                      px: 3,
                      py: 1,
                      borderRadius: 1,
                      width: 'fit-content',
                    }}
                  >
                    <Box sx={{ display: 'flex' }}>
                      <Box>
                        <Typography sx={{ fontSize: 16, fontWeight: 'bold' }}>
                          Canone Rata Totale
                        </Typography>
                        <ImportoCanoneConSpese
                          noleggio={noleggio}
                          setNoleggio={setNoleggio}
                          importo={importo}
                          mesi={noleggio?.durata_mesi}
                          assicurazione={noleggio?.flg_assicurazione}
                          dettagli={dettagli}
                          backgroundColor={matchXl ? 'white' : 'transparent'}
                          compactMode={!matchXl}
                        />
                      </Box>
                      <Box sx={{ ml: 4 }}>
                        <Typography
                          sx={{
                            ml: 2,
                            fontSize: 14,
                            textAlign: 'start',
                            fontWeight: 'bold',
                          }}
                        >
                          Comprende:
                        </Typography>
                        <Typography
                          sx={{ ml: 2, fontSize: 14, textAlign: 'start' }}
                        >
                          Canone Mensile:{' '}
                          {formatCurrency(rataNoleggioCorrente?.rataMensile)}
                        </Typography>
                        <Typography
                          sx={{ ml: 2, fontSize: 14, textAlign: 'start' }}
                        >
                          Spese di Incasso:{' '}
                          {formatCurrency(noleggio?.flg_sdd ? 1 : 10)}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>

                <Grid size={{ xs: 1, lg: 2 }}>
                  <Box sx={{ flex: 1, textAlign: 'end', marginTop: '0px' }}>
                    <PreventivatoreButton />
                  </Box>
                </Grid>
              </Grid>
            )}
          </Stack>
        </Stack>

        {expanded && (
          <Box sx={{ mx: 2 }}>
            <CalcolatoreSemplice
              noleggio={noleggio}
              setNoleggio={setNoleggio}
              compactViewMode={noleggio.id_stato == 'DC'}
            />
          </Box>
        )}
        <Box></Box>
      </CardContent>
    </Card>
  );
}

export default InfoIntestazioneNoleggio;
