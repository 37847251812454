import { useEffect, useState } from 'react';
import { getInoltriReport } from '../../../redux/report/api';
import dayjs from 'dayjs';

const useInoltriData = () => {
  const [isLoadingInoltriData, setIsLoadingInoltriData] = useState(false);
  const [inoltriData, setInoltriData] = useState(null);
  const [loadingErrorInoltriData, setLoadingErrorInoltriData] = useState(null);
  const [dataInizioInoltri, setDataInizioInoltri] = useState(
    dayjs().subtract(1, 'month').startOf('month')
  );
  const [dataFineInoltri, setDataFineInoltri] = useState(
    dayjs().endOf('month')
  );

  const loadData = async (data_inizio, data_fine) => {
    setIsLoadingInoltriData(true);
    try {
      const response = await getInoltriReport(data_inizio, data_fine);
      setInoltriData(response);
    } catch (error) {
      setLoadingErrorInoltriData(error);
    } finally {
      setIsLoadingInoltriData(false);
    }
  };

  useEffect(() => {
    if (dataInizioInoltri && dataFineInoltri) {
      if (dataInizioInoltri < dataFineInoltri) {
        loadData(
          dataInizioInoltri.format('YYYY-MM-DD'),
          dataFineInoltri.format('YYYY-MM-DD')
        );
      }
    }
  }, [dataInizioInoltri, dataFineInoltri]);

  return {
    isLoadingInoltriData,
    inoltriData,
    loadingErrorInoltriData,
    dataInizioInoltri,
    setDataInizioInoltri,
    dataFineInoltri,
    setDataFineInoltri,
  };
};

export default useInoltriData;
