import { createSlice } from '@reduxjs/toolkit';
import { getUtenti } from './operations';
import {
  createAsyncState,
  handlePending,
  handleFulfilled,
  handleRejected,
} from '../base';

const initialState = {
  utenti: [],
  getUtentiAction: createAsyncState(),
};

const accountSlice = createSlice({
  name: 'account',
  initialState,
  extraReducers: builder => {
    builder
      .addCase(getUtenti.pending, state => {
        handlePending(state.getUtentiAction);
      })
      .addCase(getUtenti.fulfilled, (state, action) => {
        state.utenti = action.payload;
        handleFulfilled(state.getUtentiAction, action);
      })
      .addCase(getUtenti.rejected, (state, action) => {
        handleRejected(state.getUtentiAction, action);
      });
  },
});

export const accountReducer = accountSlice.reducer;
