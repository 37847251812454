import Grid from '@mui/material/Grid2';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { formatStringDate } from '../../../utils/formadDate';
import { IconButton, Stack } from '@mui/material';
import { Description, Download } from '@mui/icons-material';

export default function InterventiTable({
  data,
  isLoading,
  onDownloadCertificato,
  ...props
}) {
  const navigate = useNavigate();

  const onEdit = id => {
    navigate('/noleggi/noleggio/edit', { state: { id: id } });
  };

  const columns = [
    {
      field: 'data',
      flex: 1,
      headerName: 'Data',
      maxWidth: 150,
      valueFormatter: value => {
        return formatStringDate(value);
      },
    },
    {
      field: 'descrizione',
      flex: 1,
      headerName: 'Tipologia Manutazione',
      valueFormatter: () => {
        return 'Manutenzione Ordinaria';
      },
    },
    {
      field: 'id',
      flex: 1,
      headerName: 'Documenti',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: params => (
        <Stack
          flexDirection="row"
          sx={{
            justifyContent: 'start',
            height: '100%',
            alignItems: 'center',
          }}
        >
          {params.row.has_image ? (
            <>
              {' '}
              <IconButton
                sx={{
                  border: 'none',
                  color: 'primary.main',
                  mr: 1,
                }}
                onClick={() => {
                  onDownloadCertificato(params.row.id);
                }}
              >
                <Description />
              </IconButton>
              Certificato di Manutenzione
            </>
          ) : (
            <>-</>
          )}
        </Stack>
      ),
    },
    {
      field: 'azioni',
      headerName: 'Azioni',
      minWidth: 100,
      sortable: false,
      headerClassName: 'no-separator',
      cellClassName: 'no-separator',
      disableColumnMenu: true,
      renderCell: params => (
        <>
          {params.row.has_image ? (
            <>
              <Stack
                flexDirection="row"
                gap={1}
                sx={{
                  justifyContent: 'start',
                  height: '100%',
                  alignItems: 'center',
                }}
              >
                <IconButton
                  size="small"
                  sx={{
                    border: 'none',
                    backgroundColor: 'transparent',
                  }}
                  onClick={() => onDownloadCertificato(params.row.id)}
                >
                  <Download />
                </IconButton>
              </Stack>
            </>
          ) : (
            <></>
          )}
        </>
      ),
    },
  ];

  return (
    <Grid container spacing={2} columns={12}>
      <Grid size={{ md: 12, lg: 12, sx: 12 }} sx={{ width: '100%' }}>
        <DataGrid
          onRowDoubleClick={params => onEdit(params.row.id)}
          autoHeight
          rows={data}
          columns={columns}
          loading={isLoading}
          getRowClassName={params =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
          }
          initialState={{
            pagination: { paginationModel: { pageSize: 20 } },
          }}
          pageSizeOptions={[10, 20, 50]}
          density="standard"
          {...props}
        />
      </Grid>
    </Grid>
  );
}
