import {
  Box,
  Card,
  CardContent,
  Divider,
  Slider,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import ImportoCanone from './ImportoCanone';
import { Add, Remove } from '@mui/icons-material';
import CalcoloRataSvg from '../../../assets/img/CalcoloRata.svg';
import DecimalInputDecorated from '../../../components/DecimalInputDecorated';
import { useId } from 'react';

const sliderMesi = [
  {
    label: 24,
    value: 24,
  },
  {
    label: 36,
    value: 36,
  },
  {
    label: 48,
    value: 48,
  },
  {
    label: 60,
    value: 60,
  },
  {
    label: 72,
    value: 72,
  },
];

function IntestazioneNoleggio({
  noleggio,
  setNoleggio,
  compactViewMode = false,
}) {
  const handleInputChange = e => {
    setNoleggio(prev => ({
      ...prev,
      [e.target.name]:
        e.target.type === 'checkbox' ? e.target.checked : e.target.value,
    }));
  };

  const TitleWithHelper = ({ title1, title2, subtitle }) => {
    return (
      <Box>
        <Stack direction="row" spacing={0.5}>
          <Typography variant="body1" sx={{ fontFamily: 'Poppins-Bold' }}>
            {title1}{' '}
          </Typography>
          <Typography
            sx={{ color: 'primary.main', fontFamily: 'Poppins-Bold' }}
          >
            {title2}
          </Typography>
          :
        </Stack>
        <Typography variant="caption" sx={{ lineHeight: '0px' }}>
          {subtitle}
        </Typography>
      </Box>
    );
  };

  const Item = ({ title, component }) => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          mb: 2,
        }}
      >
        <Box sx={{ width: '400px' }}>{title}</Box>
        <Box sx={{ ml: 4 }}>{component}</Box>
        <Box sx={{ flexGrow: 1 }}></Box>
      </Box>
    );
  };

  const importoInputId = useId();

  return (
    <Box sx={{ position: 'relative' }}>
      <Card
        sx={{
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 10,
          height: 10,
          bgcolor: 'background.darkest',
          color: 'white',
          zIndex: 999,
          top: '30px',
        }}
      >
        <img src={CalcoloRataSvg} alt="Calcolo Rata" />
      </Card>
      <Card
        sx={{
          border: 'none',
          // m: 1.5,
          ml: 1.5,
          backgroundColor: 'background.primaryLight',
        }}
      >
        <CardContent sx={{ p: 2 }}>
          <Stack flexDirection="row" justifyContent="space-between" gap={5}>
            <Box>
              <Stack flexDirection="row" justifyContent="space-between">
                <Typography variant="h6" sx={{ mb: 2 }}>
                  {compactViewMode ? 'Preventivatore' : 'Calcola Rata Noleggio'}
                </Typography>
              </Stack>

              <Stack sx={{ mt: compactViewMode ? 0 : 4 }} flexDirection="row">
                <Box sx={{ flex: 2 }}>
                  {compactViewMode === false && (
                    <Item
                      title={
                        <TitleWithHelper
                          title1="Inserisci"
                          title2="Importo Richiesto (€ senza iva)"
                          subtitle="Inserisci l'importo richiesto per il noleggio"
                        />
                      }
                      component={
                        <>
                          <DecimalInputDecorated
                            id={importoInputId}
                            format={'EUR'}
                            value={noleggio?.importo}
                            onChange={value => {
                              handleInputChange({
                                target: {
                                  name: 'importo',
                                  value:
                                    value != null ? parseFloat(value) : null,
                                },
                              });
                            }}
                            LeftButtonIcon={Remove}
                            RightButtonIcon={Add}
                            minWidth="120px"
                            step={1}
                            round={0.01}
                          />
                        </>
                      }
                    />
                  )}
                  <Item
                    title={
                      <TitleWithHelper
                        title1="Inserisci"
                        title2="Durata (mesi)"
                        subtitle="Inserisci la durata richiesta dal noleggio"
                      />
                    }
                    component={
                      <Slider
                        sx={{ width: '300px' }}
                        name="durata_mesi"
                        aria-label="Custom marks"
                        defaultValue={24}
                        min={24}
                        max={72}
                        step={12}
                        valueLabelDisplay="auto"
                        marks={sliderMesi}
                        value={noleggio?.durata_mesi}
                        getAriaValueText={value => `${value} mesi`}
                        onChange={(e, value) => {
                          handleInputChange({
                            target: {
                              name: 'durata_mesi',
                              value: parseInt(value),
                            },
                          });
                        }}
                      />
                    }
                  />
                  <Item
                    title={
                      <TitleWithHelper
                        title1="Vuoi aggiungere il"
                        title2="Servizio Assicurazione?"
                        subtitle="Doinn offre il servizio assicurazione a tutti i beni noleggiati."
                      />
                    }
                    component={
                      <Switch
                        checked={
                          noleggio?.flg_assicurazione == 1 ? true : false
                        }
                        name="flg_assicurazione"
                        onChange={(e, checked) => {
                          handleInputChange({
                            target: {
                              name: 'flg_assicurazione',
                              type: 'checkbox',
                              checked,
                            },
                          });
                        }}
                      />
                    }
                  />
                </Box>
              </Stack>
            </Box>
            {compactViewMode === false ? (
              <>
                <Divider orientation="vertical" flexItem />
                <Stack
                  sx={{
                    flex: 1,
                    justifyContent: 'start',
                    alignItems: 'center',
                    flexDirection: 'row',
                  }}
                >
                  <ImportoCanone
                    noleggio={noleggio}
                    importo={noleggio?.importo}
                    mesi={noleggio?.durata_mesi}
                    assicurazione={noleggio?.flg_assicurazione}
                    backgroundColor="white"
                    compactMode={false}
                  />
                </Stack>
              </>
            ) : (
              <Box sx={{ flex: 1 }}></Box>
            )}
          </Stack>
        </CardContent>
      </Card>
    </Box>
  );
}

export default IntestazioneNoleggio;
