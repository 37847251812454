import { CheckCircle } from '@mui/icons-material';
import Checkbox from 'react-custom-checkbox';
import { brand } from '../../../theme/themePrimitives';

const CustomCheckbox = ({
  onChange,
  checked,
  size = '18px',
  iconSize = '14px',
  disabled = false,
}) => {
  const className =
    'custom-checkbox' + (disabled ? ' custom-checkbox-readonly' : '');
  return (
    <Checkbox
      icon={
        <CheckCircle
          sx={{
            height: iconSize,
            width: iconSize,
            color: 'white',
          }}
        />
      }
      name="flg_sdd"
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      borderColor={brand[400]}
      className={className}
      size={size}
      style={{
        cursor: disabled ? 'not-allowed' : 'pointer',
        pointerEvents: 'auto',
        marginTop: '4px',
        backgroundColor: checked ? brand[400] : 'transparent',
        '& div': {
          color: 'red',
        },
      }}
    />
  );
};

export default CustomCheckbox;
