import { useEffect } from 'react';
import {
  Autocomplete,
  Chip,
  FormHelperText,
  MenuItem,
  TextField,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getListaCategorieProdotti } from '../../../redux/noleggi/operations';

const SelectCategoriaProdotto = ({
  name,
  placeholder,
  value,
  onChange,
  errors,
  readonly = false,
  multiple = false,
}) => {
  const { categorieProdotti } = useSelector(state => state.noleggi);
  const dispatch = useDispatch();

  useEffect(() => {
    if (categorieProdotti.length == 0) {
      dispatch(getListaCategorieProdotti());
    }
  }, []);

  const _onChange = (e, wasSelected) => {
    if (multiple) {
      const oldValues = value;
      let newValues;
      if (wasSelected) {
        newValues = oldValues.filter(v => v !== e.target.value);
      } else {
        newValues = [...oldValues, e.target.value];
      }
      onChange({
        target: {
          name: name,
          value: newValues,
        },
      });
    } else {
      onChange({
        target: {
          name: name,
          value: e.target.value,
          descrizione:
            e.target.value == null
              ? null
              : categorieProdotti.find(
                  categoria => categoria.id == e.target.value
                ).descrizione,
        },
      });
    }
  };

  const defaultValue = multiple ? [] : null;

  const isSelected = key => {
    if (multiple) {
      return value.includes(key);
    } else {
      return value === key;
    }
  };

  const selectedValues = () => {
    if (multiple) {
      return value.map(v => {
        return categorieProdotti.filter(c => c.id === v)[0];
      });
    } else {
      return categorieProdotti.filter(c => c.id === value)[0];
    }
  };

  return (
    <>
      <Autocomplete
        autoComplete
        autoHighlight
        disabled={readonly}
        multiple={multiple}
        size="small"
        name={name}
        options={categorieProdotti}
        sx={{
          '& .MuiButtonBase-root': {
            padding: '10px',
            height: '10px',
            width: '10px',
            marginRight: '5px',
          },
        }}
        getOptionLabel={option => option.descrizione}
        value={selectedValues() || defaultValue}
        onChange={(e, newVal) => {
          if (multiple) {
            onChange({
              target: {
                name: name,
                value: newVal.map(v => v.id),
              },
            });
          } else {
            onChange({
              target: {
                name: name,
                value: newVal == null ? null : newVal.id,
                descrizione: newVal == null ? null : newVal.descrizione,
              },
            });
          }
        }}
        renderInput={params => (
          <TextField placeholder={placeholder} {...params} />
        )}
        renderTags={tagValue =>
          tagValue.map(option => {
            if (option == null) {
              return <> </>;
            }
            return <Chip key={option?.id} label={option?.descrizione} />;
          })
        }
        getOptionKey={option => option.id}
        renderOption={option => (
          <MenuItem
            selected={isSelected(option.key)}
            onClick={() => {
              _onChange(
                { target: { name: name, value: option.key } },
                isSelected(option.key)
              );
            }}
          >
            {categorieProdotti.filter(v => v.id === option.key)[0].descrizione}
          </MenuItem>
        )}
      />

      {errors && (
        <FormHelperText sx={{ color: 'error.main' }}>{errors}</FormHelperText>
      )}
    </>
  );
};

export default SelectCategoriaProdotto;
