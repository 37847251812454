import Typography from '@mui/material/Typography';

export default function ActionErrorMessage(error) {
  if (error == null) {
    return null;
  }
  if (typeof error === 'string') {
    return <Typography color="error">{error}</Typography>;
  }

  return <Typography color="error">{error.error}</Typography>;
}
