import { Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import FirmatarioCard from './FirmatarioCard';
import ActionErrorMessage from '../../../components/ActionErrorMessage';

export default function Firmatario() {
  const navigate = useNavigate();
  const location = useLocation();
  const [idFornitore, setIdFornitore] = useState(null);
  const [firmatarioData, setFirmatarioData] = useState(null);
  const [loadingError, setLoadingError] = useState(null);
  const initialState = { id: null };

  const onPageLoad = async () => {
    if (!location?.state?.idFornitore) {
      navigate(-1);
    }

    setIdFornitore(location?.state?.idFornitore);
    if (location.pathname.includes('new')) {
      setFirmatarioData(initialState);
    } else if (location.pathname.includes('edit')) {
      try {
        setFirmatarioData(location.state.firmatario);
      } catch (error) {
        setLoadingError(error);
      }
    } else {
      navigate(-1);
    }
  };

  const onSaved = () => {
    navigate(-1);
  };

  useEffect(() => {
    onPageLoad();
  }, [location.pathname]);

  return (
    <Stack>
      {loadingError != null && <ActionErrorMessage error={loadingError} />}
      {firmatarioData != null ? (
        <FirmatarioCard
          isNewFirmatario={firmatarioData.id == null}
          firmatarioData={firmatarioData}
          onSaved={onSaved}
          idFornitore={idFornitore}
        />
      ) : (
        <></>
      )}
    </Stack>
  );
}
