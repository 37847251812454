import { useEffect, useState } from 'react';

const useZod = ({ schema, initialFormData }) => {
  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState({});
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    const result = schema.safeParse(formData ?? {});
    setIsValid(result.success);
  }, [formData]);

  const handleInputChange = e => {
    const { name, value, type, checked } = e.target;
    //se str trim()
    let _value = value;
    if (typeof value === 'string') {
      _value = value.trim();
    }
    setFormData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : _value,
    }));
  };

  const validate = ({ fakeRun = false } = {}) => {
    setErrors({});
    const result = schema.safeParse(formData ?? {});

    if (!result.success) {
      if (fakeRun) {
        return { success: false };
      }

      const formattedErrors = result.error.errors.reduce((acc, err) => {
        acc[err.path[0]] = err.message;
        return acc;
      }, {});
      setErrors(formattedErrors);
      return { success: false };
    }

    return { success: true, data: result.data };
  };

  const resetErrors = () => {
    setErrors({});
  };

  return {
    handleInputChange,
    validate,
    setFormData,
    isValid,
    formData,
    errors,
    resetErrors,
  };
};

export default useZod;
