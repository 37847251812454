import {
  Box,
  Button,
  Card,
  FormControl,
  FormLabel,
  Grid2 as Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LoadingMask from '../../../components/LoadingMask';
import { ArrowBackIos, Check, Close, Save } from '@mui/icons-material';
import useProduttoreCard from '../hook/useProduttoreCard';
import { useDispatch, useSelector } from 'react-redux';
import { showSnackbar } from '../../../redux/app/slice';
import SelectProvince from '../../Noleggi/components/SelectProvince';
import { nuovoProduttore } from '../../../redux/produttori/api';
import { getListaCategorieProdotti } from '../../../redux/noleggi/operations';
import { getCategorie } from '../../../redux/produttori/api';
import { GoogleMap, MarkerF } from '@react-google-maps/api';
import SearchMap from '../../../components/SearchMap';
import SelectCategoriaProdotto from '../../Noleggi/components/SelectCategoriaProdotto';

const ProduttoreCard = forwardRef(
  ({ isNewProduttore, produttoreData, onSaved, embedded = false }, ref) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [coord, setCoord] = useState({ lat: 44.390789, lng: 7.820823 });
    const [locationFields, setLocationFields] = useState([]);
    const { formData, setFormData, handleInputChange, errors, handleSubmit } =
      useProduttoreCard(produttoreData);

    const { categorieProdotti } = useSelector(state => state.noleggi);
    useEffect(() => {
      if (categorieProdotti.length == 0) {
        dispatch(getListaCategorieProdotti());
      }
    }, []);

    const onProduttoreLoaded = produttore => {
      setFormData(produttore);
    };

    const onNuovoProduttore = () => {
      onSalva();
    };

    useImperativeHandle(ref, () => ({
      onSalva,
    }));

    const onSalva = async () => {
      const validation = handleSubmit();
      if (validation === -1) {
        return;
      }

      setIsLoading(true);
      try {
        const result = await nuovoProduttore(formData);
        onProduttoreLoaded(result);
        onSaved(result);
      } catch (error) {
        dispatch(showSnackbar({ message: error, severity: 'error' }));
      } finally {
        setIsLoading(false);
      }
    };

    const loadCategorie = async () => {
      setIsLoading(true);
      try {
        const result = await getCategorie();
        setFormData({
          ...formData,
          categorie: [],
          categorie_disponibili: result,
        });
      } catch (error) {
        dispatch(showSnackbar({ message: error, severity: 'error' }));
      } finally {
        setIsLoading(false);
      }
    };

    useEffect(() => {
      if (!isNewProduttore) {
        if (
          produttoreData?.sede_latitudine &&
          produttoreData?.sede_longitudine
        ) {
          setCoord({
            lat: parseFloat(produttoreData.sede_latitudine),
            lng: parseFloat(produttoreData.sede_longitudine),
          });
        }
      } else {
        loadCategorie();
      }
    }, [isNewProduttore]);

    const center = {
      lat: coord.lat,
      lng: coord.lng,
    };

    useEffect(() => {
      if (locationFields.length > 0) {
        let tmpFormData = {
          ...formData,
        };
        const indirizzo = locationFields.filter(item => {
          if (item.types[0] == 'route') {
            return item.long_name;
          }
        });
        if (indirizzo[0]) {
          tmpFormData.sede_indirizzo = indirizzo[0].long_name;
        }
        const civico = locationFields.filter(item => {
          if (item.types[0] == 'street_number') {
            return item.long_name;
          }
        });
        if (civico[0]) {
          tmpFormData.sede_civico = civico[0].long_name;
        }
        const provincia = locationFields.filter(item => {
          if (item.types[0] == 'administrative_area_level_2') {
            return item.long_name;
          }
        });
        if (provincia[0]) {
          tmpFormData.sede_provincia = provincia[0].short_name;
        }
        const citta = locationFields.filter(item => {
          if (item.types[0] == 'administrative_area_level_3') {
            return item.long_name;
          }
        });
        if (citta[0]) {
          tmpFormData.sede_citta = citta[0].long_name;
        }
        const cap = locationFields.filter(item => {
          if (item.types[0] == 'postal_code') {
            return item.long_name;
          }
        });
        if (cap[0]) {
          tmpFormData.sede_cap = cap[0].long_name;
        }
        if (coord) {
          tmpFormData.sede_latitudine = coord.lat.toString();
          tmpFormData.sede_longitudine = coord.lng.toString();
        }

        setFormData(tmpFormData);
      }
    }, [coord]);

    return (
      <Box sx={{ backgroundColor: 'background.paper' }}>
        {isLoading && <LoadingMask />}

        {!embedded && (
          <Stack
            justifyContent="space-between"
            flexDirection="row"
            sx={{
              backgroundColor: 'background.primaryLight',
              p: 2,
              borderRadius: '4px',
            }}
          >
            <Stack
              flexDirection="row"
              alignItems="center"
              sx={{ flex: 1 }}
              gap={1}
            >
              <IconButton
                sx={{ border: 'none' }}
                size="small"
                onClick={() => navigate(-1)}
              >
                <ArrowBackIos />
              </IconButton>
              <Box sx={{ ml: 1 }}>
                <Typography
                  component="h4"
                  variant="h6"
                  sx={{ fontSize: 14 }}
                  color="primary"
                >
                  Gestione Produttore
                </Typography>
                <Stack flexDirection="row">
                  <Typography component="h3" variant="h6" sx={{ fontSize: 17 }}>
                    {isNewProduttore
                      ? 'Crea Nuovo Produttore'
                      : 'Visualizza Produttore'}
                  </Typography>
                </Stack>
              </Box>
              {!isNewProduttore ? (
                <Stack
                  flexDirection="row"
                  alignItems={'center'}
                  justifyContent={'center'}
                  sx={{ flex: 1 }}
                >
                  {formData.flg_verificato ? (
                    <>
                      <Check sx={{ color: 'green', mr: 3 }} />
                      <Typography
                        component="h4"
                        variant="h6"
                        sx={{ fontSize: 14 }}
                        color="primary"
                      >
                        Il Produttore è stato correttamente verificato dal
                        Noleggiatore.
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Close sx={{ color: 'red', mr: 3 }} />
                      <Typography
                        component="h4"
                        variant="h6"
                        sx={{ fontSize: 14 }}
                        color="primary"
                      >
                        Il Produttore non è ancora stato verificato dal
                        Noleggiatore.
                      </Typography>
                    </>
                  )}
                </Stack>
              ) : (
                <></>
              )}
            </Stack>

            {isNewProduttore ? (
              <Button
                size="small"
                variant="contained"
                startIcon={<Save />}
                onClick={onNuovoProduttore}
              >
                CREA PRODUTTORE
              </Button>
            ) : (
              <></>
            )}
          </Stack>
        )}

        <Card
          sx={{
            backgroundColor: 'background.default',
            m: 0,
            border: embedded ? 'none' : 'auto',
          }}
        >
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle2" sx={{ fontFamily: 'Poppins-Bold' }}>
              Dati Produttore
            </Typography>
          </Box>

          <Grid container spacing={2}>
            <Grid size={{ xs: 12, sm: 4 }}>
              <FormControl fullWidth>
                <FormLabel required htmlFor="ragione_sociale">
                  Ragione Sociale
                </FormLabel>
                <TextField
                  placeholder="Ragione Sociale"
                  name="ragione_sociale"
                  variant="outlined"
                  value={formData?.ragione_sociale}
                  onChange={handleInputChange}
                  fullWidth
                  error={!!errors.ragione_sociale}
                  helperText={errors.ragione_sociale}
                  slotProps={{
                    input: {
                      disabled: !isNewProduttore ? true : false,
                    },
                  }}
                />
              </FormControl>
            </Grid>

            <Grid size={{ xs: 12, sm: 4 }}>
              <FormControl fullWidth>
                <FormLabel required htmlFor="cf">
                  Codice Fiscale
                </FormLabel>
                <TextField
                  placeholder="Codice Fiscale"
                  name="cf"
                  variant="outlined"
                  value={formData?.cf}
                  onChange={handleInputChange}
                  fullWidth
                  error={!!errors.cf}
                  helperText={errors.cf}
                  slotProps={{
                    input: {
                      disabled: !isNewProduttore ? true : false,
                    },
                  }}
                />
              </FormControl>
            </Grid>

            <Grid size={{ xs: 12, sm: 4 }}>
              <FormControl fullWidth>
                <FormLabel required htmlFor="piva">
                  Partita IVA
                </FormLabel>
                <TextField
                  placeholder="Partita IVA"
                  name="piva"
                  variant="outlined"
                  value={formData?.piva}
                  onChange={handleInputChange}
                  fullWidth
                  error={!!errors.piva}
                  helperText={errors.piva}
                  slotProps={{
                    input: {
                      disabled: !isNewProduttore ? true : false,
                    },
                  }}
                />
              </FormControl>
            </Grid>
            {/* <Grid size={{ xs: 12, sm: 12 }}>
              <FormControl fullWidth>
                <FormLabel required htmlFor="categorie">Categorie</FormLabel>
                <Select
                  name="categorie"
                  multiple
                  value={formData?.categorie || []}
                  onChange={handleInputChange}
                  input={
                    <OutlinedInput id="select-multiple-chip" label="Chip" />
                  }
                  fullWidth
                  error={!!errors.categorie}
                  renderValue={selected => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map(value => (
                        <Chip
                          key={value}
                          label={
                            categorieProdotti.find(
                              categoria => categoria.id == value
                            ).descrizione
                          }
                        />
                      ))}
                    </Box>
                  )}
                  slotProps={{
                    input: {
                      disabled: !isNewProduttore ? true : false,
                    },
                  }}
                >
                  {categorieProdotti.map(categoria => (
                    <MenuItem key={categoria.id} value={categoria.id}>
                      {categoria.descrizione}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error>{errors.categorie}</FormHelperText>
              </FormControl>
            </Grid> */}
          </Grid>

          <Box>
            <Box sx={{ mt: 2 }}>
              <FormLabel required htmlFor="categorie">
                Categorie Prodotti
              </FormLabel>
              <SelectCategoriaProdotto
                multiple
                placeholder="Categoria Prodotto"
                name="categorie"
                value={formData?.categorie || []}
                onChange={handleInputChange}
                errors={errors.categorie}
                readonly={!isNewProduttore ? true : false}
              />
            </Box>
          </Box>

          <Box sx={{ mt: 4, mb: 0 }}>
            <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
              Indirizzo:
            </Typography>
          </Box>
          <Grid container spacing={2}>
            {isNewProduttore ? (
              <Box sx={{ width: '100%', display: 'block' }}>
                <SearchMap
                  readOnly={!isNewProduttore ? true : false}
                  setCoord={setCoord}
                  setLocationFields={setLocationFields}
                />
              </Box>
            ) : (
              <></>
            )}

            <Grid size={{ xs: 12, sm: 3 }}>
              <FormControl fullWidth>
                <FormLabel required htmlFor="sede_indirizzo">
                  Indirizzo Sede
                </FormLabel>
                <TextField
                  placeholder="Indirizzo Sede"
                  name="sede_indirizzo"
                  value={formData?.sede_indirizzo}
                  onChange={handleInputChange}
                  fullWidth
                  error={!!errors.sede_indirizzo}
                  helperText={errors.sede_indirizzo}
                  slotProps={{
                    input: {
                      disabled: !isNewProduttore ? true : false,
                    },
                  }}
                />
              </FormControl>
            </Grid>
            <Grid size={{ xs: 6, sm: 2 }}>
              <FormControl fullWidth>
                <FormLabel required htmlFor="sede_civico">
                  N. Civico
                </FormLabel>
                <TextField
                  placeholder="N. Civico"
                  name="sede_civico"
                  value={formData?.sede_civico}
                  onChange={handleInputChange}
                  fullWidth
                  error={!!errors.sede_civico}
                  helperText={errors.sede_civico}
                  slotProps={{
                    input: {
                      disabled: !isNewProduttore ? true : false,
                    },
                  }}
                />
              </FormControl>
            </Grid>
            <Grid size={{ xs: 6, sm: 2 }}>
              <FormControl fullWidth>
                <FormLabel required htmlFor="sede_cap">
                  CAP
                </FormLabel>
                <TextField
                  placeholder="CAP"
                  name="sede_cap"
                  value={formData?.sede_cap}
                  onChange={handleInputChange}
                  fullWidth
                  error={!!errors.sede_cap}
                  helperText={errors.sede_cap}
                  slotProps={{
                    input: {
                      disabled: !isNewProduttore ? true : false,
                    },
                  }}
                />
              </FormControl>
            </Grid>
            <Grid size={{ xs: 12, sm: 2 }}>
              <FormControl fullWidth>
                <FormLabel required htmlFor="sede_citta">
                  Città
                </FormLabel>
                <TextField
                  placeholder="Città"
                  name="sede_citta"
                  value={formData?.sede_citta}
                  onChange={handleInputChange}
                  fullWidth
                  error={!!errors.sede_citta}
                  helperText={errors.sede_citta}
                  slotProps={{
                    input: {
                      disabled: !isNewProduttore ? true : false,
                    },
                  }}
                />
              </FormControl>
            </Grid>

            <Grid size={{ xs: 12, sm: 3 }}>
              <FormControl fullWidth>
                <FormLabel required htmlFor="sede_provincia">
                  Provincia
                </FormLabel>
                <SelectProvince
                  name="sede_provincia"
                  placeholder="Provincia"
                  value={formData?.sede_provincia}
                  onChange={handleInputChange}
                  errors={errors.sede_provincia}
                  readOnly={!isNewProduttore ? true : false}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid sx={{ mb: 2, mt: 1 }} container spacing={2}>
            <GoogleMap
              mapContainerStyle={{
                width: '100%',
                height: '400px',
              }}
              center={center}
              zoom={14}
            >
              <MarkerF position={coord} />
            </GoogleMap>
          </Grid>
        </Card>
      </Box>
    );
  }
);

export default ProduttoreCard;
