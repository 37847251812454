import {
  Stack,
  Typography,
  Box,
  Divider,
  Card,
  useTheme,
  useMediaQuery,
} from '@mui/material';

import { LoadingButton } from '@mui/lab';
import BeniModificaTable from './BeniModificaTable';

const RichiestaModificaStep1 = ({
  onRescindiContratto,
  isRescindiLoading,
  onConfermaModifiche,
  isConfermaLoading,
  dettagli,
  setDettagli,
}) => {
  const onQtaChange = (id, qta) => {
    setDettagli(prev => {
      return prev.map(dettaglio => {
        if (dettaglio.id === id) {
          return {
            ...dettaglio,
            quantita: dettaglio.quantita_attuale - qta,
            quantita_cessare: qta,
          };
        }
        return dettaglio;
      });
    });
  };

  const theme = useTheme();
  const matchMd = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Stack>
      <Card sx={{ backgroundColor: 'background.default' }}>
        <Stack flexDirection="row" alignItems={'center'}>
          <Box>
            <Typography
              variant="body1"
              color="textPrimary"
              sx={{ fontWeight: 'bold' }}
            >
              Vuoi rescindere l'intero contratto?
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Rescindendo il contratto richiedi l'annullamento della pratica di
              noleggio
            </Typography>
          </Box>
          <LoadingButton
            variant="contained"
            onClick={onRescindiContratto}
            size="small"
            loading={isRescindiLoading}
            sx={{ ml: 4 }}
          >
            RESCINDI CONTRATTO
          </LoadingButton>
        </Stack>
      </Card>
      <Divider sx={{ my: 4 }} />
      <Card
        sx={{
          backgroundColor: 'background.default',
          border: 'none',
          p: 1,
        }}
      >
        <Typography
          variant="body1"
          color="textPrimary"
          sx={{ fontWeight: 'bold' }}
        >
          Inserisci Numero di Beni da rescindere:
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Il numero di beni da rescindere deve essere inferiore a quello attuale
          per procedere con la modifica.
        </Typography>
        <Box sx={{ mt: 2 }}>
          <BeniModificaTable
            rows={dettagli}
            viewMode="richiestaModifica"
            onQtaChange={onQtaChange}
          />
        </Box>
      </Card>
      <Box
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'row',
          mt: 4,
        }}
      >
        <LoadingButton
          variant="contained"
          onClick={onConfermaModifiche}
          size="small"
          loading={isConfermaLoading}
          sx={{ mt: 4 }}
        >
          CONFERMA MODIFICHE
        </LoadingButton>
      </Box>
    </Stack>
  );
};

export default RichiestaModificaStep1;
