import { z } from 'zod';
import useZod from '../../../hooks/useZod';

const formSchema = z.object({
  ragione_sociale: z
    .string()
    .min(1, { message: 'La ragione sociale è obbligatoria' }),
  cf: z.string().min(11, { message: 'Codice Fiscale non valido' }),
  piva: z.string().min(11, { message: 'Partita IVA non valida' }),
  mail: z.string().email({ message: 'Email non valida' }),
  telefono: z.string().min(10, { message: 'Numero di telefono non valido' }),
  locazione_indirizzo: z.string().min(1, { message: 'Indirizzo non valido' }),
  locazione_civico: z.string().min(1, { message: 'Numero civico non valido' }),
  locazione_cap: z.string().min(5, { message: 'CAP non valido' }),
  locazione_citta: z.string().min(1, { message: 'Città non valida' }),
  locazione_provincia: z.string().min(2, { message: 'Provincia non valida' }),
  sede_indirizzo: z.string().min(1, { message: 'Indirizzo non valido' }),
  sede_civico: z.string().min(1, { message: 'Numero civico non valido' }),
  sede_cap: z.string().min(5, { message: 'CAP non valido' }),
  sede_citta: z.string().min(1, { message: 'Città non valida' }),
  sede_provincia: z.string().min(2, { message: 'Provincia non valida' }),
  pec: z.string().email({ message: 'PEC non valida' }),
  codice_sdi: z
    .string()
    .min(7, { message: 'Codice SDI non valido' })
    .optional(),
  // sede_latitudine: z.string().min(1, { message: 'Latitudine non valida' }),
  // sede_longitudine: z.string().min(1, { message: 'Latitudine non valida' }),
  // locazione_latitudine: z.string().min(1, { message: 'Latitudine non valida' }),
  // locazione_longitudine: z
  //   .string()
  //   .min(1, { message: 'Latitudine non valida' }),
});

const useInserisciDatiCliente = azienda => {
  const {
    formData,
    setFormData,
    errors,
    handleInputChange,
    validate,
    resetErrors,
    isValid,
  } = useZod({
    schema: formSchema,
    initialFormData: azienda || {
      ragione_sociale: '',
      cf: '',
      piva: '',
      mail: '',
      telefono: '',
      locazione_indirizzo: '',
      locazione_civico: '',
      locazione_cap: '',
      locazione_citta: '',
      locazione_provincia: '',
      sede_indirizzo: '',
      sede_civico: '',
      sede_cap: '',
      sede_citta: '',
      sede_provincia: '',
      iban: '',
      pec: '',
      referente_cognome_nome: '',
      referente_email: '',
      referente_telefono: '',
      referente_note: '',
      codice_sdi: '',
      sede_latitudine: '',
      sede_longitudine: '',
      locazione_latitudine: '',
      locazione_longitudine: '',
    },
  });

  const handleCopyAddress = () => {
    setFormData(prevState => ({
      ...prevState,
      locazione_indirizzo: prevState.sede_indirizzo,
      locazione_civico: prevState.sede_civico,
      locazione_cap: prevState.sede_cap,
      locazione_citta: prevState.sede_citta,
      locazione_provincia: prevState.sede_provincia,
      locazione_latitudine: prevState.sede_latitudine,
      locazione_longitudine: prevState.sede_longitudine,
    }));
  };

  const handleSubmit = ({ requiresAll = false, fakeRun = false } = {}) => {
    if (!requiresAll) {
      //se tutti i valori sono empty non valido
      if (
        !Object.values(formData ?? {}).some(
          value => value !== '' && value !== false
        )
      ) {
        resetErrors();
        return 0;
      }
    }

    const validation = validate({ fakeRun: fakeRun });
    if (validation.success) {
      return 1;
    }

    return -1;
  };

  return {
    setFormData,
    formData,
    errors,
    handleInputChange,
    handleCopyAddress,
    handleSubmit,
    isValid,
  };
};

export default useInserisciDatiCliente;
