import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid2 as Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { ArrowForwardIos, Search } from '@mui/icons-material';
import NoleggiTable from '../Noleggi/components/NoleggiTable';
import useNoleggiTable from '../Noleggi/hook/useNoleggiTable';
import IntestazioneNoleggioVerticale from '../Noleggi/components/IntestazioneNoleggioVerticale';
import { useDispatch, useSelector } from 'react-redux';
import { getDocumenti } from '../../redux/noleggi/operations';
import GraficoTorta from '../Report/components/GraficoTorta';
import useOperazioniDataPagamenti from '../Report/hook/useOperazioniDataPagamenti';
import { formatCurrency } from '../../utils/formatNumber';
import DocumentiList from '../Noleggi/components/DocumentiList';
import DocumentiGrid from '../Noleggi/components/DocumentiGrid';
import LoadingMask from '../../components/LoadingMask';
import {
  downloadDocumento,
  salvaRichiestaAzienda,
} from '../../redux/noleggi/api';
import { downloadBlob } from '../../utils/download';
import { showSnackbar } from '../../redux/app/slice';
import { useNavigate } from 'react-router-dom';
import Slider from './components/Slider';
import useResizer from '../../hooks/useResizer';

export default function Dashboard() {
  const mainContentRef = useRef(null);
  useResizer(mainContentRef);
  const theme = useTheme();
  const navigate = useNavigate();
  const matchXl = useMediaQuery(theme.breakpoints.up('xl'));
  const matchLg = useMediaQuery(theme.breakpoints.up('lg'));
  const [isLoading, setIsLoading] = useState(false);

  const [noleggio, setNoleggio] = useState({
    durata_mesi: 24,
    importo: 0,
    flg_assicurazione: false,
  });
  const dispatch = useDispatch();

  const { attivi, filter, getNoleggiAction } = useNoleggiTable([
    'confermati',
    'recenti',
    'attivi',
  ]);

  const { isLoadingOperazioniData, operazioniData } =
    useOperazioniDataPagamenti();

  const onDownloadDocumento = async (name, id) => {
    setIsLoading(true);
    try {
      const pdfData = await downloadDocumento(id);
      downloadBlob(pdfData, 'application/pdf', name + '.pdf');
    } catch (error) {
      dispatch(showSnackbar({ message: error, severity: 'error' }));
    } finally {
      setIsLoading(false);
    }
  };

  const { documenti, getDocumentiAction } = useSelector(state => state.noleggi);
  useEffect(() => {
    dispatch(getDocumenti());
  }, []);

  const documentiLimit = documenti.slice(0, 6);

  const onVisualizzaDocumenti = () => {
    navigate('/documenti-fatture');
  };

  const onVisualizzaReport = () => {
    navigate('/report-pagamenti');
  };

  const onSalva = async () => {
    setIsLoading(true);
    try {
      await salvaRichiestaAzienda(noleggio);
      dispatch(
        showSnackbar({
          message: 'Proposta salvata con successo',
          severity: 'success',
        })
      );
    } catch (error) {
      dispatch(showSnackbar({ message: error, severity: 'error' }));
    } finally {
      setIsLoading(false);
    }
  };

  const onCompleta = () => {
    navigate('/noleggi/noleggio/completa', {
      state: {
        durata_mesi: noleggio.durata_mesi,
        importo: noleggio.importo,
      },
    });
  };

  return (
    <>
      {isLoading && <LoadingMask />}
      <Box
        sx={{
          backgroundColor: 'background.paper',
          overflow: matchXl ? 'hidden' : 'auto',
        }}
        ref={mainContentRef}
      >
        <Grid container spacing={1} sx={{ height: '100%' }}>
          <Grid
            size={{ xs: 12, md: 12, xl: 5 }}
            sx={{
              height: matchXl ? '100%' : 'auto',
              overflow: 'auto',
            }}
          >
            <Card
              sx={{
                backgroundColor: 'background.default',
                p: 0.5,
                height: '100%',
              }}
            >
              <IntestazioneNoleggioVerticale
                noleggio={noleggio}
                setNoleggio={setNoleggio}
                showButtons
                importoRow={true}
                assicurazioneRow={true}
                durataRow={matchXl ? false : true}
                fullHeight
                onCompleta={onCompleta}
                onSalva={onSalva}
                spacing={2}
              />
            </Card>
          </Grid>
          <Grid
            size={{ xs: 12, md: 12, xl: 7 }}
            sx={{
              height: matchXl ? '100%' : 'auto',
              overflowY: 'auto',
            }}
          >
            <Box>
              <Card sx={{ backgroundColor: 'background.default' }}>
                {/* <Typography component="h2" variant="h4">
                  Benvenuto {user?.ragione_sociale},
                </Typography> */}
                <Stack
                  flexDirection="row"
                  justifyContent="space-between"
                  sx={{ mb: 1, mt: 0 }}
                >
                  <Box>
                    <Stack flexDirection="row">
                      <Typography sx={{ fontFamily: 'Poppins-Bold' }}>
                        Visualizza i&nbsp;
                        <Typography
                          component="span"
                          variant="body1"
                          sx={{ fontFamily: 'Poppins-Bold' }}
                          color="primary"
                        >
                          Noleggi Attivi
                        </Typography>
                      </Typography>
                    </Stack>
                    <Typography variant="caption">
                      I noleggi che hai attualmente in corso, seleziona per
                      visualizzarne i dettagli.
                    </Typography>
                  </Box>
                </Stack>
                <TextField
                  onChange={e => filter(e.target.value, 'attivi')}
                  sx={{ mb: 1 }}
                  placeholder="Cerca"
                  size="small"
                  variant="outlined"
                  slotProps={{
                    input: {
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search sx={{ width: 20, height: 20 }} />
                        </InputAdornment>
                      ),
                    },
                  }}
                />

                <NoleggiTable
                  noleggi={attivi?.noleggiAttivi ?? []}
                  isLoading={getNoleggiAction?.loading ?? false}
                  initialState={{
                    pagination: { paginationModel: { pageSize: 5 } },
                  }}
                  pageSizeOptions={[5, 10, 20, 50]}
                  showSearch={false}
                />
              </Card>

              <Grid container spacing={1} sx={{ mt: 1 }}>
                <Grid size={{ xs: 12, md: 12, lg: 6 }}>
                  <Card
                    sx={{
                      backgroundColor: 'background.default',
                      height: '100%',
                      flexGrow: 1,
                    }}
                  >
                    <Stack flexDirection="row" justifyContent={'space-between'}>
                      <Typography sx={{ fontFamily: 'Poppins-Bold' }}>
                        Visualizza&nbsp;
                        <Typography
                          component="span"
                          variant="body1"
                          sx={{ fontFamily: 'Poppins-Bold' }}
                          color="primary"
                        >
                          Report Pratiche
                        </Typography>
                      </Typography>
                      <Button
                        variant="contained"
                        color="white"
                        size="xsmall"
                        onClick={onVisualizzaReport}
                        endIcon={<ArrowForwardIos />}
                        sx={{ fontFamily: 'Poppins-Bold' }}
                      >
                        TUTTI
                      </Button>
                    </Stack>
                    <Typography variant="caption">
                      Tutti i report sono disponibili nella sezione report
                    </Typography>
                    {isLoadingOperazioniData ? (
                      <Stack
                        flexDirection={'row'}
                        justifyContent={'center'}
                        sx={{ mt: 2 }}
                      >
                        <CircularProgress size={30} />
                      </Stack>
                    ) : (
                      <Box sx={{ mt: 2 }}>
                        {operazioniData?.counter > 0 ? (
                          <>
                            <Slider>
                              <Stack
                                sx={{ flex: 1, marginTop: 2, marginBottom: 2 }}
                              >
                                <Stack
                                  flexDirection="row"
                                  justifyContent="space-between"
                                  alignItems={'center'}
                                >
                                  <Typography sx={{ fontWeight: 'bold' }}>
                                    Tot. Importo dei Noleggi:
                                  </Typography>
                                  <Typography>
                                    {formatCurrency(operazioniData?.counter)}{' '}
                                    (Senza IVA)
                                  </Typography>
                                </Stack>
                                <GraficoTorta
                                  labelPos="left"
                                  labelAlign="center"
                                  value={operazioniData?.stati}
                                  small={true}
                                />
                              </Stack>
                              <Stack
                                sx={{ flex: 1, marginTop: 2, marginBottom: 2 }}
                              >
                                <Stack
                                  flexDirection="row"
                                  justifyContent="space-between"
                                  alignItems={'center'}
                                >
                                  <Typography
                                    sx={{
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    Importo per Noleggio:
                                  </Typography>
                                </Stack>
                                <GraficoTorta
                                  labelPos="left"
                                  labelAlign="center"
                                  value={operazioniData?.importi_noleggi}
                                  small={true}
                                />
                              </Stack>
                            </Slider>
                          </>
                        ) : (
                          <Typography variant="caption" color="textSecondary">
                            Nessun dato disponibile
                          </Typography>
                        )}
                      </Box>
                    )}
                  </Card>
                </Grid>
                <Grid size={{ xs: 12, md: 12, lg: 6 }}>
                  <Card
                    sx={{
                      backgroundColor: 'background.default',
                      height: '100%',
                      flexGrow: 1,
                    }}
                  >
                    <Stack flexDirection="row" justifyContent={'space-between'}>
                      <Typography sx={{ fontFamily: 'Poppins-Bold' }}>
                        Visualizza &nbsp;
                        <Typography
                          component="span"
                          variant="body1"
                          sx={{ fontFamily: 'Poppins-Bold' }}
                          color="primary"
                        >
                          Documenti
                        </Typography>
                      </Typography>
                      <Button
                        variant="contained"
                        color="white"
                        size="xsmall"
                        onClick={onVisualizzaDocumenti}
                        endIcon={<ArrowForwardIos />}
                        sx={{ fontFamily: 'Poppins-Bold' }}
                      >
                        TUTTI
                      </Button>
                    </Stack>
                    <Typography variant="caption" sx={{ mb: 2 }}>
                      Tutti i documenti sono disponibili nella sezione documenti
                    </Typography>
                    {getDocumentiAction.loading ? (
                      <Stack
                        flexDirection={'row'}
                        justifyContent={'center'}
                        sx={{ mt: 2 }}
                      >
                        <CircularProgress size={30} />
                      </Stack>
                    ) : (
                      <Box sx={{ mt: 2 }}>
                        {matchLg ? (
                          <DocumentiList
                            onDownload={onDownloadDocumento}
                            data={documentiLimit}
                          />
                        ) : (
                          <DocumentiGrid
                            onDownload={onDownloadDocumento}
                            data={documentiLimit}
                          />
                        )}
                      </Box>
                    )}
                  </Card>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
