import Stack from '@mui/material/Stack';
import SignInCard from './components/SignInCard';
import { Box, Divider, Typography, Link } from '@mui/material';
import LogoImg from '../../assets/img/Logo Doinn Rental Division.png';
import { useState } from 'react';
import SetTwoFA from './components/SetTwoFA';
import { useDispatch } from 'react-redux';
import { login, setToken } from '../../redux/auth/slice';
import TwoFASignIn from './components/TwoFASignIn';

export default function Login() {
  const dispatch = useDispatch();
  const [twoFaDialogOpen, setTwoFaDialogOpen] = useState(false);
  const [twoFaSignInDialogOpen, setTwoFaSignInDialogOpen] = useState(false);
  const [twoFaData, setTwoFaData] = useState(null);

  const onSignIn = signInData => {
    dispatch(setToken(signInData.access_token));

    if (signInData.qrCode !== null) {
      //deve settupare il 2fa
      setTwoFaData({
        qrCode: signInData.qrCode,
        secret: signInData.secret,
      });
      setTwoFaDialogOpen(true);
    } else {
      //deve inserire il codice 2fa
      setTwoFaSignInDialogOpen(true);
    }
  };

  const onSetTwoFaSubmit = () => {
    setTwoFaDialogOpen(false);
    setTwoFaSignInDialogOpen(true);
  };

  const onTwoFaSignInComplete = signInData => {
    const token = signInData.access_token;
    const user = signInData;
    delete user.access_token;

    dispatch(login({ user: user, token: token }));
  };

  return (
    <Stack
      direction="column"
      component="main"
      sx={[
        {
          justifyContent: 'space-between',
          height: { xs: 'auto', md: '100vh' },
          backgroundColor: 'background.default',
        },
      ]}
    >
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        sx={{
          justifyContent: 'center',
          gap: { xs: 0, sm: 12 },
          height: { xs: 'auto', md: '100vh' },
          m: 'auto',
        }}
      >
        <Stack
          sx={{
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 4,
            maxWidth: 450,
          }}
        >
          <img src={LogoImg} alt="logo" style={{ width: '60%' }} />
        </Stack>
        <Divider orientation="vertical" flexItem />
        <Stack sx={{ justifyContent: 'center', gap: 4, maxWidth: 450 }}>
          <SignInCard onSignIn={onSignIn} />
          <Box sx={{ p: { xs: 2, md: 0 } }}>
            <Typography
              variant="body1"
              color="textSecondary"
              align="right"
              sx={{ fontWeight: 'bold' }}
            >
              Hai bisogno di aiuto?
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 1,
                justifyContent: 'end',
              }}
            >
              <Typography variant="body2" color="textSecondary">
                Contatta l'assistenza Doinn{' '}
                <Link href={'mailto:assistenza@doinnovation.it'}>
                  assistenza@doinnovation.it
                </Link>
              </Typography>
            </Box>
          </Box>
        </Stack>
      </Stack>

      <SetTwoFA
        open={twoFaDialogOpen}
        handleSubmit={onSetTwoFaSubmit}
        twoFaData={twoFaData}
      />
      <TwoFASignIn
        open={twoFaSignInDialogOpen}
        handleClose={() => setTwoFaSignInDialogOpen(false)}
        onComplete={onTwoFaSignInComplete}
      />
    </Stack>
  );
}
