import { useEffect, useState } from 'react';
import {
  Typography,
  Box,
  Stack,
  useTheme,
  Alert,
  Button,
  IconButton,
} from '@mui/material';
import {
  Description,
  FileUpload,
  FileDownload,
  Delete,
} from '@mui/icons-material';
import Dropzone from 'react-dropzone';

const supportedFormatsMaps = {
  pdf: {
    type: 'application/pdf',
    extensions: ['.pdf'],
    label: 'PDF',
  },
  image: {
    type: 'image/*',
    extensions: ['.jpg', '.jpeg', '.png'],
    label: 'Immagini',
  },
};

const CaricaFileConElenco = ({
  documenti = [],
  onNewFilesChanges,
  onDownloadExisting,
  onDeleteExisting,
  acceptedFormats = ['pdf'],
  allowDeleteExisting = true,
  allowMultiple = false,
  onCompletedChanged = null,
}) => {
  const theme = useTheme();
  const [newFiles, setNewFiles] = useState([]);

  // Costruzione dell'oggetto di mapping per i formati accettati
  const acceptedFormatsMaps = {};
  acceptedFormats.forEach(format => {
    acceptedFormatsMaps[supportedFormatsMaps[format].type] =
      supportedFormatsMaps[format].extensions;
  });

  // Etichetta dei formati accettati
  const acceptedFormatsLabel = acceptedFormats
    .map(format => supportedFormatsMaps[format].label)
    .join(', ');

  // Gestione del drop dei file
  const onDrop = acceptedFiles => {
    if (!allowMultiple) {
      const newFile = acceptedFiles[0];
      setNewFiles([newFile]);
      onNewFilesChanges([newFile]);
    } else {
      const _newFiles = [...newFiles];
      _newFiles.push(...acceptedFiles);
      setNewFiles(_newFiles);
      onNewFilesChanges(_newFiles);
    }
  };

  // Rimuove un file dalla lista dei nuovi file caricati
  const removeFile = fileToRemove => {
    let _newFiles = [...newFiles];
    _newFiles = _newFiles.filter(f => f !== fileToRemove);
    setNewFiles(_newFiles);
    onNewFilesChanges(_newFiles);
  };

  useEffect(() => {
    if (onCompletedChanged) {
      // Considera completato se ci sono file nuovi o documenti già presenti
      const hasFiles =
        newFiles.length > 0 || (documenti && documenti.length > 0);
      onCompletedChanged(hasFiles);
    }
  }, [newFiles, documenti, onCompletedChanged]);

  return (
    <Box>
      {/* Elenco dei documenti già presenti */}
      {documenti && documenti.length > 0 && (
        <Box sx={{ mb: 2 }}>
          <Typography variant="h6" sx={{ mb: 1, fontSize: '14px' }}>
            Documenti già caricati:
          </Typography>
          {documenti.map((doc, index) => (
            <Stack
              key={doc.id || index}
              direction="row"
              alignItems="center"
              spacing={1}
              sx={{ mb: 1 }}
            >
              <Description color="primary" sx={{ width: 20, height: 20 }} />
              <Typography variant="body1" sx={{ flexGrow: 1 }}>
                {doc.nome}
              </Typography>
              <IconButton onClick={() => onDownloadExisting(doc)} size="small">
                <FileDownload color="primary" />
              </IconButton>
              {allowDeleteExisting ? (
                <IconButton onClick={() => onDeleteExisting(doc)} size="small">
                  <Delete color="error" />
                </IconButton>
              ) : (
                <></>
              )}
            </Stack>
          ))}
        </Box>
      )}

      {/* Alert per modalità single */}
      {!allowMultiple && (documenti?.length ?? 0) > 0 && (
        <Alert severity="warning" sx={{ mb: 2 }}>
          Se carichi un nuovo file, quello esistente verrà sostituito.
        </Alert>
      )}

      {/* Elenco dei nuovi file caricati */}
      {newFiles && newFiles.length > 0 && (
        <Box>
          {allowMultiple ? (
            <>
              <Typography variant="h6" sx={{ mb: 1, fontSize: '14px' }}>
                Nuovi documenti caricati:
              </Typography>
              <Stack direction="column" spacing={1}>
                {newFiles.map((file, index) => (
                  <Stack
                    key={index}
                    direction="row"
                    alignItems="center"
                    spacing={1}
                  >
                    <Description
                      color="primary"
                      sx={{ width: 20, height: 20 }}
                    />
                    <Typography variant="subtitle2" sx={{ flexGrow: 1 }}>
                      {file.name}
                    </Typography>
                    <IconButton onClick={() => removeFile(file)} size="small">
                      <Delete color="error" />
                    </IconButton>
                  </Stack>
                ))}
              </Stack>
            </>
          ) : (
            <>
              <Typography variant="h6" sx={{ mb: 1, fontSize: '14px' }}>
                Nuovo documento caricato:
              </Typography>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Description color="primary" sx={{ width: 20, height: 20 }} />
                <Typography variant="subtitle2" sx={{ flexGrow: 1 }}>
                  {newFiles[0].name}
                </Typography>
                <Button
                  variant="contained"
                  onClick={() => removeFile(newFiles[0])}
                  size="small"
                >
                  Modifica
                </Button>
              </Stack>
            </>
          )}
        </Box>
      )}

      {/* Area Dropzone */}
      <Dropzone
        onDrop={onDrop}
        accept={acceptedFormatsMaps}
        multiple={allowMultiple}
      >
        {({ getRootProps, getInputProps }) => (
          <section>
            {/* In modalità single, mostra la Dropzone solo se non c'è già un file; in modalità multipla è sempre visibile */}
            {(!allowMultiple && newFiles.length === 0) || allowMultiple ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                <Box
                  {...getRootProps({
                    className: 'dropzone',
                    style: {
                      border: `2px dashed ${theme.palette.primary.main}`,
                      borderRadius: '8px',
                      padding: '20px',
                      maxWidth: '500px',
                      width: '100%',
                      textAlign: 'center',
                      cursor: 'pointer',
                    },
                  })}
                >
                  <input {...getInputProps()} />
                  <Box>
                    <FileUpload
                      sx={{ color: theme.palette.primary.main, fontSize: 40 }}
                    />
                    <Typography variant="subtitle2">
                      Trascina o scegli il file da caricare
                    </Typography>
                    <Typography variant="caption">
                      {acceptedFormatsLabel}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            ) : null}
          </section>
        )}
      </Dropzone>
    </Box>
  );
};

export default CaricaFileConElenco;
