import Stack from '@mui/material/Stack';
import {
  Box,
  Divider,
  Typography,
  Link,
  AppBar,
  Button,
  useTheme,
  Grid2 as Grid,
  useMediaQuery,
} from '@mui/material';
import LogoImg from '../../assets/img/Logo Doinn Rental Division.png';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import IntestazioneNoleggioVerticale from '../Noleggi/components/IntestazioneNoleggioVerticale';
import { useNavigate } from 'react-router-dom';
import { tempRichiesta } from '../../redux/noleggi/api';
import { showSnackbar } from '../../redux/app/slice';
import { LoadingButton } from '@mui/lab';

export default function Home() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const matchMd = useMediaQuery(theme.breakpoints.up('md'));

  const [isRegistraLoading, setIsRegistraLoading] = useState(false);
  const [noleggio, setNoleggio] = useState({
    durata_mesi: 24,
    importo: 0,
    flg_assicurazione: false,
  });

  const onAccediClick = () => {
    navigate('/login');
  };

  const onRegistratiClick = async () => {
    setIsRegistraLoading(true);
    try {
      const response = await tempRichiesta(noleggio);
      navigate('/register', { state: { id: response.id } });
    } catch (error) {
      dispatch(showSnackbar({ message: error, severity: 'error' }));
    } finally {
      setIsRegistraLoading(false);
    }
  };

  return (
    <Stack flexDirection={'column'} sx={{ height: '100vh', width: '100%' }}>
      <AppBar
        position="static"
        elevation={0}
        sx={{
          //gradient white on left and primary on right
          background:
            'linear-gradient(to right, #ffffff, ' +
            theme.palette.background.primaryLight +
            ')',
        }}
      >
        <Stack
          flexDirection={'row'}
          justifyContent={'end'}
          alignItems={'center'}
          sx={{ p: 2 }}
        >
          <Typography
            variant="body1"
            sx={{ color: 'primary.dark', fontWeight: 'bold' }}
          >
            Hai un account?
          </Typography>
          <Button
            variant="contained"
            sx={{ ml: 2, minWidth: '150px' }}
            size="small"
            onClick={onAccediClick}
          >
            ACCEDI
          </Button>
        </Stack>
      </AppBar>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={0} sx={{ height: matchMd ? '100%' : 'auto' }}>
          <Grid
            size={{ xs: 12, md: 5.5 }}
            sx={{
              display: 'flex',
              height: matchMd ? '100%' : 'auto',
            }}
          >
            <Stack
              sx={{
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 0,
                width: '100%',
              }}
            >
              <Box
                sx={{
                  maxWidth: '450px',
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <img
                  src={LogoImg}
                  alt="logo"
                  style={{ width: matchMd ? '60%' : '30%' }}
                />
                <Box>
                  <Typography variant="body1" align="center" fontWeight="bold">
                    Benvenuto sulla piattaforma Doinn Rental,
                  </Typography>
                  <Typography variant="body1" align="center" sx={{ mt: 2 }}>
                    Effettua un preventivo di noleggio con il nostro
                    Preventivatore e registrati per attivare il contratto.
                  </Typography>
                </Box>
              </Box>
            </Stack>
          </Grid>
          {matchMd ? (
            <Grid
              size={{ xs: 1 }}
              sx={{ display: 'flex', justifyContent: 'center' }}
            >
              <Divider
                position="absolute"
                orientation="vertical"
                flexItem
                sx={{ height: '100%' }}
              />
            </Grid>
          ) : (
            <></>
          )}
          <Grid
            size={{ xs: 12, md: 5.5 }}
            sx={{
              height: matchMd ? '100%' : 'auto',
              display: 'flex',
              mt: 4,
            }}
          >
            <Stack
              sx={{
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 0,
                width: '100%',
                px: 4,
              }}
            >
              <Box sx={{ maxWidth: '600px' }}>
                <IntestazioneNoleggioVerticale
                  noleggio={noleggio}
                  setNoleggio={setNoleggio}
                  showTitle={false}
                  importoRow={false}
                />
                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
                  <LoadingButton
                    color="secondary"
                    variant="contained"
                    onClick={onRegistratiClick}
                    loading={isRegistraLoading}
                  >
                    Registra e Completa il Preventivo
                  </LoadingButton>
                </Box>
              </Box>
            </Stack>
          </Grid>
        </Grid>
        <Box
          sx={{
            width: '100%',
            position: matchMd ? 'absolute' : 'relative',
            zIndex: -1,
            left: '20px',
            bottom: '20px',
            p: 2,
            mt: 4,
          }}
        >
          <Typography
            variant="body1"
            color="textSecondary"
            align="left"
            sx={{ fontWeight: 'bold' }}
          >
            Hai bisogno di aiuto?
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 1,
              justifyContent: 'start',
            }}
          >
            <Typography variant="body2" color="textSecondary">
              Contatta l'assistenza Doinn{' '}
              <Link href={'mailto:assistenza@doinnovation.it'}>
                assistenza@doinnovation.it
              </Link>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Stack>
  );
}
