import { z } from 'zod';
import useZod from '../../../hooks/useZod';

const formSchema = z.object({
  cognome_nome: z
    .string()
    .min(1, { message: 'Il cognome e il nome sono obbligatori' }),
  email: z.string().email({ message: 'Inserisci un indirizzo email valido' }),
  data_nascita: z
    .string()
    .min(4, { message: 'La data di nascita è obbligatoria' }),
  qualifica: z.string().min(1, { message: 'La qualifica è obbligatoria' }),
  cf: z.string().min(1, { message: 'Il codice fiscale è obbligatorio' }),
  nazionalita: z.string().min(1, { message: 'La nazionalità è obbligatoria' }),
  telefono: z.string().min(1, { message: 'Il telefono è obbligatorio' }),
});

const useAccountCard = account => {
  const { formData, setFormData, errors, handleInputChange, validate } = useZod(
    {
      schema: formSchema,
      initialFormData: account || {
        cognome_nome: '',
        email: '',
        qualifica: '',
        data_nascita: '',
        cf: '',
        nazionalita: '',
        telefono: '',
      },
    }
  );

  const handleSubmit = () => {
    const validation = validate();
    if (validation.success) {
      return 1;
    }

    return -1;
  };
  return {
    setFormData,
    formData,
    errors,
    handleInputChange,
    handleSubmit,
  };
};

export default useAccountCard;
