import { Stack } from '@mui/material';
import HorizontalBarChartCustom from './HorizontalBarChartCustom';
import IntestazioneGrafici from './IntestazioneGrafici';

export default function GraficoBarreOrizzontale({
  titolo,
  sottotitolo,
  format,
  symbol,
  value,
}) {
  return (
    <Stack sx={{ minHeight: 200, flex: 1, paddingTop: '50px' }}>
      <IntestazioneGrafici titolo={titolo} sottotitolo={sottotitolo} />
      <HorizontalBarChartCustom data={value} format={format} symbol={symbol} />
    </Stack>
  );
}
