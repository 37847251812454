import { Stack } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getNoleggio } from '../../redux/noleggi/api';
import ActionErrorMessage from '../../components/ActionErrorMessage';
import LoadingMask from '../../components/LoadingMask';
import DefinizioneProposta from './components/DefinizioneProposta';
import PraticaNoleggio from './components/PraticaNoleggio';
import { useSelector, useDispatch } from 'react-redux';
import { clearTmpNoleggio } from '../../redux/noleggi/slice';
import { showAlertDialog } from '../../redux/app/slice';

export default function Noleggio() {
  const location = useLocation();
  const dispatch = useDispatch();
  const { noleggioClone, tmpNoleggioData } = useSelector(
    state => state.noleggi
  );
  const [noleggioData, setNoleggioData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingError, setLoadingError] = useState(null);
  const [viewMode, setViewMode] = useState('read');
  const [writeMode, setWriteMode] = useState('new');
  const [key, setKey] = useState(0);
  const [oldPath, setOldPath] = useState(null);
  const componentRef = useRef(null);

  const onPageLoad = async () => {
    setIsLoading(true);
    if (
      location.pathname.includes('new') ||
      location.pathname.includes('completa')
    ) {
      if (location.pathname.includes('completa')) {
        setNoleggioData({
          id: null,
          id_stato: 'DC',
          id_step: null,
          durata_mesi: location.state.durata_mesi,
          importo: location.state.importo,
          flg_assicurazione: false,
          flg_sdd: true,
        });
      } else {
        setNoleggioData({
          id: null,
          id_stato: 'IB',
          id_step: null,
          durata_mesi: 24,
          importo: 0,
          flg_assicurazione: false,
          flg_sdd: true,
        });
      }

      setViewMode('edit');
      setWriteMode('new');
      setIsLoading(false);
    } else if (location.pathname.includes('clone')) {
      setNoleggioData({
        ...noleggioClone,
        id: null,
        id_stato: 'DC',
        id_step: null,
        flg_preventivo: false,
        flg_proposta_firmata: false,
        interventi: [],
        documenti: [],
      });
      setViewMode('edit');
      setWriteMode('clone');
      setIsLoading(false);
    } else if (location.pathname.includes('edit')) {
      const state = location.state;
      setWriteMode('edit');
      if (tmpNoleggioData) {
        setViewMode('edit');
        setIsLoading(false);
        setNoleggioData(tmpNoleggioData);
        dispatch(clearTmpNoleggio());
      } else {
        try {
          const result = await getNoleggio(state.id);
          setNoleggioData(result);
        } catch (error) {
          setLoadingError(error);
        } finally {
          setIsLoading(false);
        }
      }
    }
    setKey(prev => prev + 1);
    setOldPath(location.pathname);
  };

  const askForSave = async () => {
    dispatch(
      showAlertDialog({
        btnConfermaText: 'SALVA E PROSEGUI',
        btnAnnullaText: 'PROSEGUI SENZA SALVARE',
        title: 'Vuoi salvare i dati correnti?',
        message:
          'Stai per uscire e creare una nuova pratica di noleggio. I dati non salvati andranno persi. Vuoi salvarli prima di procedere?',
        onCancel: () => {
          onPageLoad();
        },
        onConfirm: async () => {
          if (componentRef.current == null) {
            onPageLoad();
          }

          const result = await componentRef.current.onSalvaRichiesta(false);
          if (result != -1) {
            onPageLoad();
          }
        },
      })
    );
  };

  useEffect(() => {
    if (oldPath != null && oldPath.includes('new')) {
      if (noleggioData.id_stato == 'IB' || noleggioData.id_stato == 'DC') {
        askForSave();
        return;
      }
    }
    onPageLoad();
  }, [location.pathname]);

  return (
    <Stack key={key}>
      {isLoading && <LoadingMask />}
      {loadingError != null && <ActionErrorMessage error={loadingError} />}
      {noleggioData != null ? (
        viewMode == 'read' ? (
          <PraticaNoleggio
            setNoleggioData={setNoleggioData}
            noleggioData={noleggioData}
            setViewMode={setViewMode}
          />
        ) : (
          <DefinizioneProposta
            ref={componentRef}
            writeMode={writeMode}
            noleggio={noleggioData}
            setNoleggio={setNoleggioData}
            setViewMode={setViewMode}
          />
        )
      ) : (
        <></>
      )}
    </Stack>
  );
}
