import {
  Box,
  Button,
  Card,
  Divider,
  FormControl,
  FormLabel,
  Grid2 as Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useNavigate } from 'react-router-dom';
import LoadingMask from '../../../components/LoadingMask';
import { Add, ArrowBackIos, Check, Close, Save } from '@mui/icons-material';
import useFornitoreCard from '../hook/useFornitoreCard';
import { useDispatch } from 'react-redux';
import { showSnackbar } from '../../../redux/app/slice';
import SelectProvince from '../../Noleggi/components/SelectProvince';
import { nuovoFornitore } from '../../../redux/fornitori/api';
import FirmatariTable from './FirmatariTable';
import { GoogleMap, MarkerF } from '@react-google-maps/api';
import SearchMap from '../../../components/SearchMap';

const FornitoreCard = forwardRef(
  ({ isNewFornitore, fornitoreData, onSaved, embedded = false }, ref) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [coord, setCoord] = useState({ lat: 44.390789, lng: 7.820823 });
    const [locationFields, setLocationFields] = useState([]);
    const { formData, setFormData, handleInputChange, errors, handleSubmit } =
      useFornitoreCard(fornitoreData);

    const onFornitoreLoaded = fornitore => {
      setFormData(fornitore);
    };

    const onNuovoFornitore = async () => {
      onSalva();
    };

    useImperativeHandle(ref, () => ({
      onSalva,
    }));

    const onSalva = async () => {
      const validation = handleSubmit();
      if (validation === -1) {
        return;
      }

      setIsLoading(true);
      try {
        const result = await nuovoFornitore(formData);
        onFornitoreLoaded(result);
        onSaved(result);
      } catch (error) {
        dispatch(showSnackbar({ message: error, severity: 'error' }));
      } finally {
        setIsLoading(false);
      }
    };

    const onEdit = firmatario => {
      navigate('/firmatari/edit', {
        state: { firmatario: firmatario, idFornitore: fornitoreData.id },
      });
    };

    const onNuovoFirmatario = () => {
      navigate('/firmatari/new', {
        state: { idFornitore: fornitoreData.id },
      });
    };

    useEffect(() => {
      if (!isNewFornitore && fornitoreData) {
        if (fornitoreData.sede_latitudine && fornitoreData.sede_longitudine) {
          setCoord({
            lat: parseFloat(fornitoreData.sede_latitudine),
            lng: parseFloat(fornitoreData.sede_longitudine),
          });
        }
      }
    }, [isNewFornitore]);

    const center = {
      lat: coord.lat,
      lng: coord.lng,
    };

    useEffect(() => {
      if (locationFields.length > 0) {
        let tmpFormData = {
          ...formData,
        };
        const indirizzo = locationFields.filter(item => {
          if (item.types[0] == 'route') {
            return item.long_name;
          }
        });
        if (indirizzo[0]) {
          tmpFormData.sede_indirizzo = indirizzo[0].long_name;
        }
        const civico = locationFields.filter(item => {
          if (item.types[0] == 'street_number') {
            return item.long_name;
          }
        });
        if (civico[0]) {
          tmpFormData.sede_civico = civico[0].long_name;
        }
        const provincia = locationFields.filter(item => {
          if (item.types[0] == 'administrative_area_level_2') {
            return item.long_name;
          }
        });
        if (provincia[0]) {
          tmpFormData.sede_provincia = provincia[0].short_name;
        }
        const citta = locationFields.filter(item => {
          if (item.types[0] == 'administrative_area_level_3') {
            return item.long_name;
          }
        });
        if (citta[0]) {
          tmpFormData.sede_citta = citta[0].long_name;
        }
        const cap = locationFields.filter(item => {
          if (item.types[0] == 'postal_code') {
            return item.long_name;
          }
        });
        if (cap[0]) {
          tmpFormData.sede_cap = cap[0].long_name;
        }
        if (coord) {
          tmpFormData.sede_latitudine = coord.lat.toString();
          tmpFormData.sede_longitudine = coord.lng.toString();
        }

        setFormData(tmpFormData);
      }
    }, [coord]);

    return (
      <Box sx={{ backgroundColor: 'background.paper' }}>
        {isLoading && <LoadingMask />}

        {!embedded && (
          <Stack
            justifyContent="space-between"
            flexDirection="row"
            sx={{
              backgroundColor: 'background.primaryLight',
              p: 2,
              borderRadius: '4px',
            }}
          >
            <Stack flexDirection="row" alignItems="center" sx={{ flex: 1 }}>
              <IconButton
                sx={{ border: 'none' }}
                size="small"
                onClick={() => navigate(-1)}
              >
                <ArrowBackIos />
              </IconButton>
              <Box sx={{ ml: 1 }}>
                <Typography
                  component="h4"
                  variant="h6"
                  sx={{ fontSize: 14 }}
                  color="primary"
                >
                  Gestione Fornitore
                </Typography>
                <Stack flexDirection="row">
                  <Typography component="h3" variant="h6" sx={{ fontSize: 17 }}>
                    {isNewFornitore
                      ? 'Crea Nuovo Fornitore'
                      : 'Visualizza Fornitore'}
                  </Typography>
                </Stack>
              </Box>
              {!isNewFornitore ? (
                <Stack
                  flexDirection="row"
                  alignItems={'center'}
                  justifyContent={'center'}
                  sx={{ flex: 1 }}
                >
                  {formData.flg_verificato ? (
                    <>
                      <Check sx={{ color: 'green', mr: 3 }} />
                      <Typography
                        component="h4"
                        variant="h6"
                        sx={{ fontSize: 14 }}
                        color="primary"
                      >
                        Il Fornitore è stato correttamente verificato dal
                        Noleggiatore.
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Close sx={{ color: 'red', mr: 3 }} />
                      <Typography
                        component="h4"
                        variant="h6"
                        sx={{ fontSize: 14 }}
                        color="primary"
                      >
                        Il Fornitore non è ancora stato verificato dal
                        Noleggiatore.
                      </Typography>
                    </>
                  )}
                </Stack>
              ) : (
                <></>
              )}
            </Stack>

            {isNewFornitore ? (
              <Button
                size="small"
                variant="contained"
                startIcon={<Save />}
                onClick={onNuovoFornitore}
              >
                CREA FORNITORE
              </Button>
            ) : (
              <></>
            )}
          </Stack>
        )}

        <Card
          sx={{
            backgroundColor: 'background.default',
            border: embedded ? 'none' : 'auto',
          }}
        >
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle2" sx={{ fontFamily: 'Poppins-Bold' }}>
              Dati Fornitore
            </Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid size={{ xs: 12, sm: 4 }}>
              <FormControl fullWidth>
                <FormLabel required htmlFor="ragione_sociale">
                  Ragione Sociale
                </FormLabel>
                <TextField
                  placeholder="Ragione Sociale"
                  name="ragione_sociale"
                  variant="outlined"
                  value={formData?.ragione_sociale}
                  onChange={handleInputChange}
                  fullWidth
                  error={!!errors.ragione_sociale}
                  helperText={errors.ragione_sociale}
                  slotProps={{
                    input: {
                      disabled: !isNewFornitore ? true : false,
                    },
                  }}
                />
              </FormControl>
            </Grid>

            <Grid size={{ xs: 12, sm: 4 }}>
              <FormControl fullWidth>
                <FormLabel required htmlFor="cf">
                  Codice Fiscale
                </FormLabel>
                <TextField
                  placeholder="Codice Fiscale"
                  name="cf"
                  variant="outlined"
                  value={formData?.cf}
                  onChange={handleInputChange}
                  fullWidth
                  error={!!errors.cf}
                  helperText={errors.cf}
                  slotProps={{
                    input: {
                      disabled: !isNewFornitore ? true : false,
                    },
                  }}
                />
              </FormControl>
            </Grid>

            <Grid size={{ xs: 12, sm: 4 }}>
              <FormControl fullWidth>
                <FormLabel required htmlFor="piva">
                  Partita IVA
                </FormLabel>
                <TextField
                  placeholder="Partita IVA"
                  name="piva"
                  variant="outlined"
                  value={formData?.piva}
                  onChange={handleInputChange}
                  fullWidth
                  error={!!errors.piva}
                  helperText={errors.piva}
                  slotProps={{
                    input: {
                      disabled: !isNewFornitore ? true : false,
                    },
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Box sx={{ mt: 4 }}>
            <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
              Indirizzo:
            </Typography>
          </Box>

          <Box sx={{ display: 'block', width: '100%' }}>
            {isNewFornitore ? (
              <SearchMap
                readOnly={!isNewFornitore ? true : false}
                setCoord={setCoord}
                setLocationFields={setLocationFields}
              />
            ) : (
              <></>
            )}
          </Box>

          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid size={{ xs: 12, sm: 3 }}>
              <FormControl fullWidth>
                <FormLabel required htmlFor="sede_indirizzo">
                  Indirizzo Sede
                </FormLabel>
                <TextField
                  placeholder="Indirizzo Sede"
                  name="sede_indirizzo"
                  value={formData?.sede_indirizzo}
                  onChange={handleInputChange}
                  fullWidth
                  error={!!errors.sede_indirizzo}
                  helperText={errors.sede_indirizzo}
                  slotProps={{
                    input: {
                      disabled: !isNewFornitore ? true : false,
                    },
                  }}
                />
              </FormControl>
            </Grid>
            <Grid size={{ xs: 6, sm: 2 }}>
              <FormControl fullWidth>
                <FormLabel required htmlFor="sede_civico">
                  N. Civico
                </FormLabel>
                <TextField
                  placeholder="N. Civico"
                  name="sede_civico"
                  value={formData?.sede_civico}
                  onChange={handleInputChange}
                  fullWidth
                  error={!!errors.sede_civico}
                  helperText={errors.sede_civico}
                  slotProps={{
                    input: {
                      disabled: !isNewFornitore ? true : false,
                    },
                  }}
                />
              </FormControl>
            </Grid>
            <Grid size={{ xs: 6, sm: 2 }}>
              <FormControl fullWidth>
                <FormLabel required htmlFor="sede_cap">
                  CAP
                </FormLabel>
                <TextField
                  placeholder="CAP"
                  name="sede_cap"
                  value={formData?.sede_cap}
                  onChange={handleInputChange}
                  fullWidth
                  error={!!errors.sede_cap}
                  helperText={errors.sede_cap}
                  slotProps={{
                    input: {
                      disabled: !isNewFornitore ? true : false,
                    },
                  }}
                />
              </FormControl>
            </Grid>
            <Grid size={{ xs: 12, sm: 2 }}>
              <FormControl fullWidth>
                <FormLabel required htmlFor="sede_citta">
                  Città
                </FormLabel>
                <TextField
                  placeholder="Città"
                  name="sede_citta"
                  value={formData?.sede_citta}
                  onChange={handleInputChange}
                  fullWidth
                  error={!!errors.sede_citta}
                  helperText={errors.sede_citta}
                  slotProps={{
                    input: {
                      disabled: !isNewFornitore ? true : false,
                    },
                  }}
                />
              </FormControl>
            </Grid>

            <Grid size={{ xs: 12, sm: 3 }}>
              <FormControl fullWidth>
                <FormLabel required htmlFor="sede_provincia">
                  Provincia
                </FormLabel>
                <SelectProvince
                  name="sede_provincia"
                  placeholder="Provincia"
                  value={formData?.sede_provincia}
                  onChange={handleInputChange}
                  errors={errors.sede_provincia}
                  readOnly={!isNewFornitore ? true : false}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid sx={{ mb: 2, mt: 1 }} container spacing={2}>
            <GoogleMap
              mapContainerStyle={{
                width: '100%',
                height: '400px',
              }}
              center={center}
              zoom={14}
            >
              <MarkerF position={coord} />
            </GoogleMap>
          </Grid>

          {!isNewFornitore && (
            <>
              <Divider sx={{ mt: 4, mb: 4 }} />
              <Stack
                direction="row"
                spacing={2}
                sx={{ mt: 2, mb: 2, justifyContent: 'space-between' }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{ fontFamily: 'Poppins-Bold' }}
                >
                  Elenco Firmatari
                </Typography>
                <Button
                  onClick={onNuovoFirmatario}
                  startIcon={<Add />}
                  variant="contained"
                  size="small"
                >
                  Nuovo Firmatario
                </Button>
              </Stack>
              <FirmatariTable idFornitore={fornitoreData.id} onEdit={onEdit} />
            </>
          )}
        </Card>
      </Box>
    );
  }
);

export default FornitoreCard;
