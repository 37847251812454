import Grid from '@mui/material/Grid2';
import { DataGrid } from '@mui/x-data-grid';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getClienti } from '../../../redux/clienti/operations';
import { IconButton, Stack } from '@mui/material';
import { ArrowForwardIos } from '@mui/icons-material';

export default function ClientiTable({ onEdit }) {
  const dispatch = useDispatch();

  const { clienti, getClientiAction } = useSelector(state => state.clienti);
  useEffect(() => {
    dispatch(getClienti());
  }, []);

  const columns = [
    {
      field: 'ragione_sociale',
      headerName: 'Nome',
      flex: 1,
    },
    {
      field: 'piva',
      headerName: 'P.IVA',
      flex: 1,
    },
    { field: 'cf', headerName: 'Codice Fiscale', flex: 1 },
    {
      field: 'azioni',
      headerName: 'Azioni',
      sortable: false,
      headerClassName: 'no-separator',
      cellClassName: 'no-separator',
      disableColumnMenu: true,
      renderCell: params => (
        <Stack
          flexDirection="row"
          gap={1}
          sx={{
            justifyContent: 'start',
            height: '100%',
            alignItems: 'center',
          }}
        >
          <IconButton
            size="small"
            sx={{
              border: 'none',
              backgroundColor: 'transparent',
            }}
            onClick={() => onEdit(params.row)}
          >
            <ArrowForwardIos />
          </IconButton>
        </Stack>
      ),
    },
  ];

  return (
    <Grid container spacing={2} columns={12}>
      <Grid size={{ md: 12, lg: 12 }} sx={{ width: '100%' }}>
        <DataGrid
          onRowDoubleClick={params => onEdit(params.row)}
          autoHeight
          rows={clienti}
          columns={columns}
          loading={getClientiAction.loading}
          getRowClassName={params =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
          }
          initialState={{
            pagination: { paginationModel: { pageSize: 20 } },
          }}
          pageSizeOptions={[10, 20, 50]}
          disableColumnResize
          density="standard"
        />
      </Grid>
    </Grid>
  );
}
