import api from '../../config/axiosConfig';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { handleAsyncThunk } from '../asyncWrapper';

/*
 * GET @ preventivatore/produttori
 * response: [produttori]
 */
export const getProduttori = createAsyncThunk(
  'clienti/getProduttori',
  handleAsyncThunk(async () => {
    const res = await api.get('/preventivatore/produttori');
    return res.data.data;
  })
);

/*
 * GET @ preventivatore/produttoriCategoria
 * response: [produttori]
 */
export const getProduttoriCategoria = createAsyncThunk(
  'clienti/getProduttoriCategoria',
  handleAsyncThunk(async idCategoria => {
    const params = new URLSearchParams();
    params.append('id_categoria', idCategoria);
    const res = await api.get('/preventivatore/produttoriCategoria', {
      params,
    });
    return res.data.data;
  })
);
