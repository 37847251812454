import api from '../../config/axiosConfig';
import { handleAsyncApiRequest } from '../asyncWrapper';

/*
 * POST @ preventivatore/leggiNotifica
 * data: { id_notifica }
 */
export const leggiNotifica = handleAsyncApiRequest(async data => {
  await api.post('/preventivatore/leggiNotifica', data);
});

/*
 * POST @ preventivatore/leggiNotifiche
 */
export const leggiNotifiche = handleAsyncApiRequest(async () => {
  await api.post(`/preventivatore/leggiNotifiche`);
});
