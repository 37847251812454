import {
  Box,
  Card,
  Divider,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Description, Search } from '@mui/icons-material';
import { formatStringDate } from '../../../utils/formadDate';
import { useMemo, useState } from 'react';

export default function DocumentiFattureGrid({
  fatture,
  documenti,
  onDownloadDocumento,
  onDownloadFattura,
}) {
  const theme = useTheme();
  const matchSm = useMediaQuery(theme.breakpoints.up('sm'));
  const matchLg = useMediaQuery(theme.breakpoints.up('lg'));
  const matchXl = useMediaQuery(theme.breakpoints.up('xl'));
  const [search, setSearch] = useState('');

  const filter = value => {
    setSearch(value);
  };

  const filteredDocumenti = useMemo(() => {
    return documenti.filter(doc =>
      doc.nome.toLowerCase().includes(search.toLowerCase())
    );
  }, [documenti, search]);

  const filteredFatture = useMemo(() => {
    return fatture.filter(doc =>
      doc.titolo.toLowerCase().includes(search.toLowerCase())
    );
  }, [fatture, search]);

  const GridElement = ({ data, noDataLabel, labelKey, onDownload }) => {
    const borderCondition = index => {
      if (matchXl) {
        return index % 5 !== 4 && index < data.length - 1;
      } else if (matchLg) {
        return index % 3 !== 2 && index < data.length - 1;
      } else if (matchSm) {
        return index % 3 !== 2 && index < data.length - 1;
      } else {
        return false;
      }
    };

    return data.length > 0 ? (
      <Grid container columns={12} sx={{ mt: 2 }}>
        {data.map((doc, index) => (
          <Grid
            size={{ xs: 12, sm: 4, md: 4, lg: 4, xl: 2.4 }}
            key={index}
            sx={{
              p: 0,
              m: 0,
            }}
          >
            <Card
              sx={{
                backgroundColor: 'background.default',
                border: 'none',
                borderRadius: 0,
                borderRight: borderCondition(index)
                  ? '1px solid #e0e0e0'
                  : 'none',
                px: 2,
                py: 0,
                mb: 2,
                height: '80%',
                width: '100%',
              }}
            >
              <Stack
                direction="row"
                justifyContent={'start'}
                alignItems="top"
                start
                spacing={2}
                sx={{ cursor: 'pointer', flexGrow: 1 }}
                onClick={() => onDownload(doc[labelKey], doc.id)}
              >
                <Description color="primary" />
                <Box>
                  <Typography sx={{ fontFamily: 'Poppins-Bold' }}>
                    {doc[labelKey]}
                  </Typography>
                  <Typography variant="caption">
                    {formatStringDate(doc.data)}
                  </Typography>
                </Box>
              </Stack>
            </Card>
          </Grid>
        ))}
      </Grid>
    ) : (
      <Box sx={{ mt: 2 }}>
        <Typography variant="caption" color="textSecondary">
          {noDataLabel}
        </Typography>
      </Box>
    );
  };

  return (
    <Box>
      <TextField
        onChange={e => filter(e.target.value)}
        sx={{ mb: 1 }}
        placeholder="Cerca"
        size="small"
        variant="outlined"
        slotProps={{
          input: {
            startAdornment: (
              <InputAdornment position="start">
                <Search sx={{ width: 20, height: 20 }} />
              </InputAdornment>
            ),
          },
        }}
      />
      <Box sx={{ mt: 2 }}>
        <Typography variant="body2" fontWeight={'bold'}>
          Contratti Operazione:
        </Typography>

        <GridElement
          data={filteredDocumenti}
          noDataLabel={'Nessun documento presente'}
          labelKey={'nome'}
          onDownload={onDownloadDocumento}
        />
      </Box>
      <Divider sx={{ my: 2 }} />
      <Box>
        <Typography variant="body2" fontWeight={'bold'}>
          Fatture:
        </Typography>

        <GridElement
          data={filteredFatture}
          noDataLabel={'Nessuna fattura presente'}
          labelKey={'titolo'}
          onDownload={onDownloadFattura}
        />
      </Box>
    </Box>
  );
}
