import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  drawerNotificheOpen: false,
  drawerComunicazioniOpen: false,
  drawerSupportoOpen: false,
  snackbarConfig: null,
  alertDialogConfig: null,
  sideMenuOpen: false,
};

const closeAllDrawers = state => {
  state.drawerNotificheOpen = false;
  state.drawerComunicazioniOpen = false;
  state.drawerSupportoOpen = false;
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setSideMenuOpen: (state, action) => {
      state.sideMenuOpen = action.payload;
    },
    toggleSideMenu: state => {
      state.sideMenuOpen = !state.sideMenuOpen;
    },
    toggleDrawerNotifiche: state => {
      const newVal = !state.drawerNotificheOpen;
      closeAllDrawers(state);
      state.drawerNotificheOpen = newVal;
    },
    toggleDrawerComunicazioni: state => {
      const newVal = !state.drawerComunicazioniOpen;
      closeAllDrawers(state);
      state.drawerComunicazioniOpen = newVal;
    },
    toggleDrawerSupporto: state => {
      const newVal = !state.drawerSupportoOpen;
      closeAllDrawers(state);
      state.drawerSupportoOpen = newVal;
    },
    showSnackbar: (state, action) => {
      state.snackbarConfig = {
        open: true,
        message: '',
        severity: 'info', // Può essere: 'success', 'error', 'warning', 'info'
        duration: 3000,
        position: { vertical: 'top', horizontal: 'center' },
        ...action.payload,
      };
    },
    hideSnackbar: state => {
      state.snackbarConfig = null;
    },
    showAlertDialog: (state, action) => {
      state.alertDialogConfig = {
        open: true,
        title: '',
        message: '',
        btnAnnullaText: 'Annulla',
        btnConfermaText: 'Conferma',
        ...action.payload,
      };
    },
    hideAlertDialog: state => {
      state.alertDialogConfig = null;
    },
  },
});

export const {
  setSideMenuOpen,
  toggleSideMenu,
  toggleDrawerSupporto,
  toggleDrawerNotifiche,
  toggleDrawerComunicazioni,
  showSnackbar,
  hideSnackbar,
  showAlertDialog,
  hideAlertDialog,
} = appSlice.actions;

export const appReducer = appSlice.reducer;
