import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import AppNavbar from '../components/AppNavbar';
import Header from '../components/Header';
import SideMenu from '../components/SideMenu';
import { Outlet } from 'react-router-dom';
import { AppBar, useTheme } from '@mui/material';
import NotificationDrawer from './NotificationDrawer';
import { useEffect, useRef } from 'react';
import ComunicazioniDrawer from './ComunicazioniDrawer';
import useAuth from '../hooks/useAuth';
import pusher from '../config/pusherConfig';
import { getNotifiche } from '../redux/notifiche/operations';
import { useDispatch, useSelector } from 'react-redux';
import { getComunicazioni } from '../redux/comunicazioni/operations';
import SupportoDrawer from './SupportoDrawer';

export const Layout = () => {
  const body = document.querySelector('body');
  const headerRef = useRef(null);
  const { user } = useAuth();
  const dispatch = useDispatch();
  const theme = useTheme();
  body.style.backgroundColor = theme.palette.background.paper;
  const sideMenuRef = useRef(null);
  const mainContentRef = useRef(null);

  const {
    drawerNotificheOpen,
    drawerComunicazioniOpen,
    drawerSupportoOpen,
    sideMenuOpen,
  } = useSelector(state => state.app);
  const maskContent =
    drawerNotificheOpen || drawerComunicazioniOpen || drawerSupportoOpen;

  useEffect(() => {
    // socket connection
    const checkSocketPusher = async () => {
      try {
        let c = pusher.subscribe('RENTAL_' + user.id_utenza);
        c.bind('nuovaNotifica', async () => {
          console.log('get notifiche pusher');
          dispatch(getNotifiche());
        });
        let com = pusher.subscribe('RENTAL_comunicazioni');
        com.bind('nuovaComunicazione', async data => {
          console.log('get comunicazione pusher');
          if (!data.id_fornitore || data.id_fornitore === user.id_fornitore) {
            dispatch(getComunicazioni());
          }
        });
        // id_fornitore
      } catch (error) {
        console.log(error);
      }
    };
    checkSocketPusher();
  }, [location]);

  const calcSideMenuWidth = () => {
    if (sideMenuRef.current && mainContentRef.current) {
      mainContentRef.current.style.width = `calc(100vw - ${sideMenuRef.current.offsetWidth}px - 16px)`;
    }
  };

  const calcSizes = () => {
    calcSideMenuWidth();
  };

  useEffect(() => {
    calcSizes();
    window.addEventListener('resize', calcSizes);
    return () => {
      window.removeEventListener('resize', calcSizes);
    };
  }, [headerRef.current, sideMenuRef.current, mainContentRef.current]);

  useEffect(() => {
    setTimeout(() => {
      calcSizes();
    }, 50);
  }, [sideMenuOpen]);

  return (
    <Box
      sx={{
        display: 'flex',
        backgroundColor: 'background.paper',
        width: '100vw',
      }}
    >
      <Box ref={sideMenuRef}>
        <SideMenu />
      </Box>

      <AppNavbar />
      {/* Main content */}
      <Box component="main" sx={{ flexGrow: 1 }}>
        <Stack spacing={0} sx={{ backgroundColor: 'background.paper' }}>
          <AppBar
            ref={headerRef}
            position="sticky"
            sx={{
              display: { xs: 'none', md: 'block' },
              bgcolor: 'background.default',
              boxShadow: 1,
              height: 50,
              // pt: 1,
            }}
          >
            <Header />
          </AppBar>
          <Box
            ref={mainContentRef}
            sx={{
              borderRadius: '4px',
              bgcolor: 'background.default',
              m: 1,
              mt: { xs: '70px', md: 1 },
            }}
          >
            <Box
              sx={{
                opacity: maskContent ? 0.5 : 1,
              }}
            >
              <Outlet />
            </Box>

            <NotificationDrawer headerRef={headerRef} />
            <ComunicazioniDrawer headerRef={headerRef} />
            <SupportoDrawer headerRef={headerRef} sideMenuRef={sideMenuRef} />
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};
