import Grid from '@mui/material/Grid2';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import columnsData from '../data/noleggi_columns';
import { InputAdornment, TextField } from '@mui/material';
import { Search } from '@mui/icons-material';

export default function NoleggiTable({
  noleggi,
  isLoading,
  onSearchValueChange,
  showSearch = true,
  compactMode = false,
  ...props
}) {
  const navigate = useNavigate();

  const onEdit = id => {
    navigate('/noleggi/noleggio/edit', { state: { id: id } });
  };

  const columns = columnsData({
    onEdit: id => onEdit(id),
    compactMode,
  });

  return (
    <Grid container spacing={2} columns={12}>
      <Grid size={{ md: 12, lg: 12, sx: 12 }} sx={{ width: '100%' }}>
        {showSearch && (
          <TextField
            onChange={onSearchValueChange}
            sx={{ mb: 1 }}
            placeholder="Cerca"
            size="small"
            variant="outlined"
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <Search sx={{ width: 20, height: 20 }} />
                  </InputAdornment>
                ),
              },
            }}
          />
        )}
        <DataGrid
          onRowDoubleClick={params => onEdit(params.row.id)}
          autoHeight
          rows={noleggi}
          columns={columns}
          loading={isLoading}
          getRowClassName={params =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
          }
          initialState={{
            pagination: { paginationModel: { pageSize: 20 } },
          }}
          pageSizeOptions={[5, 10, 20, 50]}
          density="standard"
          {...props}
        />
      </Grid>
    </Grid>
  );
}
