import { useEffect, useState } from 'react';
import {
  Autocomplete,
  FormHelperText,
  MenuItem,
  TextField,
} from '@mui/material';
import { getListaFornitori } from '../../../redux/noleggi/api';

const SelectFornitore = ({
  key,
  name,
  placeholder,
  value,
  onChange,
  errors,
}) => {
  const [listaFornitori, setListaFornitori] = useState([]);

  const loadFornitori = async () => {
    let response = await getListaFornitori();
    setListaFornitori(response);
  };

  useEffect(() => {
    loadFornitori();
  }, [key]);

  const _onChange = e => {
    onChange({
      target: {
        name: name,
        value: e.target.value,
        ragione_sociale:
          e.target.value == null
            ? null
            : listaFornitori.find(fornitore => fornitore.id == e.target.value)
                .ragione_sociale,
      },
    });
  };

  return (
    <>
      <Autocomplete
        autoHighlight
        fullWidth
        size="small"
        name={name}
        options={listaFornitori}
        sx={{
          '& .MuiButtonBase-root': {
            padding: '10px',
            height: '10px',
            width: '10px',
            marginRight: '5px',
          },
        }}
        getOptionLabel={option => option.ragione_sociale}
        value={listaFornitori.filter(v => v.id === value)[0] || null}
        onChange={(e, newVal) =>
          _onChange({ target: { name: name, value: newVal?.id } })
        }
        renderInput={params => (
          <TextField placeholder={placeholder} {...params} />
        )}
        getOptionKey={option => option.id}
        renderOption={option => (
          <MenuItem
            selected={option.key === value}
            onClick={() => {
              if (option.key === value) return;
              _onChange({ target: { name: name, value: option.key } });
            }}
          >
            {listaFornitori.filter(v => v.id === option.key)[0].ragione_sociale}
          </MenuItem>
        )}
      />

      {errors && (
        <FormHelperText sx={{ color: 'error.main' }}>{errors}</FormHelperText>
      )}
    </>
  );
};

export default SelectFornitore;
