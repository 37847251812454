import { Box, Button, Stack, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';
import AccountTable from './components/AccountTable';
import { useNavigate } from 'react-router-dom';
import FirmatariTable from '../Fornitori/components/FirmatariTable';
import useAuth from '../../hooks/useAuth';

export default function Impostazioni() {
  const navigate = useNavigate();
  const { user } = useAuth();

  const onNuovoAccount = () => {
    navigate('/impostazioni/gestione-account/new');
  };

  const onEdit = account => {
    navigate('/impostazioni/gestione-account/edit', {
      state: { account: account },
    });
  };

  const onFirmatarioEdit = firmatario => {
    navigate('/firmatari/edit', {
      state: { firmatario: firmatario, idFornitore: user.id_fornitore },
    });
  };

  const onNuovoFirmatario = () => {
    navigate('/firmatari/new', { state: { idFornitore: user.id_fornitore } });
  };

  return (
    <Box sx={{ p: 3 }}>
      <Stack
        direction="row"
        spacing={2}
        sx={{ mb: 2, justifyContent: 'space-between' }}
      >
        <Box>
          <Typography component="h2" variant="h4">
            Impostazioni
          </Typography>
          <Typography variant="body1">
            Censisci gli account alla piattaforma ed i firmatari delle pratiche.
          </Typography>
        </Box>
      </Stack>
      <Stack
        direction="row"
        spacing={2}
        sx={{ mt: 6, mb: 2, justifyContent: 'space-between' }}
      >
        <Typography variant="h6">Gestione Account</Typography>

        <Button
          onClick={onNuovoAccount}
          startIcon={<Add />}
          size="small"
          variant="contained"
        >
          Nuovo account
        </Button>
      </Stack>

      <AccountTable onEdit={onEdit} />

      <Stack
        direction="row"
        spacing={2}
        sx={{ mt: 6, mb: 2, justifyContent: 'space-between' }}
      >
        <Typography variant="h6">Elenco Firmatari</Typography>
        <Button
          onClick={onNuovoFirmatario}
          startIcon={<Add />}
          size="small"
          variant="contained"
        >
          Nuovo Firmatario
        </Button>
      </Stack>
      <FirmatariTable
        idFornitore={user.id_fornitore}
        onEdit={onFirmatarioEdit}
      />
    </Box>
  );
}
