import {
  Box,
  Card,
  CardContent,
  Slider,
  Stack,
  Switch,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

const sliderMesi = [
  {
    label: 24,
    value: 24,
  },
  {
    label: 36,
    value: 36,
  },
  {
    label: 48,
    value: 48,
  },
  {
    label: 60,
    value: 60,
  },
  {
    label: 72,
    value: 72,
  },
];

function CalcolatoreSemplice({
  noleggio,
  setNoleggio,
  compactViewMode = false,
}) {
  const theme = useTheme();
  const matchLg = useMediaQuery(theme.breakpoints.up('lg'));

  const handleInputChange = e => {
    setNoleggio(prev => ({
      ...prev,
      [e.target.name]:
        e.target.type === 'checkbox' ? e.target.checked : e.target.value,
    }));
  };

  const TitleWithHelper = ({ title1, title2, subtitle }) => {
    return (
      <Box>
        <Stack direction="row" spacing={0.5}>
          <Typography variant="body1" sx={{ fontFamily: 'Poppins-Bold' }}>
            {title1}{' '}
            <span style={{ color: theme.palette.primary.main }}>{title2}</span>{' '}
          </Typography>
        </Stack>
        <Typography variant="caption" sx={{ lineHeight: '0px' }}>
          {subtitle}
        </Typography>
      </Box>
    );
  };

  const Item = ({ title, component, direction = 'row' }) => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: direction,
          justifyContent: direction === 'row' ? 'space-between' : 'start',
          alignItems: direction === 'row' ? 'center' : 'start',
        }}
      >
        <Box sx={{ flex: 1 }}>{title}</Box>
        <Box sx={{ mt: 2, flex: 2 }}>
          <Stack
            justifyContent={'left'}
            alignItems={'left'}
            alignContent={'left'}
          >
            {component}
          </Stack>
        </Box>
        {/* <Box sx={{ flex: 1 }}></Box> */}
      </Box>
    );
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <Card
        sx={{
          p: 0,
          border: 'none',
          backgroundColor: 'background.primaryLight',
        }}
      >
        <CardContent sx={{ p: 0 }}>
          <Stack flexDirection="row" justifyContent="space-between"></Stack>

          <Stack
            gap={5}
            sx={{ mt: compactViewMode ? 0 : 4 }}
            flexDirection="row"
          >
            <Box sx={{ flex: 2 }}>
              <Item
                title={
                  <TitleWithHelper
                    title1="Inserisci"
                    title2="Durata (mesi)"
                    subtitle="Inserisci la durata richiesta dal noleggio"
                  />
                }
                direction={matchLg ? 'row' : 'row'}
                component={
                  <Slider
                    sx={{ width: '300px', ml: 2 }}
                    name="durata_mesi"
                    aria-label="Custom marks"
                    defaultValue={24}
                    min={24}
                    max={72}
                    step={12}
                    valueLabelDisplay="auto"
                    marks={sliderMesi}
                    value={noleggio?.durata_mesi}
                    getAriaValueText={value => `${value} mesi`}
                    onChange={(e, value) => {
                      handleInputChange({
                        target: {
                          name: 'durata_mesi',
                          value: parseInt(value),
                        },
                      });
                    }}
                  />
                }
              />
              <Box sx={{ mt: 2 }}></Box>
              <Item
                title={
                  <TitleWithHelper
                    title1="Vuoi aggiungere il"
                    title2="Servizio Assicurazione?"
                    subtitle="Doinn offre il servizio assicurazione a tutti i beni noleggiati."
                  />
                }
                component={
                  <Switch
                    checked={noleggio?.flg_assicurazione == 1 ? true : false}
                    name="flg_assicurazione"
                    onChange={(e, checked) => {
                      handleInputChange({
                        target: {
                          name: 'flg_assicurazione',
                          type: 'checkbox',
                          checked,
                        },
                      });
                    }}
                  />
                }
              />
            </Box>
          </Stack>
        </CardContent>
      </Card>
    </Box>
  );
}

export default CalcolatoreSemplice;
