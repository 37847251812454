import { z } from 'zod';
import {
  TextField,
  Button,
  Grid2 as Grid,
  FormControl,
  Box,
  FormLabel,
  Stack,
  FormHelperText,
  IconButton,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import SelectCategoriaProdotto from './SelectCategoriaProdotto';
import useZod from '../../../hooks/useZod';
import DecimalInputDecorated from '../../../components/DecimalInputDecorated';
import { Add, Euro } from '@mui/icons-material';
import SelectProduttore from './SelectProduttore';
import CreaProduttoreDialog from '../../Produttori/components/CreaProduttoreDialog';
import { useId, useState } from 'react';

const formSchema = z.object({
  descrizione: z.string().min(1, { message: 'La descrizione è obbligatoria' }),
  codice_prodotto: z.string().min(1, { message: 'Il codice è obbligatorio' }),
  id_produttore: z.string().min(1, { message: 'Il produttore è obbligatorio' }),
  id_categoria_prodotto: z
    .string()
    .min(1, { message: 'La categoria prodotto è obbligatoria' }),
  quantita: z.number().min(1, { message: 'La quantità è obbligatoria' }),
  importo: z.number().min(1, { message: "L'importo è obbligatorio" }),
});

const ConfiguraBene = ({ isEditing, bene, onCancel, onSave }) => {
  const [dialogCreaProduttureOpen, setDialogCreaProduttureOpen] =
    useState(false);
  const [produttoreKey, setProduttoreKey] = useState(0);

  const { errors, validate, setFormData, handleInputChange, formData } = useZod(
    {
      schema: formSchema,
      initialFormData:
        bene != null
          ? { ...bene, importo: parseFloat(bene.importo) }
          : {
              descrizione: '',
              codice_prodotto: '',
              produttore: '',
              id_categoria_prodotto: '',
              quantita: 0,
              importo: 0,
            },
    }
  );

  const handleSubmit = () => {
    const validation = validate();
    if (validation.success) {
      //todo: save data
      onSave(formData);
    }
  };

  const handleCategoriaChange = e => {
    setFormData(prevState => ({
      ...prevState,
      id_categoria_prodotto: e.target.value,
      id_produttore: '',
      categoria_prodotto_descrizione: e.target.descrizione,
      ragione_sociale_produttore: '',
    }));
    setProduttoreKey(prev => prev + 1);
  };

  const handleProduttoreChange = e => {
    setFormData(prevState => ({
      ...prevState,
      id_produttore: e.target.value,
      ragione_sociale_produttore: e.target.ragione_sociale,
    }));
  };

  const onAddProduttore = () => {
    setDialogCreaProduttureOpen(true);
  };

  const onProduttoreSaved = produttore => {
    setDialogCreaProduttureOpen(false);
    handleProduttoreChange({
      target: {
        value: produttore.id,
        ragione_sociale: produttore.ragione_sociale,
      },
    });
    setProduttoreKey(prev => prev + 1);
  };

  const theme = useTheme();
  const matchMd = useMediaQuery(theme.breakpoints.up('md'));
  const matchLg = useMediaQuery(theme.breakpoints.up('lg'));
  const matchXl = useMediaQuery(theme.breakpoints.up('xl'));
  const formClass = matchMd ? 'horizontal' : 'vertical';

  const importoInputId = useId();
  const qtaInputId = useId();

  return (
    <Box>
      <Grid container columnSpacing={4} rowSpacing={1}>
        <Grid size={{ xs: 12, sm: 6, md: 6, lg: 6, xl: 4 }}>
          <FormControl className={formClass} fullWidth>
            <FormLabel
              required
              htmlFor="categoriaProdotto"
              sx={{ minWidth: '140px' }}
            >
              Categoria Prodotto
            </FormLabel>
            <Box sx={{ width: '100%' }}>
              <SelectCategoriaProdotto
                placeholder="Categoria Prodotto"
                name="id_categoria_prodotto"
                value={formData.id_categoria_prodotto}
                onChange={handleCategoriaChange}
                errors={errors.id_categoria_prodotto}
              />
            </Box>
          </FormControl>
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 6, lg: 6, xl: 4 }}>
          <FormControl className={formClass} fullWidth>
            <FormLabel required htmlFor="produttore" sx={{ minWidth: '85px' }}>
              Produttore
            </FormLabel>
            <Stack
              flexDirection={'row'}
              sx={{ width: '100%', flex: 1, alignItems: 'center' }}
            >
              <SelectProduttore
                key={produttoreKey}
                placeholder="Produttore"
                name="produttore"
                value={formData.id_produttore}
                onChange={handleProduttoreChange}
                errors={errors.id_produttore}
                categoriaProdotto={formData.id_categoria_prodotto}
              />
              <IconButton
                sx={{ ml: 1 }}
                size="small"
                onClick={onAddProduttore}
                color="secondary"
              >
                <Add />
              </IconButton>
            </Stack>
          </FormControl>
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 6, lg: 6, xl: 4 }}>
          <FormControl className={formClass} fullWidth>
            <FormLabel
              required
              htmlFor="codice_prodotto"
              sx={{ minWidth: matchXl ? 'auto' : '140px' }}
            >
              Codice Prodotto
            </FormLabel>
            <TextField
              placeholder="Codice Prodotto"
              name="codice_prodotto"
              variant="outlined"
              value={formData.codice_prodotto}
              onChange={handleInputChange}
              fullWidth
              error={!!errors.codice_prodotto}
              helperText={errors.codice_prodotto}
            />
          </FormControl>
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 6, lg: 6, xl: 4 }}>
          <Stack>
            <FormControl className={formClass} fullWidth>
              <FormLabel
                required
                htmlFor="quantita"
                sx={{ minWidth: matchXl ? '140px' : '85px' }}
              >
                Quantità
              </FormLabel>
              <Box sx={{ width: '100%' }}>
                <DecimalInputDecorated
                  id={qtaInputId}
                  step={1}
                  round={1}
                  format={'decimal'}
                  value={formData.quantita}
                  width="100%"
                  onChange={value => {
                    handleInputChange({
                      target: {
                        name: 'quantita',
                        value: value,
                        type: 'number',
                      },
                    });
                  }}
                  name="quantita"
                  style={{ justifyContent: 'start' }}
                />
              </Box>
            </FormControl>
            {errors.quantita && (
              <FormHelperText sx={{ color: 'error.main' }}>
                {errors.quantita}
              </FormHelperText>
            )}

            {matchXl ? (
              <>
                <FormControl className={formClass} fullWidth sx={{ mt: 1 }}>
                  <FormLabel
                    required
                    htmlFor="importo"
                    sx={{ minWidth: '140px' }}
                  >
                    Importo Cad.
                  </FormLabel>
                  <Box sx={{ width: '100%' }}>
                    <DecimalInputDecorated
                      id={importoInputId}
                      step={1}
                      round={0.01}
                      format={'EUR'}
                      decorationButtons={true}
                      LeftButtonIcon={null}
                      RightButtonIcon={Euro}
                      value={formData.importo}
                      width="100%"
                      onChange={value => {
                        handleInputChange({
                          target: {
                            name: 'importo',
                            value: value,
                            type: 'number',
                          },
                        });
                      }}
                      name="importo"
                      style={{ justifyContent: 'start' }}
                    />
                  </Box>
                </FormControl>
                {errors.importo && (
                  <FormHelperText sx={{ color: 'error.main' }}>
                    {errors.importo}
                  </FormHelperText>
                )}
              </>
            ) : (
              <></>
            )}
          </Stack>
        </Grid>
        {!matchXl ? (
          <>
            <Grid size={{ xs: 12, sm: 6, md: 6, lg: 6, xl: 4 }}>
              <Stack>
                <FormControl className={formClass} fullWidth>
                  <FormLabel
                    required
                    htmlFor="importo"
                    sx={{ minWidth: '140px' }}
                  >
                    Importo Cad.
                  </FormLabel>
                  <Box sx={{ width: '100%' }}>
                    <DecimalInputDecorated
                      id={importoInputId}
                      step={1}
                      round={0.01}
                      format={'EUR'}
                      decorationButtons={true}
                      LeftButtonIcon={null}
                      RightButtonIcon={Euro}
                      value={formData.importo}
                      width="100%"
                      onChange={value => {
                        handleInputChange({
                          target: {
                            name: 'importo',
                            value: value,
                            type: 'number',
                          },
                        });
                      }}
                      name="importo"
                      style={{ justifyContent: 'start' }}
                    />
                  </Box>
                </FormControl>
                {errors.importo && (
                  <FormHelperText sx={{ color: 'error.main' }}>
                    {errors.importo}
                  </FormHelperText>
                )}
              </Stack>
            </Grid>
          </>
        ) : (
          <></>
        )}
        <Grid size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 8 }}>
          <FormControl
            className={formClass}
            fullWidth
            sx={{
              alignItems: 'start!important',
              alignContent: 'start',
              display: 'flex',
            }}
          >
            <FormLabel
              required
              htmlFor="descrizione"
              sx={{ minWidth: matchLg ? '85px' : '140px' }}
            >
              Descrizione
            </FormLabel>
            <TextField
              multiline
              rows={4}
              placeholder="Descrizione"
              name="descrizione"
              variant="outlined"
              value={formData.descrizione}
              onChange={handleInputChange}
              fullWidth
              error={!!errors.descrizione}
              helperText={errors.descrizione}
            />
          </FormControl>
        </Grid>
      </Grid>

      <Stack flexDirection="row" justifyContent="end" gap={2} sx={{ mt: 2 }}>
        <Button
          variant="contained"
          color="info"
          onClick={onCancel}
          size="small"
        >
          Annulla
        </Button>
        <Button variant="contained" onClick={handleSubmit} size="small">
          {isEditing ? 'Modifica' : 'Aggiungi'} Bene
        </Button>
      </Stack>

      <CreaProduttoreDialog
        open={dialogCreaProduttureOpen}
        onClose={() => setDialogCreaProduttureOpen(false)}
        onSaved={onProduttoreSaved}
      />
    </Box>
  );
};

export default ConfiguraBene;
