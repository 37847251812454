import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete';
import useOnclickOutside from 'react-cool-onclickoutside';
import { TextField } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
const { innerWidth } = window;

const SearchMap = ({
  setCoord,
  setLocationFields,
  placeholder = 'Cerca indirizzo...',
  readOnly = false,
}) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
  });
  const textRef = useRef();
  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });

  const handleInput = e => {
    // Update the keyword of the input element
    setValue(e.target.value);
  };

  const handleSelect = suggestion => () => {
    const description = suggestion.description;
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter to "false"
    setValue(description, false);
    clearSuggestions();

    // Get latitude and longitude via utility functions
    getGeocode({ address: description }).then(results => {
      setLocationFields(results[0].address_components);
      const { lat, lng } = getLatLng(results[0]);
      setCoord({ lat, lng });
    });
  };

  const renderSuggestions = () =>
    data.map(suggestion => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li
          key={place_id}
          style={{ cursor: 'pointer', listStyle: 'none', padding: 4 }}
          onClick={handleSelect(suggestion)}
        >
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });

  const [textWidth, setTextWidth] = useState(0);
  useEffect(() => {
    if (textRef.current) {
      setTextWidth(textRef.current.offsetWidth);
    }
  }, [textRef.current]);

  return (
    <div ref={ref} style={{ flex: 1 }}>
      <TextField
        ref={textRef}
        className="input-focus-form"
        // style={{width:400}}
        fullWidth
        style={{
          borderWidth: 0,
          borderBottomWidth: 1,
          borderColor: '#0061DF',
          fontSize: innerWidth < 500 ? 15 : 20,
          fontFamily: 'Poppins-Regular',
          width: '100%',
          backgroundColor: 'transparent',
        }}
        value={value}
        onChange={handleInput}
        disabled={!ready}
        placeholder={placeholder}
        slotProps={{
          input: {
            disabled: readOnly,
          },
        }}
      />
      {/* We can use the "status" to decide whether we should display the dropdown or not */}
      {status === 'OK' && (
        <ul
          style={{
            width: textWidth,
            marginTop: 0,
            marginLeft: 0,
            paddingLeft: 0,
            border: '1px solid #0061DF',
            borderRadius: 5,
            position: 'absolute',
            backgroundColor: 'white',
            zIndex: 999,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
          }}
        >
          {renderSuggestions()}
        </ul>
      )}
    </div>
  );
};

export default SearchMap;
