export const CONFIG = {
  // eslint-disable-next-line no-undef
  API_URL: process.env.REACT_APP_API_URL,
  // eslint-disable-next-line no-undef
  PUSHER_KEY: process.env.REACT_APP_PUSHER_KEY,
  // eslint-disable-next-line no-undef
  PUSHER_CLUSTER: process.env.REACT_APP_PUSHER_CLUSTER,
  // eslint-disable-next-line no-undef
  PUSHER_WS_PORT: process.env.REACT_APP_PUSHER_WS_PORT,
  // eslint-disable-next-line no-undef
  PUSHER_WSS_PORT: process.env.REACT_APP_PUSHER_WSS_PORT,
  // eslint-disable-next-line no-undef
  PUSHER_HOST: process.env.REACT_APP_PUSHER_HOST,
  // eslint-disable-next-line no-undef
  GOOGLE_MAPS_API_KEY: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
};
