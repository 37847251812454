import { Stack, Typography, Grid2 as Grid } from '@mui/material';
import ChatImg from '../../../assets/img/Chat.png';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/it';
import { ExpandMore } from '@mui/icons-material';

dayjs.locale('it');

export default function CardComunicazione({ item, setItemModalComunicazione }) {
  const [formatDataItem, setFormatDataItem] = useState(false);

  const formatData = () => {
    let data = dayjs(item.created_at);
    let dataFormattata = data.format('dddd, DD/MM/YY');

    return {
      ...item,
      data: dataFormattata.charAt(0).toUpperCase() + dataFormattata.slice(1),
    };
  };

  useEffect(() => {
    setFormatDataItem(formatData());
  }, [item]);

  return (
    <Grid
      sx={{ position: 'relative', backgroundColor: 'white', height: 350, p: 3 }}
      size={{ xs: 12, sm: 4 }}
    >
      {formatDataItem && (
        <Stack flexDirection="row">
          <Stack>
            <img src={ChatImg} alt="logo" width={32} />
          </Stack>
          <Stack sx={{ pl: 3, pr: 3 }}>
            <Typography>{formatDataItem.data}</Typography>
            <Typography sx={{ mt: 1, mb: 2 }} component="h2" variant="h5">
              {formatDataItem.titolo}
            </Typography>
            <Typography>
              <span
                style={{ maxHeight: 200, overflow: 'hidden', display: 'block' }}
                dangerouslySetInnerHTML={{
                  __html: formatDataItem.testo,
                }}
              ></span>
            </Typography>
          </Stack>
          <Stack
            sx={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              backgroundColor: 'white',
            }}
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              variant="body1"
              sx={{ lineHeight: 0, color: '#387FDF', pt: 5 }}
            >
              Leggi Tutto
            </Typography>
            <ExpandMore
              sx={{
                width: 32,
                height: 32,
                border: 'none',
                color: '#387FDF',
                cursor: 'pointer',
              }}
              onClick={() => {
                setItemModalComunicazione(item);
              }}
            />
          </Stack>
        </Stack>
      )}
    </Grid>
  );
}
