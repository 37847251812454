import { Card, Stack, Typography } from '@mui/material';
import IntestazioneGrafici from './IntestazioneGrafici';
import { formatNumber } from '../../../utils/formatNumber';

export default function TotaleComponent({
  titolo,
  sottotitolo,
  value,
  format,
  symbol,
}) {
  return (
    <Stack sx={{ flex: 1 }} flexDirection="row">
      <IntestazioneGrafici titolo={titolo} sottotitolo={sottotitolo} />
      <Stack
        sx={{ flex: 1 }}
        alignItems={'flex-start'}
        justifyContent={'center'}
      >
        <Typography
          component="h3"
          variant="h6"
          sx={{ maxHeight: 50, color: '#284BAA' }}
        >
          <Card
            sx={{
              maxHeight: 50,
              backgroundColor: '#F0F6FD',
              paddingLeft: 5,
              paddingRight: 5,
              pt: 1,
              pb: 1,
            }}
          >
            {format ? formatNumber(value, symbol) : value}
          </Card>
        </Typography>
      </Stack>
    </Stack>
  );
}
