import PropTypes from 'prop-types';
import Badge, { badgeClasses } from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';

function MenuButton({ badgeContent = '', showBadge = false, ...props }) {
  return (
    <Badge
      badgeContent={badgeContent}
      invisible={!showBadge}
      sx={{
        [`& .${badgeClasses.badge}`]: {
          right: 10,
          top: 10,
          backgroundColor: 'primary.main',
        },
      }}
    >
      <IconButton size="small" {...props} />
    </Badge>
  );
}

MenuButton.propTypes = {
  showBadge: PropTypes.bool,
};

export default MenuButton;
