import {
  Box,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { DataGrid } from '@mui/x-data-grid';
import { CopyAll, Delete, Edit } from '@mui/icons-material';
import { formatCurrency } from '../../../utils/formatNumber';

export default function BeniTable({
  rows,
  onClone,
  onEdit,
  onDelete,
  viewMode = 'full',
  readOnly = false,
  showHeader = true,
  autoHeight = true,
}) {
  const theme = useTheme();
  const matchMd = useMediaQuery(theme.breakpoints.up('md'));
  const matchLg = useMediaQuery(theme.breakpoints.up('lg'));

  var columns = [
    { field: 'ragione_sociale_produttore', headerName: 'Produttore', flex: 1 },
    {
      field: 'categoria_prodotto_descrizione',
      headerName: 'Categoria',
      flex: 1.5,
    },
    {
      field: 'codice_prodotto',
      headerName: 'Codice',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'descrizione',
      headerName: 'Descrizione',
      flex: 2,
    },
    {
      field: 'quantita',
      headerName: 'N. Beni',
      flex: 0.5,
      minWidth: 70,
      // flex: readOnly ? 0.5 : 1,
      renderCell: params => {
        return (
          <Stack sx={{ justifyContent: 'center', height: '100%' }}>
            {readOnly || !matchMd ? (
              <Typography variant="body2">{params.value}</Typography>
            ) : (
              <Box sx={{ maxWidth: '170px', pb: 0.3 }}>
                {/* <DecimalInputDecorated
                  format={'decimal'}
                  step={1}
                  round={1}
                  value={params.value}
                  max={params.row.max ?? 99999}
                  min={params.row.min ?? 0}
                  onChange={value => onQtaChange(params.row.id, value)}
                  style={{ justifyContent: 'start' }}
                  readOnly={viewMode === 'richiestaModifica'}
                /> */}
                {<Typography variant="body2">{params.value}</Typography>}
              </Box>
            )}
          </Stack>
        );
      },
    },
    {
      field: 'importo',
      headerName: 'Prezzo cad.',
      headerClassName: 'align-right',
      flex: 0.75,
      minWidth: 110,
      renderCell: params => {
        return (
          <Stack sx={{ textAlign: 'end', height: '100%' }}>
            {formatCurrency(params.row.importo)}
          </Stack>
        );
      },
    },
    {
      headerName: 'Totale',
      headerClassName: 'align-right',
      flex: 0.75,
      minWidth: 110,
      renderCell: params => {
        return (
          <Stack sx={{ textAlign: 'end', height: '100%' }}>
            {formatCurrency(params.row.importo * params.row.quantita)}
          </Stack>
        );
      },
    },
  ];

  // if (viewMode != 'full' || !matchLg) {
  //   // rimuovo descrizione
  //   columns = columns.filter(col => col.field !== 'descrizione');
  // }

  if (!readOnly && viewMode === 'full') {
    columns.push({
      field: 'id',
      headerName: 'Azioni',
      flex: 0.5,
      minWidth: 140,
      sortable: false,
      headerClassName: 'no-separator',
      cellClassName: 'no-separator',
      disableColumnMenu: true,
      renderCell: params => (
        <Stack
          flexDirection="row"
          gap={1}
          sx={{
            justifyContent: 'center',
            height: '100%',
            alignItems: 'center',
          }}
        >
          <IconButton
            size="small"
            color="primary"
            onClick={() => onEdit(params.row)}
          >
            <Edit />
          </IconButton>
          <IconButton
            size="small"
            color="primary"
            onClick={() => onClone(params.row)}
          >
            <CopyAll />
          </IconButton>
          <IconButton
            size="small"
            color="primary"
            onClick={() => onDelete(params.row.id)}
          >
            <Delete />
          </IconButton>
        </Stack>
      ),
    });
  } else {
    columns[columns.length - 1].headerClassName += ' no-separator';
  }

  return (
    <Grid container spacing={2} columns={12}>
      <Grid size={{ xs: 12, md: 12, lg: 12 }} sx={{ width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          getRowClassName={params =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
          }
          initialState={{
            pagination: { paginationModel: { pageSize: 100 } },
          }}
          autoHeight={autoHeight}
          disableColumnMenu
          disableColumnSorting
          disableColumnResize
          density="standard"
          sx={{
            '.MuiDataGrid-footerContainer': { display: 'none' },
            '.MuiDataGrid-columnHeaders': {
              display: showHeader ? 'flex' : 'none',
            },
          }}
        />
      </Grid>
    </Grid>
  );
}
