import {
  TextField,
  Button,
  Grid2 as Grid,
  FormControlLabel,
  FormControl,
  Typography,
  Box,
  FormLabel,
  Divider,
  Stack,
  useTheme,
  useMediaQuery,
  Autocomplete,
  InputAdornment,
} from '@mui/material';
import { CopyAll, Search } from '@mui/icons-material';
import SelectProvince from './SelectProvince';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { getClienti } from '../../../redux/clienti/operations';
import SearchMap from '../../../components/SearchMap';
import useAuth from '../../../hooks/useAuth';
import CustomCheckbox from './CustomCheckbox';

const InserisciDatiCliente = ({
  hook,
  noleggio,
  setNoleggio,
  viewMode = 'full',
  showSearch = true,
  showReferente = true,
  onCompletedChanged = null,
}) => {
  const { user } = useAuth();
  const { formData, errors, handleInputChange, handleCopyAddress, isValid } =
    hook;

  useEffect(() => {
    var ibanValid = false;
    if (!noleggio.flg_sdd) {
      ibanValid = true;
    } else if (formData && formData.iban && formData.iban != '') {
      ibanValid = true;
    }

    if (onCompletedChanged) {
      onCompletedChanged(isValid && ibanValid);
    }
  }, [isValid, noleggio, formData]);

  console.log(isValid, errors);

  const toggleSwitchSdd = e => {
    if (e.target.checked) {
      setNoleggio(prev => {
        return {
          ...prev,
          flg_sdd: true,
        };
      });
    } else {
      setNoleggio(prev => {
        return {
          ...prev,
          flg_sdd: false,
        };
      });
      handleInputChange({
        target: {
          name: 'iban',
          value: '',
        },
      });
    }
  };
  const theme = useTheme();
  const matchMd = useMediaQuery(theme.breakpoints.up('md'));
  const matchLg = useMediaQuery(theme.breakpoints.up('lg'));
  const matchXl = useMediaQuery(theme.breakpoints.up('xl'));
  const formClass = matchMd ? 'horizontal' : 'vertical';

  const { clienti } = useSelector(state => state.clienti);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user?.tipologia != 'A') {
      dispatch(getClienti());
      if (noleggio.azienda != null) {
        setSelectedCliente(noleggio.azienda);
      }
    }
  }, []);

  const [selectedCliente, setSelectedCliente] = useState(null);

  const onClienteChange = (e, value) => {
    const applicableFields = [
      'ragione_sociale',
      'cf',
      'piva',
      'mail',
      'telefono',
      'sede_indirizzo',
      'sede_civico',
      'sede_cap',
      'sede_citta',
      'sede_provincia',
      'locazione_indirizzo',
      'locazione_civico',
      'locazione_cap',
      'locazione_citta',
      'locazione_provincia',
      'referente_note',
      'referente_cognome_nome',
      'referente_email',
      'referente_telefono',
      'pec',
      'codice_sdi',
      'locazione_latitudine',
      'locazione_longitudine',
      'sede_latitudine',
      'sede_longitudine',
    ];

    setSelectedCliente(value);
    if (value == null) {
      applicableFields.forEach(field => {
        handleInputChange({
          target: {
            name: field,
            value: '',
          },
        });
      });
    } else {
      applicableFields.forEach(field => {
        handleInputChange({
          target: {
            name: field,
            value: value ? value[field] : '',
          },
        });
      });
    }
  };

  const [coordSede, setCoordSede] = useState({ lat: 44.390789, lng: 7.820823 });
  const [coordLocazione, setCoordLocazione] = useState({
    lat: 44.390789,
    lng: 7.820823,
  });
  const [locationFieldsSede, setLocationFieldsSede] = useState([]);
  const [locationFieldsLocazione, setLocationFieldsLocazione] = useState([]);

  useEffect(() => {
    if (locationFieldsSede.length > 0) {
      let tmpFormData = {
        ...formData,
      };
      const indirizzo = locationFieldsSede.filter(item => {
        if (item.types[0] == 'route') {
          return item.long_name;
        }
      });
      if (indirizzo[0]) {
        tmpFormData.sede_indirizzo = indirizzo[0].long_name;
      }
      const civico = locationFieldsSede.filter(item => {
        if (item.types[0] == 'street_number') {
          return item.long_name;
        }
      });
      if (civico[0]) {
        tmpFormData.sede_civico = civico[0].long_name;
      }
      const provincia = locationFieldsSede.filter(item => {
        if (item.types[0] == 'administrative_area_level_2') {
          return item.long_name;
        }
      });
      if (provincia[0]) {
        tmpFormData.sede_provincia = provincia[0].short_name;
      }
      const citta = locationFieldsSede.filter(item => {
        if (item.types[0] == 'administrative_area_level_3') {
          return item.long_name;
        }
      });
      if (citta[0]) {
        tmpFormData.sede_citta = citta[0].long_name;
      }
      const cap = locationFieldsSede.filter(item => {
        if (item.types[0] == 'postal_code') {
          return item.long_name;
        }
      });
      if (cap[0]) {
        tmpFormData.sede_cap = cap[0].long_name;
      }
      if (coordSede) {
        tmpFormData.sede_latitudine = coordSede.lat.toString();
        tmpFormData.sede_longitudine = coordSede.lng.toString();
      }

      const fields = [
        'sede_indirizzo',
        'sede_civico',
        'sede_cap',
        'sede_citta',
        'sede_provincia',
        'sede_latitudine',
        'sede_longitudine',
      ];
      fields.forEach(field => {
        handleInputChange({
          target: {
            name: field,
            value: tmpFormData[field],
          },
        });
      });
    }
  }, [coordSede]);

  useEffect(() => {
    if (locationFieldsLocazione.length > 0) {
      let tmpFormData = {
        ...formData,
      };
      const indirizzo = locationFieldsLocazione.filter(item => {
        if (item.types[0] == 'route') {
          return item.long_name;
        }
      });
      if (indirizzo[0]) {
        tmpFormData.locazione_indirizzo = indirizzo[0].long_name;
      }
      const civico = locationFieldsLocazione.filter(item => {
        if (item.types[0] == 'street_number') {
          return item.long_name;
        }
      });
      if (civico[0]) {
        tmpFormData.locazione_civico = civico[0].long_name;
      }
      const provincia = locationFieldsLocazione.filter(item => {
        if (item.types[0] == 'administrative_area_level_2') {
          return item.long_name;
        }
      });
      if (provincia[0]) {
        tmpFormData.locazione_provincia = provincia[0].short_name;
      }
      const citta = locationFieldsLocazione.filter(item => {
        if (item.types[0] == 'administrative_area_level_3') {
          return item.long_name;
        }
      });
      if (citta[0]) {
        tmpFormData.locazione_citta = citta[0].long_name;
      }
      const cap = locationFieldsLocazione.filter(item => {
        if (item.types[0] == 'postal_code') {
          return item.long_name;
        }
      });
      if (cap[0]) {
        tmpFormData.locazione_cap = cap[0].long_name;
      }
      if (coordLocazione) {
        tmpFormData.locazione_latitudine = coordLocazione.lat.toString();
        tmpFormData.locazione_longitudine = coordLocazione.lng.toString();
      }

      const fields = [
        'locazione_indirizzo',
        'locazione_civico',
        'locazione_cap',
        'locazione_citta',
        'locazione_provincia',
        'locazione_latitudine',
        'locazione_longitudine',
      ];
      fields.forEach(field => {
        handleInputChange({
          target: {
            name: field,
            value: tmpFormData[field],
          },
        });
      });
    }
  }, [coordLocazione]);

  const canEditSelectedCliente = () => {
    if (selectedCliente == null) {
      return true;
    }
    return selectedCliente.flg_inoltrato == 0;
  };

  return (
    <Box>
      {showSearch && (
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <FormControl className={formClass} fullWidth>
            <Autocomplete
              disablePortal
              options={clienti}
              autoHighlight
              name="cliente"
              sx={{
                minWidth: '300px',
                '& .MuiButtonBase-root': {
                  padding: '10px',
                  height: '10px',
                  width: '10px',
                  marginRight: '5px',
                },
              }}
              value={selectedCliente}
              size="small"
              getOptionLabel={option => option.ragione_sociale}
              renderInput={params => (
                <TextField
                  {...params}
                  placeholder="Cerca Cliente"
                  slotProps={{
                    input: {
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      ),
                    },
                  }}
                />
              )}
              onChange={onClienteChange}
            />
          </FormControl>
        </Grid>
      )}
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, sm: 6, md: 6, lg: 4 }}>
          <FormControl className={formClass} fullWidth>
            <FormLabel
              htmlFor="ragione_sociale"
              sx={{ minWidth: '120px' }}
              required
            >
              Ragione Sociale
            </FormLabel>
            <TextField
              placeholder="Ragione Sociale"
              name="ragione_sociale"
              variant="outlined"
              value={formData?.ragione_sociale}
              onChange={handleInputChange}
              fullWidth
              error={!!errors.ragione_sociale}
              helperText={errors.ragione_sociale}
              slotProps={{
                input: {
                  disabled: viewMode === 'read' || !canEditSelectedCliente(),
                },
              }}
            />
          </FormControl>
        </Grid>

        <Grid size={{ xs: 12, sm: 6, md: 6, lg: 4 }}>
          <FormControl className={formClass} fullWidth>
            <FormLabel required htmlFor="cf" sx={{ minWidth: '120px' }}>
              Codice Fiscale
            </FormLabel>
            <TextField
              placeholder="Codice Fiscale"
              name="cf"
              value={formData?.cf}
              onChange={handleInputChange}
              fullWidth
              error={!!errors.cf}
              helperText={errors.cf}
              slotProps={{
                input: {
                  disabled: viewMode === 'read' || !canEditSelectedCliente(),
                },
              }}
            />
          </FormControl>
        </Grid>

        <Grid size={{ xs: 12, sm: 6, md: 6, lg: 4 }}>
          <FormControl className={formClass} fullWidth>
            <FormLabel required htmlFor="piva" sx={{ minWidth: '120px' }}>
              Partita Iva
            </FormLabel>
            <TextField
              placeholder="Partita IVA"
              name="piva"
              value={formData?.piva}
              onChange={handleInputChange}
              fullWidth
              error={!!errors.piva}
              helperText={errors.piva}
              slotProps={{
                input: {
                  disabled: viewMode === 'read' || !canEditSelectedCliente(),
                },
              }}
            />
          </FormControl>
        </Grid>

        <Grid size={{ xs: 12, sm: 6, md: 6, lg: 4 }}>
          <FormControl className={formClass} fullWidth>
            <FormLabel required htmlFor="mail" sx={{ minWidth: '120px' }}>
              Email
            </FormLabel>
            <TextField
              placeholder="Email"
              name="mail"
              value={formData?.mail}
              onChange={handleInputChange}
              fullWidth
              error={!!errors.mail}
              helperText={errors.mail}
              slotProps={{
                input: {
                  disabled: viewMode === 'read' || !canEditSelectedCliente(),
                },
              }}
            />
          </FormControl>
        </Grid>

        <Grid size={{ xs: 12, sm: 6, md: 6, lg: 4 }}>
          <FormControl className={formClass} fullWidth>
            <FormLabel required htmlFor="mail" sx={{ minWidth: '120px' }}>
              PEC
            </FormLabel>
            <TextField
              placeholder="Pec"
              name="pec"
              value={formData?.pec}
              onChange={handleInputChange}
              fullWidth
              error={!!errors.pec}
              helperText={errors.pec}
              slotProps={{
                input: {
                  disabled: viewMode === 'read' || !canEditSelectedCliente(),
                },
              }}
            />
          </FormControl>
        </Grid>

        <Grid size={{ xs: 12, sm: 6, md: 6, lg: 4 }}>
          <FormControl className={formClass} fullWidth>
            <FormLabel required htmlFor="telefono" sx={{ minWidth: '120px' }}>
              Telefono
            </FormLabel>
            <TextField
              placeholder="Telefono"
              name="telefono"
              value={formData?.telefono}
              onChange={handleInputChange}
              fullWidth
              error={!!errors.telefono}
              helperText={errors.telefono}
              slotProps={{
                input: {
                  disabled: viewMode === 'read' || !canEditSelectedCliente(),
                },
              }}
            />
          </FormControl>
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 6, lg: 4 }}>
          <FormControl className={formClass} fullWidth>
            <FormLabel htmlFor="codice_sdi" sx={{ minWidth: '120px' }}>
              Codice SDI
            </FormLabel>
            <TextField
              placeholder="Codice SDI"
              name="codice_sdi"
              value={formData?.codice_sdi}
              onChange={handleInputChange}
              fullWidth
              error={!!errors.codice_sdi}
              helperText={errors.codice_sdi}
              slotProps={{
                input: {
                  disabled: viewMode === 'read' || !canEditSelectedCliente(),
                },
              }}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Divider sx={{ my: 2 }} />
      <Box sx={{ mb: 1 }}>
        <Typography
          variant="h6"
          sx={{ fontFamily: 'Poppins-Bold', fontSize: 16 }}
        >
          Inserisci Indirizzi:
        </Typography>
      </Box>

      {!(viewMode === 'read' || !canEditSelectedCliente()) ? (
        <Grid container>
          <Box
            sx={{ display: 'block', width: '100%', maxWidth: '400px', mb: 1 }}
          >
            <SearchMap
              readOnly={viewMode === 'read' || !canEditSelectedCliente()}
              setCoord={setCoordSede}
              setLocationFields={setLocationFieldsSede}
            />
          </Box>
        </Grid>
      ) : (
        <></>
      )}

      <Grid container spacing={2}>
        <Grid size={{ xs: 12, sm: 6, md: 6, lg: 6, xl: 3 }}>
          <FormControl className={formClass} fullWidth>
            <FormLabel
              required
              htmlFor="sede_indirizzo"
              sx={{ minWidth: '100px' }}
            >
              Indirizzo Sede
            </FormLabel>
            <TextField
              placeholder="Indirizzo Sede"
              name="sede_indirizzo"
              value={formData?.sede_indirizzo}
              onChange={handleInputChange}
              fullWidth
              error={!!errors.sede_indirizzo}
              helperText={errors.sede_indirizzo}
              slotProps={{
                input: {
                  disabled: viewMode === 'read' || !canEditSelectedCliente(),
                },
              }}
            />
          </FormControl>
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 3, lg: 3, xl: 2 }}>
          <FormControl className={formClass} fullWidth>
            <FormLabel required htmlFor="sede_civico">
              N. Civico
            </FormLabel>
            <TextField
              placeholder="N. Civico"
              name="sede_civico"
              value={formData?.sede_civico}
              onChange={handleInputChange}
              fullWidth
              error={!!errors.sede_civico}
              helperText={errors.sede_civico}
              slotProps={{
                input: {
                  disabled: viewMode === 'read' || !canEditSelectedCliente(),
                },
              }}
            />
          </FormControl>
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 3, lg: 3, xl: 2 }}>
          <FormControl className={formClass} fullWidth>
            <FormLabel required htmlFor="sede_cap">
              CAP
            </FormLabel>
            <TextField
              placeholder="CAP"
              name="sede_cap"
              value={formData?.sede_cap}
              onChange={handleInputChange}
              fullWidth
              error={!!errors.sede_cap}
              helperText={errors.sede_cap}
              slotProps={{
                input: {
                  disabled: viewMode === 'read' || !canEditSelectedCliente(),
                },
              }}
            />
          </FormControl>
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 6, lg: 6, xl: 2 }}>
          <FormControl className={formClass} fullWidth>
            <FormLabel
              required
              htmlFor="sede_citta"
              sx={{ minWidth: matchXl ? 'auto' : '100px' }}
            >
              Città
            </FormLabel>
            <TextField
              placeholder="Città"
              name="sede_citta"
              value={formData?.sede_citta}
              onChange={handleInputChange}
              fullWidth
              error={!!errors.sede_citta}
              helperText={errors.sede_citta}
              slotProps={{
                input: {
                  disabled: viewMode === 'read' || !canEditSelectedCliente(),
                },
              }}
            />
          </FormControl>
        </Grid>

        <Grid size={{ xs: 12, sm: 6, md: 6, lg: 6, xl: 3 }}>
          <FormControl className={formClass} fullWidth>
            <FormLabel required htmlFor="sede_provincia">
              Provincia
            </FormLabel>
            <Box sx={{ width: '100%' }}>
              <SelectProvince
                name="sede_provincia"
                placeholder="Provincia"
                value={formData?.sede_provincia}
                onChange={handleInputChange}
                errors={errors.sede_provincia}
                readOnly={viewMode === 'read' || !canEditSelectedCliente()}
              />
            </Box>
          </FormControl>
        </Grid>
      </Grid>

      <Box sx={{ mt: 3, mb: 3 }}>
        <Button
          startIcon={<CopyAll />}
          variant="contained"
          color="info"
          size="small"
          onClick={() => {
            handleCopyAddress();
          }}
        >
          Copia Indirizzo Sede
        </Button>
      </Box>

      <Grid container>
        <Box sx={{ display: 'block', width: '100%', maxWidth: '400px', mb: 1 }}>
          <SearchMap
            setCoord={setCoordLocazione}
            setLocationFields={setLocationFieldsLocazione}
          />
        </Box>
      </Grid>

      <Grid container spacing={2}>
        <Grid size={{ xs: 12, sm: 6, md: 6, lg: 6, xl: 3 }}>
          <FormControl className={formClass} fullWidth>
            <FormLabel
              required
              htmlFor="locazione_indirizzo"
              sx={{ minWidth: '130px' }}
            >
              Indirizzo Locazione
            </FormLabel>
            <TextField
              placeholder="Indirizzo Locazione"
              name="locazione_indirizzo"
              value={formData?.locazione_indirizzo}
              onChange={handleInputChange}
              fullWidth
              error={!!errors.locazione_indirizzo}
              helperText={errors.locazione_indirizzo}
            />
          </FormControl>
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 3, lg: 3, xl: 2 }}>
          <FormControl className={formClass} fullWidth>
            <FormLabel required htmlFor="locazione_civico">
              N. Civico
            </FormLabel>
            <TextField
              placeholder="N. Civico"
              name="locazione_civico"
              value={formData?.locazione_civico}
              onChange={handleInputChange}
              fullWidth
              error={!!errors.locazione_civico}
              helperText={errors.locazione_civico}
            />
          </FormControl>
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 3, lg: 3, xl: 2 }}>
          <FormControl className={formClass} fullWidth>
            <FormLabel required htmlFor="locazione_cap">
              CAP
            </FormLabel>
            <TextField
              placeholder="CAP"
              name="locazione_cap"
              value={formData?.locazione_cap}
              onChange={handleInputChange}
              fullWidth
              error={!!errors.locazione_cap}
              helperText={errors.locazione_cap}
            />
          </FormControl>
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 6, lg: 6, xl: 2 }}>
          <FormControl className={formClass} fullWidth>
            <FormLabel
              required
              htmlFor="locazione_citta"
              sx={{ minWidth: matchXl ? 'auto' : '130px' }}
            >
              Città
            </FormLabel>
            <TextField
              placeholder="Città"
              name="locazione_citta"
              value={formData?.locazione_citta}
              onChange={handleInputChange}
              fullWidth
              error={!!errors.locazione_citta}
              helperText={errors.locazione_citta}
            />
          </FormControl>
        </Grid>

        <Grid size={{ xs: 12, sm: 6, md: 6, lg: 6, xl: 3 }}>
          <FormControl className={formClass} fullWidth>
            <FormLabel required htmlFor="locazione_provincia">
              Provincia
            </FormLabel>
            <Box sx={{ width: '100%' }}>
              <SelectProvince
                name="locazione_provincia"
                placeholder="Provincia"
                value={formData?.locazione_provincia}
                onChange={handleInputChange}
                errors={errors.locazione_provincia}
              />
            </Box>
          </FormControl>
        </Grid>
      </Grid>

      {showReferente ? (
        <>
          <Divider sx={{ my: 2 }} />
          <Typography fontWeight={'bold'}>
            Referente Azienda (Falcoltativo)
          </Typography>
          <Grid container spacing={2}>
            <Grid size={{ xs: 12, sm: 6, md: 6, lg: 4, xl: 3 }}>
              <FormControl className={formClass} fullWidth>
                <FormLabel
                  htmlFor="referente_cognome_nome"
                  sx={{ minWidth: '120px' }}
                >
                  Cognome Nome
                </FormLabel>
                <TextField
                  placeholder="Cognome Nome"
                  name="referente_cognome_nome"
                  value={formData?.referente_cognome_nome}
                  onChange={handleInputChange}
                  fullWidth
                  error={!!errors.referente_cognome_nome}
                  helperText={errors.referente_cognome_nome}
                />
              </FormControl>
            </Grid>
            <Grid size={{ xs: 12, sm: 6, md: 6, lg: 4, xl: 3 }}>
              <FormControl className={formClass} fullWidth>
                <FormLabel
                  htmlFor="referente_telefono"
                  sx={{ minWidth: !matchLg ? '60px' : 'auto' }}
                >
                  Telefono
                </FormLabel>
                <TextField
                  placeholder="Telefono"
                  name="referente_telefono"
                  value={formData?.referente_telefono}
                  onChange={handleInputChange}
                  fullWidth
                  error={!!errors.referente_telefono}
                  helperText={errors.referente_telefono}
                />
              </FormControl>
            </Grid>
            <Grid size={{ xs: 12, sm: 6, md: 6, lg: 4, xl: 3 }}>
              <FormControl className={formClass} fullWidth>
                <FormLabel
                  htmlFor="referente_email"
                  sx={{ minWidth: !matchLg ? '120px' : 'auto' }}
                >
                  Mail
                </FormLabel>
                <TextField
                  placeholder="Mail"
                  name="referente_email"
                  value={formData?.referente_email}
                  onChange={handleInputChange}
                  fullWidth
                  error={!!errors.referente_email}
                  helperText={errors.referente_email}
                />
              </FormControl>
            </Grid>
            <Grid size={{ xs: 12, sm: 6, md: 6, lg: 12, xl: 3 }}>
              <FormControl
                className={formClass}
                fullWidth
                sx={{
                  alignItems: 'start!important',
                  alignContent: 'start',
                  display: 'flex',
                }}
              >
                <FormLabel
                  htmlFor="referente"
                  sx={{
                    minWidth: !matchLg ? '60px' : matchXl ? 'auto' : '120px',
                  }}
                >
                  Note
                </FormLabel>
                <TextField
                  multiline
                  rows={3}
                  placeholder="Note"
                  name="referente_note"
                  variant="outlined"
                  value={formData?.referente_note}
                  onChange={handleInputChange}
                  sx={{ width: '100%' }}
                  error={!!errors.referente_note}
                  helperText={errors.referente_note}
                />
              </FormControl>
            </Grid>
          </Grid>
        </>
      ) : (
        <></>
      )}

      <Divider orientation="horizontal" flexItem sx={{ mt: 4, mb: 3 }} />

      <Box>
        <Typography sx={{ fontWeight: 'bold' }}>
          Seleziona Modalità di Pagamento:
        </Typography>
      </Box>
      <Grid container spacing={matchLg ? 8 : 2}>
        <Grid size={{ xs: 12, sm: 6, lg: 5 }}>
          <FormControlLabel
            style={{ pointerEvents: 'none' }}
            sx={{ ml: 0, alignItems: 'start', mt: 1 }}
            control={
              <CustomCheckbox
                checked={noleggio?.flg_sdd == 1 ? true : false}
                onChange={value => {
                  toggleSwitchSdd({
                    target: {
                      checked: value,
                    },
                  });
                }}
              />
            }
            labelPlacement="end"
            label={
              <Box
                sx={{ mr: 4, ml: 4, opacity: noleggio?.flg_sdd == 1 ? 1 : 0.5 }}
              >
                <Stack direction="row" sx={{ fontWeight: 'bold' }}>
                  <Typography fontWeight={'bold'} sx={{ display: 'inline' }}>
                    Attiva l'addebito diretto&nbsp;
                    <Typography
                      sx={{
                        color: 'primary.main',
                        fontWeight: 'bold',
                        display: 'inline',
                      }}
                    >
                      SDD?
                    </Typography>
                  </Typography>
                </Stack>
                <Typography variant="caption">
                  L’attivazione dell’addebito automatico avrà una spesa di
                  incasso di € 1,00(+ IVA)/mese
                </Typography>
              </Box>
            }
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, lg: 5 }}>
          <FormControlLabel
            style={{ pointerEvents: 'none' }}
            sx={{ ml: 0, alignItems: 'start', mt: 1 }}
            control={
              <CustomCheckbox
                checked={noleggio?.flg_sdd == 0 ? true : false}
                onChange={value => {
                  toggleSwitchSdd({
                    target: {
                      checked: value ? false : true,
                    },
                  });
                }}
              />
            }
            labelPlacement="end"
            label={
              <Box
                sx={{ mr: 4, ml: 4, opacity: noleggio?.flg_sdd == 0 ? 1 : 0.5 }}
              >
                <Stack direction="row" sx={{ fontWeight: 'bold' }}>
                  <Typography fontWeight={'bold'} sx={{ display: 'inline' }}>
                    Attiva il pagamento tramite&nbsp;
                    <Typography
                      sx={{
                        color: 'primary.main',
                        fontWeight: 'bold',
                        display: 'inline',
                      }}
                    >
                      Bonifico
                    </Typography>
                  </Typography>
                </Stack>
                <Typography variant="caption">
                  Le spese di incasso saranno di € 10,00(+ IVA) ogni incasso
                  tramite Bonifico Bancario
                </Typography>
              </Box>
            }
          />
        </Grid>
      </Grid>
      {noleggio?.flg_sdd ? (
        <FormControl
          className="horizontal"
          fullWidth
          sx={{ mt: 2, mb: 1, ml: 6, maxWidth: '400px' }}
        >
          <FormLabel required htmlFor="cf">
            IBAN
          </FormLabel>
          <TextField
            placeholder="IBAN"
            name="iban"
            value={formData?.iban}
            onChange={handleInputChange}
            fullWidth
            error={!!errors.iban}
            helperText={errors.iban}
          />
        </FormControl>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default InserisciDatiCliente;
