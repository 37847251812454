import { ArrowRight, CheckCircle, ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Stack,
  Typography,
} from '@mui/material';
import { green } from '@mui/material/colors';
import { cloneElement, useState } from 'react';
import { salvaFlgPersonalizzazioneAvanzato } from '../../../redux/noleggi/api';

function CardInserimentoStep({
  noleggio,
  setNoleggio,
  total,
  expandedAccordion,
  setExpandedAccordion,
  number,
  title,
  component,
  onCompletedChanged,
  extraContent,
  contentReadOnly,
}) {
  const [isCompleted, setIsCompleted] = useState(false);

  const _onCompletedChanged = val => {
    setIsCompleted(val);
    onCompletedChanged(number, val);
  };

  const onAvanti = async () => {
    setExpandedAccordion(prev => prev + 1);

    if (component?.ref?.current && component?.ref?.current?.getStepName) {
      const stepName = component?.ref?.current?.getStepName();

      if (stepName == 'personalizzazione-rata') {
        if (noleggio.id == null) {
          //new o clone
          setNoleggio(prev => {
            return {
              ...prev,
              flg_step_personalizzazione_avanzato: true,
            };
          });
        } else {
          if (noleggio.flg_step_personalizzazione_avanzato !== true) {
            try {
              await salvaFlgPersonalizzazioneAvanzato(noleggio.id);
              setNoleggio(prev => {
                return {
                  ...prev,
                  flg_step_personalizzazione_avanzato: true,
                };
              });
            } catch (err) {
              console.log(err);
            }
          }
        }
      }
    }
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <Accordion
        defaultExpanded={number == 1}
        expanded={expandedAccordion == number}
        onChange={e => {
          if (
            e.target.className &&
            typeof e.target.className === 'string' &&
            e.target.className.includes('Switch')
          ) {
            //nothing
          } else {
            setExpandedAccordion(prev => (prev == number ? null : number));
          }
        }}
      >
        <AccordionSummary
          className="accordion-sumary"
          expandIcon={
            <ExpandMore
              sx={{
                backgroundColor: 'background.primaryLight',
                borderRadius: '4px',
              }}
              color="primary"
            />
          }
          sx={{ flexDirection: 'row-reverse' }}
        >
          <Stack
            spacing={1}
            direction="row"
            justifyContent="space-between"
            sx={{ width: '100%' }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Typography
                variant="h6"
                component="h6"
                color="primary"
                sx={{ pl: 1, fontSize: 16 }}
              >
                {number}. {title}
              </Typography>
              <Box sx={{ ml: 4 }}>{extraContent}</Box>
            </Box>

            {isCompleted && <CheckCircle sx={{ color: green[400] }} />}
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            sx={{
              // pl: '50px',
              opacity: contentReadOnly ? 0.5 : 1,
              pointerEvents: contentReadOnly ? 'none' : 'auto',
            }}
          >
            {cloneElement(component, {
              onCompletedChanged: _onCompletedChanged,
            })}
          </Box>

          {number < total && (
            <Button
              sx={{ float: 'right', mb: 1, mt: 1 }}
              variant="contained"
              endIcon={<ArrowRight />}
              size="small"
              color="secondary"
              onClick={onAvanti}
            >
              Avanti
            </Button>
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

export default CardInserimentoStep;
