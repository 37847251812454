import { useEffect } from 'react';
import {
  Autocomplete,
  Box,
  FormHelperText,
  MenuItem,
  TextField,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getProduttoriCategoria } from '../../../redux/produttori/operations';

const SelectProduttore = ({
  name,
  placeholder,
  value,
  categoriaProdotto,
  onChange,
  errors,
}) => {
  const { produttoriCategoria } = useSelector(state => state.produttori);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProduttoriCategoria(categoriaProdotto));
  }, []);

  const _onChange = e => {
    onChange({
      target: {
        name: name,
        value: e.target.value,
        ragione_sociale:
          e.target.value == null
            ? null
            : produttoriCategoria.find(
                produttore => produttore.id == e.target.value
              ).ragione_sociale,
      },
    });
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Autocomplete
        autoHighlight
        fullWidth
        size="small"
        name={name}
        options={produttoriCategoria}
        sx={{
          '& .MuiButtonBase-root': {
            padding: '10px',
            height: '10px',
            width: '10px',
            marginRight: '5px',
          },
        }}
        getOptionLabel={option => option.ragione_sociale}
        value={produttoriCategoria.filter(v => v.id === value)[0] || null}
        onChange={(e, newVal) =>
          _onChange({ target: { name: name, value: newVal?.id } })
        }
        renderInput={params => (
          <TextField placeholder={placeholder} {...params} />
        )}
        getOptionKey={option => option.id}
        renderOption={option => (
          <MenuItem
            selected={option.key === value}
            onClick={() => {
              if (option.key === value) return;
              _onChange({ target: { name: name, value: option.key } });
            }}
          >
            {
              produttoriCategoria.filter(v => v.id === option.key)[0]
                .ragione_sociale
            }
          </MenuItem>
        )}
      />

      {errors && (
        <FormHelperText sx={{ color: 'error.main' }}>{errors}</FormHelperText>
      )}
    </Box>
  );
};

export default SelectProduttore;
