import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import MuiDrawer, { drawerClasses } from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import OptionsMenu from './OptionsMenu';
import useAuth from '../hooks/useAuth';
import { ReactComponent as LogoSvg } from '../assets/img/Doinn Rental Division.svg';

import { useNavigate } from 'react-router-dom';
import SideMenuContent from './SideMenuContent';
import { useEffect, useState } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setSideMenuOpen } from '../redux/app/slice';
import OptionsMenuCompact from './OptionsMenuCompact';

export default function SideMenu() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [drawerWidth, setDrawerWidth] = useState(240);
  const theme = useTheme();
  const dispatch = useDispatch();
  const matchLg = useMediaQuery(theme.breakpoints.up('lg'));
  const { sideMenuOpen } = useSelector(state => state.app);

  useEffect(() => {
    dispatch(setSideMenuOpen(matchLg));
  }, []);

  useEffect(() => {
    setDrawerWidth(sideMenuOpen ? 240 : 73);
  }, [sideMenuOpen]);

  const Drawer = styled(MuiDrawer)({
    width: drawerWidth,
    flexShrink: 0,
    boxSizing: 'border-box',
    mt: 10,
    [`& .${drawerClasses.paper}`]: {
      width: drawerWidth,
      boxSizing: 'border-box',
    },
  });

  const onNavigate = (path, data) => {
    navigate(path, data);
  };

  const onItemClick = fn => {
    fn();
  };

  return (
    <Drawer
      variant="permanent"
      sx={{
        display: { xs: 'none', md: 'block' },
      }}
    >
      <Box
        sx={{
          alignContent: 'center',
          textAlign: 'center',
          pt: 2,
          cursor: 'pointer',
        }}
        onClick={() => navigate('/dashboard')}
      >
        {/* <img src={LogoImg} alt="logo" style={{ width: '60%' }} /> */}
        <LogoSvg style={{ width: '60%' }} />
      </Box>

      <Stack sx={{ m: 2, flexGrow: 1 }}>
        <SideMenuContent onNavigate={onNavigate} onItemClick={onItemClick} />
        {sideMenuOpen ? (
          <Box>
            <Stack
              direction="row"
              sx={{
                p: 2,
                gap: 1,
                alignItems: 'center',
                borderTop: '1px solid',
                borderColor: 'divider',
              }}
            >
              <Avatar
                sizes="small"
                alt={user.username}
                sx={{ width: 36, height: 36 }}
              />
              <Box
                sx={{
                  flexGrow: 1,
                  minWidth: 0,
                  overflow: 'hidden',
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: 500,
                    lineHeight: '16px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: 'block',
                  }}
                >
                  {user.username}
                </Typography>
                <Typography
                  variant="caption"
                  sx={{
                    color: 'text.secondary',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: 'block',
                  }}
                >
                  {user.email}
                </Typography>
              </Box>
              <OptionsMenu />
            </Stack>
          </Box>
        ) : (
          <Box
            sx={{
              pt: 1,
              alignItems: 'center',
              borderTop: '1px solid',
              borderColor: 'divider',
            }}
          >
            <OptionsMenuCompact />
          </Box>
        )}
      </Stack>
    </Drawer>
  );
}
