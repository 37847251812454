import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getNoleggi } from '../../../redux/noleggi/operations';

const useNoleggiTable = (keys = []) => {
  const dispatch = useDispatch();
  const [searchFilter, setSearchFilter] = useState({ 1: '' });
  const { noleggi, getNoleggiAction } = useSelector(state => state.noleggi);

  const processFiltered = key => {
    const search = searchFilter[key ?? 1] ?? '';
    let noleggiFiltered = noleggi || [];
    if (search !== '') {
      noleggiFiltered = noleggiFiltered.filter(noleggio =>
        [
          noleggio.codice,
          noleggio.azienda?.ragione_sociale,
          noleggio.azienda?.piva,
          noleggio.azienda?.cf,
          noleggio.importo,
          noleggio.durata_mesi,
        ].some(field =>
          field?.toString().toLowerCase().includes(search.toLowerCase())
        )
      );
    }

    const noleggiAttivi = noleggiFiltered.filter(n => n.id_stato === 'TE');
    const noleggiBozza = noleggiFiltered.filter(
      n => n.id_stato === 'IB' || n.id_stato === 'DC'
    );
    const noleggiInoltrate = noleggiFiltered.filter(n => n.id_stato === 'IC');
    const noleggiConfermate = noleggiFiltered.filter(n => n.id_stato === 'CF');

    const noleggiBozzaScaduti = noleggiFiltered.filter(
      n => n.id_stato === 'SC'
    );
    const noleggiDeclinati = noleggiFiltered.filter(
      n => n.id_stato === 'RF' || n.id_stato === 'RC'
    );
    const noleggiScaduti = noleggiFiltered.filter(n => n.id_stato === 'FI');

    return {
      noleggi: noleggiFiltered,
      noleggiRecenti: noleggiFiltered.slice(0, 30),
      noleggiAttivi,
      noleggiBozza,
      noleggiInoltrate,
      noleggiConfermate,
      noleggiBozzaScaduti,
      noleggiDeclinati,
      noleggiScaduti,
    };
  };

  useEffect(() => {
    dispatch(getNoleggi());
  }, []);

  const onFilterChange = (value, key) => {
    setSearchFilter(prev => ({ ...prev, [key]: value }));
  };

  if (keys.length === 0) {
    return {
      filter: onFilterChange,
      getNoleggiAction,
      ...processFiltered(),
    };
  } else {
    const map = {};
    keys.forEach(key => {
      map[key] = processFiltered(key);
    });
    return {
      filter: onFilterChange,
      getNoleggiAction,
      ...map,
    };
  }
};

export default useNoleggiTable;
