import {
  Add,
  ArrowBackIos,
  ChevronRight,
  ContentCopy,
  Edit,
  ExpandMore,
  PanTool,
  UploadFile,
} from '@mui/icons-material';
import {
  Box,
  Card,
  CardContent,
  Grid2 as Grid,
  Stack,
  Typography,
  IconButton,
  Divider,
  Stepper,
  Step,
  StepLabel,
  Button,
  LinearProgress,
  Alert,
  CircularProgress,
  useMediaQuery,
  useTheme,
  AccordionDetails,
  AccordionSummary,
  Accordion,
  linearProgressClasses,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import BeniTable from './BeniTable';
import {
  downloadCertificatoIntervento,
  downloadDocumento,
  downloadFattura,
  getInterventi,
  inoltraRichiestaModifica,
  rifiutaRichiesta,
  uploadContrattoFirmato,
} from '../../../redux/noleggi/api';
import { useEffect, useState } from 'react';
import LoadingMask from '../../../components/LoadingMask';
import { useDispatch } from 'react-redux';
import { setNoleggioClone } from '../../../redux/noleggi/slice';
import { showAlertDialog, showSnackbar } from '../../../redux/app/slice';
import DialogIntervento from './DialogIntervento';
import { downloadBlob } from '../../../utils/download';
import DialogRichiestaModificaNoleggio from './DialogRichiestaModificaNoleggio';
import { formatCurrency } from '../../../utils/formatNumber';
import { formatStringDate } from '../../../utils/formadDate';
import RichiestaModificaSvg from '../../../assets/img/RichiestaModifica.svg';
import useAuth from '../../../hooks/useAuth';
import ScadenziarioTable from './ScadenziarioTable';
import InterventiTable from './InterventiTable';
import DocumentiFattureGrid from './DocumentiFattureGrid';

export default function PraticaNoleggio({
  setNoleggioData,
  noleggioData,
  setViewMode,
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useAuth();
  const [dialogRichiestaModificaOpen, setDialogRichiestaModificaOpen] =
    useState(false);
  const [dialogManutazioneOpen, setDialogManutazioneOpen] = useState(false);
  const [selectedIntervento, setSelectedIntervento] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isInterventiLoading, setIsInterventiLoading] = useState(false);
  const [interventi, setInterventi] = useState(noleggioData?.interventi || []);
  const theme = useTheme();
  const matchMd = useMediaQuery(theme.breakpoints.up('md'));
  const matchXs = useMediaQuery(theme.breakpoints.up('xs'));
  const matchLg = useMediaQuery(theme.breakpoints.up('lg'));

  let steps = [];
  if (noleggioData.id_stato != 'RF') {
    steps = [
      {
        key: 1,
        title: 'PRATICA INOLTRATA',
        subtitle: noleggioData?.id_step < 5 ? 'In Valutazione' : 'Valutata',
      },
      {
        key: 2,
        title: 'PRATICA APPROVATA',
        subtitle:
          noleggioData?.id_step < 9
            ? 'In Attesa di Caricamento del Contratto Firmato'
            : 'Contratto Firmato Caricato',
      },
      {
        key: 3,
        title: 'CONTRATTO CARICATO',
        subtitle:
          noleggioData?.id_step < 10
            ? 'In Attesa di Validazione del Contratto Firmato'
            : 'Contratto Firmato Validato',
      },
      {
        key: 4,
        title: 'PRATICA CONFERMATA',
        subtitle: 'In Attesa di Consegna Materiale',
      },
    ];
  } else {
    steps = [
      {
        key: 1,
        title: 'PRATICA INOLTRATA',
        subtitle: 'Valutata',
      },
      { key: 2, title: 'PRATICA RIFIUTATA', subtitle: '', error: true },
    ];
  }

  const onClona = () => {
    dispatch(setNoleggioClone({ ...noleggioData, codice: null }));
    navigate('/noleggi/noleggio/clone/' + Date.now(), { replace: true });
  };

  const onCompleta = () => {
    setViewMode('edit');
  };

  const onDownloadDocumento = async (name, id) => {
    setIsLoading(true);
    try {
      const pdfData = await downloadDocumento(id);
      downloadBlob(pdfData, 'application/pdf', name + '.pdf');
    } catch (error) {
      dispatch(showSnackbar({ message: error, severity: 'error' }));
    } finally {
      setIsLoading(false);
    }
  };

  const onDownloadFattura = async (name, id) => {
    setIsLoading(true);
    try {
      const pdfData = await downloadFattura(id);
      downloadBlob(pdfData, 'application/pdf', name + '.pdf');
    } catch (error) {
      dispatch(showSnackbar({ message: error, severity: 'error' }));
    } finally {
      setIsLoading(false);
    }
  };

  //active step vuole la posizione dell'array steps + 1
  let activeStep = 1;
  if (noleggioData.id_stato === 'IC' && noleggioData.id_step < 5) {
    activeStep = 1;
  } else if (noleggioData.id_stato === 'IC' && noleggioData.id_step >= 5) {
    if (noleggioData.id_step < 9) {
      activeStep = 2;
    } else if (noleggioData.id_step < 10) {
      activeStep = 3;
    }
  } else if (noleggioData.id_stato === 'CF') {
    activeStep = 4;
  } else if (noleggioData.id_stato === 'RF') {
    activeStep = 2;
  }

  useEffect(() => {
    const state = location.state;
    if (
      state?.id_richiesta_modifica &&
      noleggioData.richiesta_modifica?.id === state?.id_richiesta_modifica
    ) {
      setDialogRichiestaModificaOpen(true);
    }
  }, []);

  const getIndirizzoLocazione = () => {
    let indirizzo = '';
    if (
      noleggioData?.azienda?.locazione_indirizzo != null &&
      noleggioData?.azienda?.locazione_indirizzo != ''
    ) {
      indirizzo += noleggioData?.azienda?.locazione_indirizzo;
    }
    if (
      noleggioData?.azienda?.locazione_civico != null &&
      noleggioData?.azienda?.locazione_civico != ''
    ) {
      indirizzo += ', ' + noleggioData?.azienda?.locazione_civico;
    }
    if (
      noleggioData?.azienda?.locazione_cap != null &&
      noleggioData?.azienda?.locazione_cap != ''
    ) {
      indirizzo += ' - ' + noleggioData?.azienda?.locazione_cap;
    }
    if (
      noleggioData?.azienda?.locazione_citta != null &&
      noleggioData?.azienda?.locazione_citta != ''
    ) {
      indirizzo += ' ' + noleggioData?.azienda?.locazione_citta;
    }
    return indirizzo;
  };

  const getIndirizzoSede = () => {
    let indirizzo = '';
    if (
      noleggioData?.azienda?.sede_indirizzo != null &&
      noleggioData?.azienda?.sede_indirizzo != ''
    ) {
      indirizzo += noleggioData?.azienda?.sede_indirizzo;
    }
    if (
      noleggioData?.azienda?.sede_civico != null &&
      noleggioData?.azienda?.sede_civico != ''
    ) {
      indirizzo += ', ' + noleggioData?.azienda?.sede_civico;
    }
    if (
      noleggioData?.azienda?.sede_cap != null &&
      noleggioData?.azienda?.sede_cap != ''
    ) {
      indirizzo += ' - ' + noleggioData?.azienda?.sede_cap;
    }
    if (
      noleggioData?.azienda?.sede_citta != null &&
      noleggioData?.azienda?.sede_citta != ''
    ) {
      indirizzo += ' ' + noleggioData?.azienda?.sede_citta;
    }
    return indirizzo;
  };

  const getIndirizzoSedeFornitore = () => {
    let indirizzo = '';
    if (
      noleggioData?.fornitore?.sede_indirizzo != null &&
      noleggioData?.fornitore?.sede_indirizzo != ''
    ) {
      indirizzo += noleggioData?.fornitore?.sede_indirizzo;
    }
    if (
      noleggioData?.fornitore?.sede_civico != null &&
      noleggioData?.fornitore?.sede_civico != ''
    ) {
      indirizzo += ', ' + noleggioData?.fornitore?.sede_civico;
    }
    if (
      noleggioData?.fornitore?.sede_cap != null &&
      noleggioData?.fornitore?.sede_cap != ''
    ) {
      indirizzo += ' - ' + noleggioData?.fornitore?.sede_cap;
    }
    if (
      noleggioData?.fornitore?.sede_citta != null &&
      noleggioData?.fornitore?.sede_citta != ''
    ) {
      indirizzo += ' ' + noleggioData?.fornitore?.sede_citta;
    }
    return indirizzo;
  };

  const onNewIntervento = () => {
    setSelectedIntervento(null);
    setDialogManutazioneOpen(true);
  };

  // const onViewIntervento = intervento => {
  //   setSelectedIntervento(intervento);
  //   setDialogManutazioneOpen(true);
  // };

  const onInterventoSaved = () => {
    setDialogManutazioneOpen(false);
    caricaInterventi();
  };

  const onInterventoDeleted = () => {
    setDialogManutazioneOpen(false);
    caricaInterventi();
  };

  const onDownloadCertificato = async id => {
    setIsLoading(true);
    try {
      const blobData = await downloadCertificatoIntervento(id);
      downloadBlob(blobData, 'application/pdf', 'certificato_manutenzione.pdf');
    } catch (error) {
      dispatch(showSnackbar({ message: error, severity: 'error' }));
    } finally {
      setIsLoading(false);
    }
  };

  const caricaInterventi = async () => {
    setIsInterventiLoading(true);
    try {
      const response = await getInterventi(noleggioData.id);
      setInterventi(response);
    } catch (error) {
      dispatch(showSnackbar({ message: error, severity: 'error' }));
    } finally {
      setIsInterventiLoading(false);
    }
  };

  const onRichiediModifica = () => {
    setDialogRichiestaModificaOpen(true);
  };

  const onModificaClick = () => {
    if (noleggioData.richiesta_modifica.id_stato == 'DC') {
      setDialogRichiestaModificaOpen(true);
    } else {
      navigate('/richieste-modifica/edit', {
        state: { id: noleggioData.richiesta_modifica.id },
      });
    }
  };

  const onInoltraModifica = async () => {
    setIsLoading(true);
    try {
      const response = await inoltraRichiestaModifica(
        noleggioData.richiesta_modifica.id,
        { flg_firma_digitale: 0 }
      );
      noleggioData.richiesta_modifica = response;
      setDialogRichiestaModificaOpen(false);
    } catch (error) {
      dispatch(showSnackbar({ message: error, severity: 'error' }));
    } finally {
      setIsLoading(false);
    }
  };

  const onCaricaContrattoFirmato = () => {
    dispatch(
      showAlertDialog({
        btnConfermaText: 'CARICA',
        btnAnnullaText: 'ANNULLA',
        title: 'Carica Contratto Firmato',
        message: '',
        showFileInput: true,
        fileRequired: true,
        onConfirm: data => {
          onCaricaContrattoFirmatoAction(data);
        },
      })
    );
    return;
  };

  const onCaricaContrattoFirmatoAction = async data => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('file', data.file);
    try {
      const result = await uploadContrattoFirmato(noleggioData.id, formData);
      dispatch(
        showSnackbar({
          message: 'Contratto caricato con successo',
          severity: 'success',
        })
      );
      setNoleggioData(result);
    } catch (error) {
      dispatch(showSnackbar({ message: error, severity: 'error' }));
    } finally {
      setIsLoading(false);
    }
  };

  const onRifiuta = () => {
    dispatch(
      showAlertDialog({
        btnConfermaText: 'RIFIUTA',
        btnAnnullaText: 'ANNULLA',
        title: 'Rifiuta Proposta Noleggio',
        message: 'Sei sicuro di voler rifiutare la proposta di noleggio?',
        showNoteInput: true,
        noteRequired: true,
        onConfirm: data => {
          onRifiutaRichiesta(data);
        },
      })
    );
    return;
  };

  const onRifiutaRichiesta = async data => {
    setIsLoading(true);
    try {
      await rifiutaRichiesta(noleggioData.id, { note: data.note });
      dispatch(
        showSnackbar({
          message: 'Proposta rifiutata con successo',
          severity: 'success',
        })
      );
      navigate(-1);
    } catch (error) {
      dispatch(showSnackbar({ message: error, severity: 'error' }));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ backgroundColor: 'background.paper' }}>
      {isLoading && <LoadingMask />}
      <Stack
        justifyContent="space-between"
        flexDirection={matchMd ? 'row' : 'column'}
        sx={{
          backgroundColor: 'background.primaryLight',
          p: 2,
          borderRadius: '4px',
        }}
      >
        <Stack flexDirection="row" alignItems="center" gap={1}>
          <IconButton
            sx={{ border: 'none' }}
            size="small"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIos />
          </IconButton>
          <Box sx={{ ml: 1 }}>
            <Typography
              component="h4"
              variant="h6"
              sx={{ fontSize: 14 }}
              color="primary"
            >
              Pratica di Noleggio
            </Typography>
            <Stack flexDirection="row">
              <Typography component="h3" variant="h6" sx={{ fontSize: 17 }}>
                Operazione {noleggioData.codice}
                {noleggioData?.azienda?.ragione_sociale != '' &&
                noleggioData?.azienda?.ragione_sociale != null ? (
                  <>
                    {' '}
                    -{' '}
                    <span style={{ color: theme.palette.primary.main }}>
                      {noleggioData?.azienda?.ragione_sociale}
                    </span>
                  </>
                ) : (
                  <></>
                )}
              </Typography>
            </Stack>
          </Box>
        </Stack>

        <Stack
          flexDirection="row"
          justifyContent={matchMd ? 'flex-end' : 'center'}
          alignItems={matchMd ? 'center' : 'end'}
          sx={{ mt: matchMd ? 0 : 1 }}
        >
          {(noleggioData.id_stato == 'IB' || noleggioData.id_stato == 'DC') && (
            <Button
              variant="contained"
              sx={{ mr: 2, fontFamily: 'Poppins-Bold' }}
              size="small"
              onClick={onCompleta}
              startIcon={<Edit />}
            >
              COMPLETA PROPOSTA
            </Button>
          )}
          {noleggioData.id_stato == 'TE' &&
            !noleggioData.richiesta_modifica && (
              <Button
                variant="contained"
                sx={{ mr: 2, fontFamily: 'Poppins-Bold' }}
                size="small"
                onClick={onRichiediModifica}
                startIcon={<Edit />}
              >
                RICHIEDI MODIFICA
              </Button>
            )}
          {noleggioData.flg_carica_contratto_firmato ? (
            <>
              <Button
                variant="contained"
                color="white"
                sx={{ mr: 2, fontFamily: 'Poppins-Bold' }}
                size="small"
                onClick={onCaricaContrattoFirmato}
                startIcon={<UploadFile />}
              >
                CONTRATTO FIRMATO
              </Button>
            </>
          ) : (
            <></>
          )}
          {noleggioData.flg_rifiuta ? (
            <>
              <Button
                variant="contained"
                color="white"
                sx={{ mr: 2, fontFamily: 'Poppins-Bold' }}
                size="small"
                onClick={onRifiuta}
                startIcon={<PanTool />}
              >
                RIFIUTA PROPOSTA
              </Button>
            </>
          ) : (
            <></>
          )}
          <Button
            variant="contained"
            color="white"
            sx={{ mr: 2, fontFamily: 'Poppins-Bold' }}
            size="small"
            onClick={onClona}
            startIcon={<ContentCopy />}
          >
            CLONA PROPOSTA
          </Button>
          {noleggioData.id_stato == 'TE' && noleggioData.richiesta_modifica && (
            <Stack
              flexDirection={'row'}
              alignItems={'center'}
              justifyContent={'center'}
              sx={{
                backgroundColor: 'white',
                height: 36,
                borderRadius: '4px',
                minWidth: 0,
                mt: matchMd ? 0 : 2,
              }}
              onClick={onModificaClick}
            >
              <Stack
                sx={{
                  px: 1,
                  py: 1,
                  backgroundColor: '#103188',
                  borderRadius: '4px 0 0 4px',
                }}
                height={'100%'}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <img src={RichiestaModificaSvg} alt="" />
              </Stack>
              <Stack sx={{ pl: 1, flex: 1, minWidth: 0 }}>
                <Typography
                  component="h4"
                  sx={{
                    fontFamily: 'Poppins-Bold',
                    color: '#103188',
                    lineHeight: 1.1,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  Richiesta di Modifica
                </Typography>
                <Typography
                  component="h5"
                  sx={{
                    color: '#103188',
                    lineHeight: 1.1,
                    fontSize: 12,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {noleggioData.richiesta_modifica.nome_stato}
                </Typography>
              </Stack>

              <Stack
                alignItems={'center'}
                justifyContent={'center'}
                sx={{ pl: 1 }}
              >
                <ChevronRight
                  sx={{ color: '#446BD5', cursor: 'pointer' }}
                  onClick={onModificaClick}
                />
              </Stack>
            </Stack>
            // <Button
            //   variant="contained"
            //   sx={{ mr: 2, fontFamily: 'Poppins-Bold' }}
            //   size="small"
            //   onClick={onRichiediModifica}
            //   startIcon={<Edit />}
            // >
            //   RICHIEDI MODIFICA
            // </Button>
          )}
        </Stack>
      </Stack>
      <Grid container spacing={0.5}>
        <Grid size={{ xs: 12, lg: 12, height: '100%' }}>
          <Card
            sx={{ p: 2, backgroundColor: 'background.default', height: '100%' }}
          >
            <Grid container spacing={1}>
              <Grid size={{ xl: 5.9, xs: 12 }}>
                <Typography
                  component="h4"
                  variant="h6"
                  sx={{ fontSize: 14 }}
                  color="primary"
                >
                  DATI OPERAZIONE
                </Typography>
                <Card
                  sx={{
                    backgroundColor: 'background.primaryLight',
                    border: 'none',
                    ml: -1,
                    mt: 2,
                    p: 0,
                    py: 1,
                    pl: 1,
                    pr: 1,
                  }}
                >
                  <Stack flexDirection="row" justifyContent="space-between">
                    <Grid container sx={{ width: '100%' }} spacing={1}>
                      <Grid size={{ xs: 6, sm: 3, md: 3 }}>
                        <Typography sx={{ fontSize: 14 }}>
                          Importo Pratica:
                        </Typography>
                      </Grid>
                      <Grid size={{ xs: 6, sm: 9, md: 9 }}>
                        <Typography sx={{ fontWeight: 'bold', fontSize: 14 }}>
                          {formatCurrency(noleggioData?.importo)}
                        </Typography>
                      </Grid>
                      <Grid size={{ xs: 6, sm: 3, md: 3 }}>
                        <Typography sx={{ fontSize: 14 }}>
                          Canone Rata Totale:
                        </Typography>
                      </Grid>
                      <Grid size={{ xs: 6, sm: 3, md: 3 }}>
                        <Box>
                          <Typography sx={{ fontWeight: 'bold', fontSize: 14 }}>
                            {formatCurrency(noleggioData?.rata_no_iva)} (+ IVA)
                          </Typography>
                          <Typography sx={{ fontSize: 14 }}>
                            {formatCurrency(noleggioData?.rata)} (IVA inclusa)
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid size={{ xs: 6, sm: 3, md: 6 }}>
                        <Typography sx={{ fontSize: 12 }}>
                          Include Spese Incasso:{' '}
                          {formatCurrency(noleggioData?.spese_incasso)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Stack>
                </Card>

                <Box sx={{ width: '100%', pr: 1 }}>
                  <Grid
                    container
                    spacing={1}
                    sx={{ mt: 1, fontSize: 14, width: '100%' }}
                  >
                    <Grid size={{ xs: 6, sm: 3, md: 3 }}>Assicurazione:</Grid>
                    <Grid size={{ xs: 6, sm: 3, md: 3 }}>
                      <Typography sx={{ fontSize: 14 }}>
                        {noleggioData?.flg_assicurazione
                          ? 'ATTIVA'
                          : 'NON ATTIVA'}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Box sx={{ width: '100%', pr: 1 }}>
                  <Grid container spacing={1} sx={{ mt: 1, width: '100%' }}>
                    <Grid size={{ xs: 6, sm: 3, md: 3 }} sx={{ fontSize: 14 }}>
                      Durata:
                    </Grid>
                    <Grid size={{ xs: 6, sm: 3, md: 3 }}>
                      <Typography sx={{ fontSize: 14, fontWeight: 'bold' }}>
                        {noleggioData?.durata_mesi} mesi
                      </Typography>
                    </Grid>
                    <Grid
                      size={{ xs: 6, sm: 3, md: 3 }}
                      sx={{ fontSize: 14, lineHeight: 1.1 }}
                    >
                      Data Creazione Pratica:
                    </Grid>
                    <Grid size={{ xs: 6, sm: 3, md: 3 }}>
                      <Typography sx={{ fontSize: 14, fontWeight: 'bold' }}>
                        {formatStringDate(noleggioData?.created_at)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>

                {user.tipologia == 'F' && noleggioData.firmatario != null ? (
                  <>
                    <Box sx={{ width: '100%', pr: 1, mt: 1 }}>
                      <Grid
                        container
                        spacing={1}
                        sx={{ mt: 1, fontSize: 14, width: '100%' }}
                      >
                        <Grid size={{ xs: 6, sm: 3, md: 3 }}>Firmatario:</Grid>
                        <Grid size={{ xs: 6, sm: 3, md: 5 }}>
                          <Typography sx={{ fontSize: 14 }}>
                            {noleggioData?.firmatario}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </>
                ) : (
                  <></>
                )}

                {noleggioData.id_stato !== 'TE' &&
                  noleggioData.id_stato !== 'FI' && (
                    <Box sx={{ width: '100%', pr: 1 }}>
                      <Grid container spacing={1} sx={{ mt: 1 }}>
                        <Grid
                          size={{ xs: 6, sm: 3, md: 3 }}
                          sx={{ fontSize: 14 }}
                        >
                          Stato Pratica:
                        </Grid>
                        <Grid size={{ xs: 6, sm: 5, md: 5 }}>
                          <Typography
                            color={
                              noleggioData.id_stato === 'SC' ||
                              noleggioData.id_stato === 'RF' ||
                              noleggioData.id_stato === 'RC'
                                ? 'error.main'
                                : noleggioData.id_stato === 'CF'
                                  ? 'success'
                                  : ''
                            }
                            sx={{ fontFamily: 'Poppins-Bold' }}
                          >
                            {noleggioData?.stato}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                {noleggioData.flg_personalizza_rata ? (
                  <Box sx={{ width: '100%', pr: 1 }}>
                    <Grid container spacing={1} sx={{ mt: 1 }}>
                      <Grid
                        size={{ xs: 6, sm: 3, md: 3, lg: 3, xl: 3 }}
                        sx={{ fontSize: 14 }}
                      >
                        Richiesta Personalizzazione Rata:
                      </Grid>
                      <Grid
                        size={{ xs: 6, sm: 5, md: 5 }}
                        sx={{
                          display: 'flex',
                          justifyContent: 'start',
                          alignItems: 'center',
                        }}
                      >
                        <Typography sx={{ fontFamily: 'Poppins-Bold' }}>
                          {formatCurrency(noleggioData?.rata_personalizzata)}
                          /mese
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                ) : (
                  <></>
                )}

                <Box sx={{ width: '100%' }}>
                  {(noleggioData.id_stato === 'TE' ||
                    noleggioData.id_stato === 'FI') && (
                    <Box sx={{ mt: 2 }}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Box sx={{ width: '100%', mr: 1 }}>
                          <LinearProgress
                            sx={{
                              height: 10,
                              borderRadius: 5,
                              [`&.${linearProgressClasses.colorPrimary}`]: {
                                backgroundColor: theme.palette.grey[200],
                                ...theme.applyStyles('dark', {
                                  backgroundColor: theme.palette.grey[800],
                                }),
                              },
                              [`& .${linearProgressClasses.bar}`]: {
                                borderRadius: 5,
                                backgroundColor: '#1a90ff',
                                ...theme.applyStyles('dark', {
                                  backgroundColor: '#308fe8',
                                }),
                              },
                            }}
                            variant="determinate"
                            value={noleggioData.percentuale_avanzamento}
                          />
                        </Box>
                        <Box sx={{ minWidth: 35 }}>
                          <Typography
                            variant="body2"
                            sx={{ color: 'text.secondary' }}
                          >
                            {`${Math.round(noleggioData.percentuale_avanzamento)}%`}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          px: 4,
                          justifyContent: 'space-between',
                        }}
                      >
                        <Box sx={{ textAlign: 'center' }}>
                          <Typography sx={{ fontSize: 14 }}>
                            {formatStringDate(noleggioData.data_inizio)}
                          </Typography>

                          <Typography sx={{ fontSize: 12 }}>
                            Inizio Noleggio
                          </Typography>
                        </Box>
                        <Box sx={{ textAlign: 'center' }}>
                          <Typography sx={{ fontSize: 14 }}>
                            {formatStringDate(noleggioData.data_fine)}
                          </Typography>

                          <Typography sx={{ fontSize: 12 }}>
                            Fine Noleggio
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  )}
                  {(noleggioData.id_stato == 'IC' ||
                    noleggioData.id_stato == 'FD' ||
                    noleggioData.id_stato == 'CF' ||
                    noleggioData.id_stato == 'RF') && (
                    <Grid container spacing={1} sx={{ mt: 2 }}>
                      <Grid size={{ xs: 1.25, xl: 1.25 }}></Grid>
                      <Grid size={{ xs: 10.75, xl: 10.75 }}>
                        <Stepper
                          activeStep={activeStep}
                          alternativeLabel
                          sx={{ overflowY: 'auto', overflowX: 'hidden', py: 1 }}
                        >
                          {steps.map(label => (
                            <Step key={label.key}>
                              <StepLabel error={label.error}>
                                <Typography
                                  sx={{
                                    fontSize: 12,
                                    display: 'block',
                                    lineHeight: 1.1,
                                    color: 'text.primary',
                                  }}
                                >
                                  {label.title}
                                </Typography>
                                <Typography
                                  variant="caption"
                                  sx={{
                                    fontSize: 10,
                                    display: 'block',
                                    lineHeight: 1.1,
                                    mt: 0.5,
                                  }}
                                >
                                  {label.subtitle}
                                </Typography>
                              </StepLabel>
                            </Step>
                          ))}
                        </Stepper>
                      </Grid>
                    </Grid>
                  )}

                  {noleggioData.flg_scadenza && (
                    <Alert severity="info" sx={{ mt: 2 }}>
                      <Typography variant="caption">
                        Il Noleggio è in scadenza
                      </Typography>
                    </Alert>
                  )}
                </Box>
              </Grid>
              {matchLg ? (
                <Grid
                  size={{ xl: 0.2, xs: 0 }}
                  sx={{ display: 'flex', justifyContent: 'center' }}
                >
                  <Divider orientation="vertical" flexItem />
                </Grid>
              ) : (
                <></>
              )}
              {matchXs && !matchLg ? (
                <Grid
                  size={{ xl: 0, xs: 12 }}
                  sx={{ display: 'flex', justifyContent: 'center' }}
                >
                  <Divider sx={{ width: '100%' }} />
                </Grid>
              ) : (
                <></>
              )}

              <Grid size={{ xl: 5.9, xs: 12 }}>
                <Card
                  sx={{
                    backgroundColor: 'background.default',
                    height: '100%',
                    border: 'none',
                    p: 0,
                    m: 0,
                    mx: 0,
                  }}
                >
                  <Typography
                    component="h4"
                    variant="h6"
                    sx={{ fontSize: 14 }}
                    color="primary"
                  >
                    Elenco Beni Aggiunti
                  </Typography>
                  <CardContent sx={{ mt: 2 }}>
                    <BeniTable
                      rows={noleggioData?.dettagli || []}
                      readOnly={true}
                      autoHeight={true}
                      viewMode="full"
                    />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Card>
        </Grid>

        <Grid size={{ xs: 12 }}>
          <Box>
            <Accordion disableGutters={true}>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography
                  component="h4"
                  variant="h6"
                  sx={{ fontSize: 14 }}
                  color="primary"
                >
                  {user.tipologia == 'A' ? 'I TUOI DATI' : 'DATI CLIENTE'}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={1} sx={{ mt: 1 }}>
                  <Grid size={{ xs: 4, lg: 1 }}>
                    <Typography sx={{ fontFamily: 'Poppins-Bold' }}>
                      Dati Azienda
                    </Typography>
                  </Grid>
                  <Grid size={{ xs: 4, lg: 2 }}>
                    <Stack flexDirection="row">
                      <Stack>
                        <Typography>Ragione Sociale:</Typography>
                        <Typography>Codice Fiscale:</Typography>
                        <Typography>P.IVA:</Typography>
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid size={{ xs: 4, lg: 2 }}>
                    <Stack flexDirection="row">
                      <Stack sx={{ ml: 2 }}>
                        <Typography>
                          {noleggioData?.azienda?.ragione_sociale}
                        </Typography>
                        <Typography>{noleggioData?.azienda?.cf}</Typography>
                        <Typography>{noleggioData?.azienda?.piva}</Typography>
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid
                    size={{ xs: 0, lg: 1 }}
                    sx={{ display: { xs: 'none', lg: 'block' } }}
                  ></Grid>
                  <Grid size={{ xs: 4, lg: 1 }}>
                    <Typography sx={{ fontFamily: 'Poppins-Bold' }}>
                      Indirizzo
                    </Typography>
                  </Grid>
                  <Grid size={{ xs: 4, lg: 1 }}>
                    <Stack flexDirection="row">
                      <Stack>
                        <Typography>Ind. Locazione:</Typography>
                        <Typography>Ind. Sede:</Typography>
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid size={{ xs: 4, lg: 3 }}>
                    <Stack flexDirection="row">
                      <Stack sx={{ ml: 2 }}>
                        <Typography>{getIndirizzoLocazione()}</Typography>
                        <Typography>{getIndirizzoSede()}</Typography>
                      </Stack>
                    </Stack>
                  </Grid>
                </Grid>
                <Grid container spacing={1} sx={{ mt: 2 }}>
                  <Grid size={{ xs: 4, lg: 1 }}>
                    <Typography sx={{ fontFamily: 'Poppins-Bold' }}>
                      Contatti
                    </Typography>
                  </Grid>
                  <Grid size={{ xs: 4, lg: 2 }}>
                    <Stack flexDirection="row">
                      <Stack>
                        <Typography>Email:</Typography>
                        <Typography>Telefono:</Typography>
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid size={{ xs: 4, lg: 3 }}>
                    <Stack flexDirection="row">
                      <Stack sx={{ ml: 2 }}>
                        <Typography>{noleggioData?.azienda?.mail}</Typography>
                        <Typography>
                          {noleggioData?.azienda?.telefono}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Grid>
                  {user.tipologia === 'F' || user.tipologia === 'M' ? (
                    <>
                      <Grid size={{ xs: 4, lg: 1 }}>
                        <Typography sx={{ fontFamily: 'Poppins-Bold' }}>
                          Referente
                        </Typography>
                      </Grid>
                      <Grid size={{ xs: 4, lg: 2 }}>
                        <Stack flexDirection="row">
                          <Stack>
                            <Typography>Cognome Nome:</Typography>
                            <Typography>Telefono:</Typography>
                            <Typography>Mail:</Typography>
                            <Typography>Note:</Typography>
                          </Stack>
                        </Stack>
                      </Grid>
                      <Grid size={{ xs: 4, lg: 2 }}>
                        <Stack flexDirection="row">
                          <Stack sx={{ ml: 2 }}>
                            <Typography>
                              {noleggioData?.azienda?.referente_cognome_nome}
                            </Typography>
                            <Typography>
                              {noleggioData?.azienda?.referente_telefono}
                            </Typography>
                            <Typography>
                              {noleggioData?.azienda?.referente_email}
                            </Typography>
                            <Typography>
                              {noleggioData?.azienda?.referente_note}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Grid>
                    </>
                  ) : (
                    <></>
                  )}
                </Grid>
                <Divider sx={{ m: 2 }} />
                <Grid container spacing={1} sx={{ mt: 2 }}>
                  <Grid size={{ xs: 4, lg: 2, xl: 1 }}>
                    <Typography sx={{ fontFamily: 'Poppins-Bold' }}>
                      Dettagli Pagamento
                    </Typography>
                  </Grid>
                  <Grid size={{ xs: 4, lg: 2 }}>
                    <Stack flexDirection="row">
                      <Stack>
                        <Typography>SSD:</Typography>
                        <Typography>IBAN:</Typography>
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid size={{ xs: 4, lg: 2 }}>
                    <Stack flexDirection="row">
                      <Stack sx={{ ml: 2 }}>
                        <Typography>
                          {noleggioData?.azienda?.iban != null &&
                          noleggioData?.azienda?.iban != ''
                            ? 'ATTIVO'
                            : 'NON ATTIVO'}
                        </Typography>
                        <Typography>{noleggioData?.azienda?.iban}</Typography>
                      </Stack>
                    </Stack>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Box>
          {user.tipologia === 'A' || user.tipologia === 'M' ? (
            <Box>
              <Accordion sx={{ mt: 0.5 }} disableGutters={true}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography
                    component="h4"
                    variant="h6"
                    sx={{ fontSize: 14 }}
                    color="primary"
                  >
                    DATI FORNITORE
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={1} sx={{ mt: 1 }}>
                    <Grid size={{ xs: 12, md: 1 }}>
                      <Typography sx={{ fontFamily: 'Poppins-Bold' }}>
                        Dati Azienda
                      </Typography>
                    </Grid>
                    <Grid size={{ xs: 12, md: 2 }}>
                      <Stack flexDirection="row">
                        <Stack>
                          <Typography>Ragione Sociale:</Typography>
                          <Typography>Codice Fiscale:</Typography>
                          <Typography>P.IVA:</Typography>
                        </Stack>
                      </Stack>
                    </Grid>
                    <Grid size={{ xs: 12, md: 2 }}>
                      <Stack flexDirection="row">
                        <Stack sx={{ ml: 2 }}>
                          <Typography>
                            {noleggioData?.fornitore?.ragione_sociale}
                          </Typography>
                          <Typography>{noleggioData?.fornitore?.cf}</Typography>
                          <Typography>
                            {noleggioData?.fornitore?.piva}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Grid>
                    <Grid
                      size={{ xs: 0, lg: 1 }}
                      sx={{ display: { xs: 'none', lg: 'block' } }}
                    ></Grid>
                    <Grid size={{ xs: 12, md: 1 }}>
                      <Typography sx={{ fontFamily: 'Poppins-Bold' }}>
                        Indirizzo
                      </Typography>
                    </Grid>
                    <Grid size={{ xs: 12, md: 1 }}>
                      <Stack flexDirection="row">
                        <Stack>
                          <Typography>Ind. Sede:</Typography>
                        </Stack>
                      </Stack>
                    </Grid>
                    <Grid size={{ xs: 12, md: 3 }}>
                      <Stack flexDirection="row">
                        <Stack sx={{ ml: 2 }}>
                          <Typography>{getIndirizzoSedeFornitore()}</Typography>
                        </Stack>
                      </Stack>
                    </Grid>
                  </Grid>
                  {noleggioData?.firmatario != null ? (
                    <>
                      <Grid container spacing={1} sx={{ mt: 2 }}>
                        <Grid size={{ xs: 12, md: 1 }}>
                          <Typography sx={{ fontFamily: 'Poppins-Bold' }}>
                            Firmatario
                          </Typography>
                        </Grid>
                        <Grid size={{ xs: 12, md: 5 }}>
                          <Stack flexDirection="row">
                            <Stack>
                              <Typography>
                                {noleggioData?.firmatario}
                              </Typography>
                            </Stack>
                          </Stack>
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <></>
                  )}
                </AccordionDetails>
              </Accordion>
            </Box>
          ) : (
            <></>
          )}

          <Box>
            <Accordion sx={{ mt: 0.5 }} disableGutters={true}>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography
                  component="h4"
                  variant="h6"
                  sx={{ fontSize: 14 }}
                  color="primary"
                >
                  DOCUMENTI
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <DocumentiFattureGrid
                  fatture={noleggioData?.fatture ?? []}
                  documenti={noleggioData?.documenti ?? []}
                  onDownloadDocumento={onDownloadDocumento}
                  onDownloadFattura={onDownloadFattura}
                />
              </AccordionDetails>
            </Accordion>
          </Box>

          {(noleggioData.id_stato === 'TE' ||
            noleggioData.id_stato === 'FI') && (
            <>
              {user?.tipologia == 'F' ? (
                <Box>
                  <Accordion sx={{ mt: 0.5 }} disableGutters={true}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                      <Typography
                        component="h4"
                        variant="h6"
                        sx={{ fontSize: 14 }}
                        color="primary"
                      >
                        INTERVENTI DI MANUTENZIONE
                      </Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                      <Button
                        variant="contained"
                        size="small"
                        color="white"
                        onClick={onNewIntervento}
                        sx={{ fontFamily: 'Poppins-Bold', lineHeight: 2 }}
                        startIcon={<Add />}
                      >
                        AGGIUNGI NOTA MANUTENZIONE
                      </Button>
                      <CardContent sx={{ mt: 2 }}>
                        {isInterventiLoading && (
                          <Stack
                            flexDirection="row"
                            alignItems="center"
                            justifyContent={'center'}
                          >
                            <CircularProgress size={30} />
                          </Stack>
                        )}
                        {!isInterventiLoading && (
                          // <Box>
                          //   {interventi.length > 0 && (
                          //     <Timeline
                          //       position="right"
                          //       sx={{
                          //         [`& .${timelineItemClasses.root}:before`]: {
                          //           flex: 0,
                          //           padding: 0,
                          //         },
                          //       }}
                          //     >
                          //       {interventi.map((intervento, index) => (
                          //         <TimelineItem key={index}>
                          //           <TimelineSeparator>
                          //             <TimelineDot color="primary" />
                          //             {index < interventi.length - 1 && (
                          //               <TimelineConnector
                          //                 sx={{
                          //                   backgroundColor: 'primary.main',
                          //                 }}
                          //               />
                          //             )}
                          //           </TimelineSeparator>
                          //           <TimelineContent>
                          //             <Stack
                          //               flexDirection="row"
                          //               alignItems="center"
                          //             >
                          //               <Box>
                          //                 <Typography
                          //                   sx={{
                          //                     fontFamily: 'Poppins-Bold',
                          //                   }}
                          //                 >
                          //                   Manutenzione Ordinaria
                          //                 </Typography>
                          //                 <Typography variant="caption">
                          //                   {formatStringDate(intervento.data)}
                          //                 </Typography>
                          //               </Box>
                          //               {intervento.has_image ? (
                          //                 <Stack
                          //                   flexDirection="row"
                          //                   alignItems={'center'}
                          //                   sx={{ ml: 2 }}
                          //                 >
                          //                   <IconButton
                          //                     sx={{
                          //                       border: 'none',
                          //                       color: 'primary.main',
                          //                       mr: 1,
                          //                     }}
                          //                     onClick={() => {
                          //                       onDownloadCertificato(
                          //                         intervento.id
                          //                       );
                          //                     }}
                          //                   >
                          //                     <Description />
                          //                   </IconButton>
                          //                   Certificato di Manutenzione
                          //                 </Stack>
                          //               ) : (
                          //                 <> </>
                          //               )}
                          //               <Box sx={{ flexGrow: 1 }} />
                          //               <IconButton
                          //                 size="small"
                          //                 sx={{ border: 'none' }}
                          //                 onClick={() =>
                          //                   onViewIntervento(intervento)
                          //                 }
                          //               >
                          //                 <ArrowForwardIos />
                          //               </IconButton>
                          //             </Stack>
                          //             {index < interventi.length - 1 && (
                          //               <Divider sx={{ mt: 2 }} />
                          //             )}
                          //           </TimelineContent>
                          //         </TimelineItem>
                          //       ))}
                          //     </Timeline>
                          //   )}
                          //   {interventi.length == 0 && (
                          //     <Typography variant="caption">
                          //       Nessun intervento di manutenzione presente
                          //     </Typography>
                          //   )}
                          // </Box>
                          <InterventiTable
                            data={interventi}
                            isLoading={false}
                            onDownloadCertificato={onDownloadCertificato}
                          />
                        )}
                      </CardContent>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              ) : (
                <></>
              )}

              {user?.tipologia == 'A' ? (
                <Box>
                  <Accordion sx={{ mt: 0.5 }} disableGutters={true}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                      <Typography
                        component="h4"
                        variant="h6"
                        sx={{ fontSize: 14 }}
                        color="primary"
                      >
                        SCADENZIARIO
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <ScadenziarioTable rows={noleggioData.scadenziario} />
                    </AccordionDetails>
                  </Accordion>
                </Box>
              ) : (
                <></>
              )}
            </>
          )}
        </Grid>
      </Grid>

      <DialogIntervento
        idRichiesta={noleggioData.id}
        intervento={selectedIntervento}
        open={dialogManutazioneOpen}
        onClose={() => {
          setDialogManutazioneOpen(false);
        }}
        onSaved={onInterventoSaved}
        onDeleted={onInterventoDeleted}
        onDownloadCertificato={onDownloadCertificato}
      />

      <DialogRichiestaModificaNoleggio
        setNoleggioData={setNoleggioData}
        noleggioData={noleggioData}
        open={dialogRichiestaModificaOpen}
        onClose={() => {
          setDialogRichiestaModificaOpen(false);
        }}
        onSaved={onInoltraModifica}
      />
    </Box>
  );
}
