import { Box, Stack, Typography, Grid2 as Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import LoadingMask from '../../components/LoadingMask';
import ActionErrorMessage from '../../components/ActionErrorMessage';
import { getListaComunicazioni } from '../../redux/comunicazioni/api';
import CardComunicazione from './components/CardComunicazione';
import ModalComunicazione from './components/ModalComunicazione';
import { useLocation } from 'react-router-dom';

export default function Comunicazioni() {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [comunicazioniData, setComunicazioniData] = useState(null);
  const [loadingError, setLoadingError] = useState(null);
  const [showModalComunicazione, setShowModalComunicazione] = useState(false);
  const [itemModalComunicazione, setItemModalComunicazione] = useState(null);
  const [locationStateId, setLocationStateId] = useState(null);

  const dataLoad = async () => {
    setIsLoading(true);
    try {
      const response = await getListaComunicazioni();
      setComunicazioniData(response);
      if (locationStateId) {
        let tmpItem = response.find(item => item.id === locationStateId);
        if (tmpItem) {
          setItemModalComunicazione(tmpItem);
        }
      }
    } catch (error) {
      setLoadingError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    dataLoad();
  }, []);

  useEffect(() => {
    if (location?.state?.id) {
      setLocationStateId(location?.state?.id);
      if (comunicazioniData?.length > 0) {
        let tmpItem = comunicazioniData.find(
          item => item.id === location?.state?.id
        );
        if (tmpItem) {
          setItemModalComunicazione(tmpItem);
        }
      }
    }
  }, [location]);

  useEffect(() => {
    if (itemModalComunicazione) {
      setShowModalComunicazione(true);
    }
  }, [itemModalComunicazione]);

  return (
    <>
      <Box sx={{ p: 3 }}>
        <Stack
          direction="row"
          spacing={2}
          sx={{ mb: 2, justifyContent: 'space-between' }}
        >
          <Box>
            <Typography component="h2" variant="h4">
              News e Comunicazioni
            </Typography>
            <Typography variant="body1">
              Visualizza le comunicazioni operative.
            </Typography>
          </Box>
        </Stack>
        <ModalComunicazione
          showModalComunicazione={showModalComunicazione}
          setShowModalComunicazione={setShowModalComunicazione}
          itemModalComunicazione={itemModalComunicazione}
          setItemModalComunicazione={setItemModalComunicazione}
        />
        {loadingError != null && <ActionErrorMessage error={loadingError} />}
        {isLoading ? <LoadingMask /> : <></>}
      </Box>
      <Grid
        sx={{ backgroundColor: 'rgb(245, 246, 250)', pt: 2 }}
        container
        spacing={2}
      >
        {comunicazioniData &&
          comunicazioniData.map((item, _index) => {
            return (
              <CardComunicazione
                key={_index}
                item={item}
                setItemModalComunicazione={setItemModalComunicazione}
              />
            );
          })}
      </Grid>
    </>
  );
}
