import Stack from '@mui/material/Stack';
import { Box, Typography, AppBar, Button, useTheme } from '@mui/material';
import LogoImg from '../../assets/img/Logo Doinn Rental Division.png';
import { useEffect, useState } from 'react';
import Logo from '../../assets/img/Logo Doinn Rental Division.png';
import { useLocation, useNavigate } from 'react-router-dom';
import RegisterForm from './components/RegisterForm';

export default function Register() {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  const [userRegistered, setUserRegistered] = useState(false);

  const onAccountRegistered = () => {
    setUserRegistered(true);
  };

  useEffect(() => {
    if (!state || !state.id) {
      navigate('/');
    }
  }, [state, navigate]);

  return (
    <Box sx={{ height: '100vh', width: '100%' }}>
      {!userRegistered ? (
        <Box>
          <AppBar
            position="static"
            elevation={0}
            sx={{
              px: 2,
              //gradient white on left and primary on right
              background:
                'linear-gradient(to left, #ffffff, ' +
                theme.palette.background.primaryLight +
                ')',
            }}
          >
            <Stack
              flexDirection={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
              sx={{ p: 2 }}
            >
              <Box>
                <Typography variant="h5" fontWeight="bold" color="textPrimary">
                  Benvenuto,
                </Typography>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  sx={{ mt: 1 }}
                >
                  Registrati per accedere alla piattaforma Rental Doinn
                </Typography>
              </Box>

              <img
                src={Logo}
                alt="Circle Illustration"
                style={{
                  height: '60px',
                }}
              />
            </Stack>
          </AppBar>
          <Box sx={{ flexGrow: 1 }}>
            <RegisterForm
              idRichiesta={state?.id}
              onAccountRegistered={onAccountRegistered}
            />
          </Box>
        </Box>
      ) : (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ p: 2, height: '100%' }}
          flexDirection={'column'}
        >
          <img
            src={LogoImg}
            alt="Logo"
            style={{ height: '110px', marginBottom: '50px' }}
          />
          <Box
            sx={{
              width: '600px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography variant="h6" fontWeight="bold" color="textPrimary">
              Registrazione Completata
            </Typography>
            <Typography
              variant="body1"
              color="textSecondary"
              sx={{ mt: 1, textAlign: 'center' }}
            >
              La tua registrazione è stata completata. Accedi alla piattaforma
              Rental Doinn tramite le credenziali che ti abbiamo fornito via
              mail.
            </Typography>
          </Box>
          <Button
            color="secondary"
            variant="contained"
            sx={{ mt: 10, minWidth: '150px' }}
            size="small"
            onClick={() => navigate('/login')}
          >
            ACCEDI
          </Button>
        </Stack>
      )}
    </Box>
  );
}
