import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { Close } from '@mui/icons-material';
import FornitoreCard from './FornitoreCard';

export default function CreaFornitoreDialog({ onSaved, open, onClose }) {
  const [fornitoreData, setFornitoreData] = useState(null);
  const fornitoreCardRef = useRef(null);

  const handleSave = () => {
    fornitoreCardRef.current.onSalva();
  };

  useEffect(() => {
    setFornitoreData(null);
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'hidden',
        }}
      >
        <DialogTitle>
          <Stack
            direction="row"
            spacing={2}
            justifyContent={'space-between'}
            alignItems="center"
          >
            <Typography variant="h6" color="primary">
              Nuovo Fornitore
            </Typography>
            <Stack direction="row" spacing={2}>
              <Button variant="contained" size="small" onClick={handleSave}>
                SALVA
              </Button>

              <IconButton size="small" onClick={onClose}>
                <Close />
              </IconButton>
            </Stack>
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ flexGrow: 1, overflowY: 'auto' }}>
          <FornitoreCard
            ref={fornitoreCardRef}
            isNewFornitore
            fornitoreData={fornitoreData}
            onSaved={onSaved}
            embedded
          />
        </DialogContent>
      </Box>
    </Dialog>
  );
}
