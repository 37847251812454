import { createSlice } from '@reduxjs/toolkit';
import { getClienti } from './operations';
import {
  createAsyncState,
  handlePending,
  handleFulfilled,
  handleRejected,
} from '../base';

const initialState = {
  clienti: [],
  getClientiAction: createAsyncState(),
};

const clientiSlice = createSlice({
  name: 'clienti',
  initialState,

  extraReducers: builder => {
    builder
      .addCase(getClienti.pending, state => {
        handlePending(state.getClientiAction);
      })
      .addCase(getClienti.fulfilled, (state, action) => {
        state.clienti = action.payload;
        handleFulfilled(state.getClientiAction, action);
      })
      .addCase(getClienti.rejected, (state, action) => {
        handleRejected(state.getClientiAction, action);
      });
  },
});

export const clientiReducer = clientiSlice.reducer;
