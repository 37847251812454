import { Snackbar as MuiSbackbar, Alert } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { hideSnackbar } from '../redux/app/slice';

const Snackbar = () => {
  const dispatch = useDispatch();
  const { snackbarConfig } = useSelector(state => state.app);

  const handleClose = () => {
    dispatch(hideSnackbar());
  };

  return snackbarConfig != null ? (
    <MuiSbackbar
      open={snackbarConfig.open}
      autoHideDuration={snackbarConfig.duration}
      onClose={handleClose}
      anchorOrigin={snackbarConfig.position}
    >
      <Alert
        onClose={handleClose}
        severity={snackbarConfig.severity}
        sx={{ width: '100%' }}
      >
        {snackbarConfig.message}
      </Alert>
    </MuiSbackbar>
  ) : null;
};

export default Snackbar;
