import { Box, Stack, useTheme } from '@mui/material';
import IntestazioneGrafici from './IntestazioneGrafici';
import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';

export default function GraficoBarre({
  titolo,
  sottotitolo,
  value,
  horizontal = false,
}) {
  const [chartData, setChartData] = useState([]);
  const [xAxisData, setXAxisData] = useState([]);
  const theme = useTheme();

  useEffect(() => {
    if (value) {
      setXAxisData(formatAxisData(value));
      setChartData(formatChartData(value));
    } else {
      setXAxisData([]);
      setChartData([]);
    }
  }, [value]);

  const formatChartData = data => {
    return Object.values(data).map(item => item.counter);
  };

  const formatAxisData = data => {
    return Object.values(data).map(item => item.nome);
  };

  return (
    <Stack sx={{ flex: 1 }}>
      <IntestazioneGrafici titolo={titolo} sottotitolo={sottotitolo} />
      {chartData?.length > 0 && xAxisData?.length > 0 && (
        <Box
          sx={{
            display: 'flex',
            position: 'relative',
            justifyContent: 'center',
            mt: 2,
            flexGrow: 1,
            maxHeight: '200px',
          }}
        >
          <Bar
            data={{
              labels: xAxisData,
              datasets: [
                {
                  label: 'Numero',
                  data: chartData,
                  backgroundColor: theme.palette.primary.main,
                  barThickness: 40,
                },
              ],
            }}
            options={{
              indexAxis: horizontal ? 'y' : 'x',
              plugins: {
                legend: {
                  display: false,
                },
              },
              scales: {
                x: {
                  grid: {
                    display: false,
                  },
                },
                y: {
                  grid: {
                    display: false,
                  },
                },
              },
            }}
          />
        </Box>
      )}
    </Stack>
  );
}
