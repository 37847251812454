import { z } from 'zod';
import useZod from '../../../hooks/useZod';

const formSchema = z.object({
  ragione_sociale: z
    .string()
    .min(1, { message: 'La ragione sociale è obbligatoria' }),
  cf: z.string().min(11, { message: 'Codice Fiscale non valido' }),
  piva: z.string().min(11, { message: 'Partita IVA non valida' }),
  sede_indirizzo: z.string().min(1, { message: 'Indirizzo non valido' }),
  sede_civico: z.string().min(1, { message: 'Numero civico non valido' }),
  sede_cap: z.string().min(5, { message: 'CAP non valido' }),
  sede_citta: z.string().min(1, { message: 'Città non valida' }),
  sede_provincia: z.string().min(2, { message: 'Provincia non valida' }),
  // sede_latitudine: z.string().min(1, { message: 'Latitudine non valida' }),
  // sede_longitudine: z.string().min(1, { message: 'Latitudine non valida' }),
});

const useInserisciDatiFornitore = fornitore => {
  const {
    formData,
    setFormData,
    errors,
    handleInputChange,
    validate,
    resetErrors,
    isValid,
  } = useZod({
    schema: formSchema,
    initialFormData: fornitore || {
      ragione_sociale: '',
      cf: '',
      piva: '',
      sede_indirizzo: '',
      sede_civico: '',
      sede_cap: '',
      sede_citta: '',
      sede_provincia: '',
      sede_latitudine: '',
      sede_longitudine: '',
    },
  });

  const handleSubmit = ({ requiresAll = false, fakeRun = false } = {}) => {
    if (!requiresAll) {
      //se tutti i valori sono empty non valido
      if (
        !Object.values(formData ?? {}).some(
          value => value !== '' && value !== false
        )
      ) {
        resetErrors();
        return 0;
      }
    }

    const validation = validate({ fakeRun: fakeRun });
    if (validation.success) {
      return 1;
    }

    return -1;
  };
  return {
    setFormData,
    formData,
    errors,
    handleInputChange,
    handleSubmit,
    isValid,
  };
};

export default useInserisciDatiFornitore;
