import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Box,
  Stack,
  Typography,
  IconButton,
  FormControl,
  FormLabel,
  TextField,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { hideAlertDialog } from '../redux/app/slice';
import { Close } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import CaricaFile from '../pages/Noleggi/components/CaricaFile';
import ActionErrorMessage from './ActionErrorMessage';

const AlertDialog = () => {
  const dispatch = useDispatch();
  const { alertDialogConfig } = useSelector(state => state.app);
  const [note, setNote] = useState('');
  const [noteError, setNoteError] = useState('');
  const [file, setFile] = useState(null);
  const [fileError, setFileError] = useState('');

  const handleClose = () => {
    dispatch(hideAlertDialog());
  };

  const validate = () => {
    if (
      alertDialogConfig?.showNoteInput &&
      alertDialogConfig?.noteRequired &&
      note === ''
    ) {
      setNoteError('Inserire una nota');
      return false;
    }

    if (
      alertDialogConfig?.showFileInput &&
      alertDialogConfig?.fileRequired &&
      file == null
    ) {
      setFileError('Caricare un file');
      return false;
    }

    return true;
  };

  const onConfirm = () => {
    if (!validate()) {
      return;
    }

    if (alertDialogConfig.onConfirm) {
      alertDialogConfig.onConfirm({ note: note, file: file });
    }

    handleClose();
  };

  useEffect(() => {
    if (alertDialogConfig != null) {
      setNote('');
      setNoteError('');
      setFile(null);
      setFileError('');
    }
  }, [alertDialogConfig]);

  return alertDialogConfig != null ? (
    <Dialog open={alertDialogConfig.open} onClose={handleClose} fullWidth>
      <Box sx={{ backgroundColor: 'background.default', p: 2 }}>
        <DialogContent>
          <Stack
            sx={{ position: 'absolute', top: 5, right: 5 }}
            direction="row"
            spacing={2}
          >
            <IconButton size="small" onClick={handleClose}>
              <Close />
            </IconButton>
          </Stack>
          <Stack justifyContent={'center'} alignItems="center" spacing={0}>
            <Typography
              variant="body1"
              fontWeight={'bold'}
              sx={{ textAlign: 'center' }}
            >
              {alertDialogConfig.title}
            </Typography>
            <Typography variant="body1" sx={{ textAlign: 'center' }}>
              {alertDialogConfig.message}
            </Typography>
          </Stack>
          {alertDialogConfig?.showNoteInput ? (
            <>
              <FormControl
                fullWidth
                sx={{
                  alignItems: 'start!important',
                  alignContent: 'start',
                  display: 'flex',
                  mt: 4,
                }}
              >
                <FormLabel htmlFor="note" sx={{ width: '120px' }}>
                  Note
                </FormLabel>
                <TextField
                  multiline
                  fullWidth
                  rows={3}
                  placeholder="Note"
                  name="note"
                  variant="outlined"
                  value={note}
                  onChange={e => setNote(e.target.value)}
                  sx={{ maxWidth: '500px', width: '100%' }}
                  helperText={noteError}
                  error={noteError !== ''}
                />
              </FormControl>
            </>
          ) : (
            <></>
          )}

          {alertDialogConfig?.showFileInput ? (
            <Box sx={{ mt: 2 }}>
              <CaricaFile
                alreadyPresentMessage="Hai già caricato la proposta firmata. Per sovrascriverla, carica un nuovo file."
                isPresent={false}
                onLoad={file => setFile(file)}
                onCancel={() => setFile(null)}
              />
              <Box sx={{ mt: 1, ml: 1 }}>
                <ActionErrorMessage error={fileError} />
              </Box>
            </Box>
          ) : (
            <></>
          )}
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', mt: 2 }}>
          {alertDialogConfig.btnAnnullaText != null && (
            <Button
              onClick={() => {
                if (alertDialogConfig.onCancel) {
                  alertDialogConfig.onCancel();
                }

                handleClose();
              }}
              variant="outlined"
              size="small"
              sx={{ minWidth: '150px' }}
            >
              {alertDialogConfig.btnAnnullaText ?? 'Annulla'}
            </Button>
          )}
          <Button
            size="small"
            variant="contained"
            sx={{ minWidth: '150px' }}
            onClick={onConfirm}
          >
            {alertDialogConfig.btnConfermaText ?? 'Conferma'}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  ) : null;
};

export default AlertDialog;
