import Grid from '@mui/material/Grid2';
import { DataGrid } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { IconButton, Stack } from '@mui/material';
import { ArrowForwardIos } from '@mui/icons-material';
import { getListaFirmatari } from '../../../redux/noleggi/api';

export default function FirmatariTable({ idFornitore, onEdit }) {
  const [listaFirmatari, setListaFirmatari] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const loadFirmatari = async () => {
    setIsLoading(true);
    let response = await getListaFirmatari(idFornitore);
    setListaFirmatari(response);
    setIsLoading(false);
  };

  useEffect(() => {
    if (idFornitore) {
      loadFirmatari();
    } else {
      setListaFirmatari([]);
    }
  }, [idFornitore]);

  const columns = [
    {
      field: 'cognome_nome',
      headerName: 'Cognome Nome',
      flex: 1,
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
    },
    { field: 'telefono', headerName: 'Telefono', flex: 1 },
    { field: 'qualifica', headerName: 'Qualifica', flex: 1 },
    {
      field: 'azioni',
      headerName: 'Azioni',
      sortable: false,
      headerClassName: 'no-separator',
      cellClassName: 'no-separator',
      disableColumnMenu: true,
      renderCell: params => (
        <Stack
          flexDirection="row"
          gap={1}
          sx={{
            justifyContent: 'start',
            height: '100%',
            alignItems: 'center',
          }}
        >
          <IconButton
            size="small"
            sx={{
              border: 'none',
              backgroundColor: 'transparent',
            }}
            onClick={() => onEdit(params.row)}
          >
            <ArrowForwardIos />
          </IconButton>
        </Stack>
      ),
    },
  ];

  return (
    <Grid container spacing={2} columns={12}>
      <Grid size={{ md: 12, lg: 12 }} sx={{ width: '100%' }}>
        <DataGrid
          onRowDoubleClick={params => onEdit(params.row)}
          autoHeight
          rows={listaFirmatari}
          columns={columns}
          loading={isLoading}
          getRowClassName={params =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
          }
          initialState={{
            pagination: { paginationModel: { pageSize: 20 } },
          }}
          pageSizeOptions={[10, 20, 50]}
          disableColumnResize
          density="standard"
        />
      </Grid>
    </Grid>
  );
}
