import { Box, Stack, Switch, useMediaQuery, useTheme } from '@mui/material';
import CardInserimentoStep from './CardInserimentoStep';
import InserisciDatiCliente from './InserisciDatiCliente';
import CaricaFile from './CaricaFile';
import ConfiguraBeni from './ConfiguraBeni';
import FirmaDocumento from './FirmaDocumento';
import useInserisciDatiCliente from '../hook/useInserisciDatiCliente';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
  useMemo,
} from 'react';
import PersonalizzazioneRata from './PersonalizzazioneRata';
import CaricaFileConElenco from './CaricaFileConElenco';
import useAuth from '../../../hooks/useAuth';

const CompilaDatiRichiestaFornitore = forwardRef(
  (
    {
      noleggio,
      setNoleggio,
      triggerUpdateDati,
      setTriggerUpdateDati,
      onDownloadDocumento,
      onCompilazioneCompletaChanged = null,
    },
    ref
  ) => {
    const { user } = useAuth();
    const styleHiddenStack = { mt: 4, opacity: '0.2', pointerEvents: 'none' };
    const styleVisibleStack = { mt: 0 };
    const styleStack =
      noleggio.id_stato != 'DC' ? styleHiddenStack : styleVisibleStack;

    const useInserisciDatiClienteHook = useInserisciDatiCliente(
      noleggio.azienda
    );
    const personalizzaRataRef = useRef();
    const theme = useTheme();
    const matchLg = useMediaQuery(theme.breakpoints.up('lg'));
    const [deletedPreventivi, setDeletedPreventivi] = useState([]);
    const [newPreventivi, setNewPreventivi] = useState([]);
    const [deletedProposteFirmate, setDeletedProposteFirmate] = useState([]);
    const [newProposteFirmate, setNewProposteFirmate] = useState([]);
    const [preventiviKey, setPreventiviKey] = useState(0);
    const [proposteKey, setProposteKet] = useState(0);

    const resetDocTmp = () => {
      setNewPreventivi([]);
      setDeletedPreventivi([]);
      setNewProposteFirmate([]);
      setDeletedProposteFirmate([]);
      setPreventiviKey(prev => prev + 1);
      setProposteKet(prev => prev + 1);
    };

    const hasOnePreventivoLoaded = useMemo(() => {
      //se somma di (documenti con flg_preventivo e che i loro id non siano dentro deletedPreventivi) + newPreventivi > 0
      return (
        noleggio?.documenti?.filter(
          doc => doc.flg_preventivo && !deletedPreventivi.includes(doc.id)
        ).length +
          newPreventivi.length >
        0
      );
    }, [noleggio?.documenti, deletedPreventivi, newPreventivi]);

    const hasOnePropostaFirmataLoaded = useMemo(() => {
      //se somma di (documenti con flg_proposta e flg_firmato e che i loro id non siano dentro deletedProposteFirmate) + newProposteFirmate > 0
      return (
        noleggio?.documenti?.filter(
          doc =>
            doc.flg_proposta &&
            !deletedProposteFirmate.includes(doc.id) &&
            doc.flg_firmato
        ).length +
          newProposteFirmate.length >
        0
      );
    }, [noleggio?.documenti, deletedProposteFirmate, newProposteFirmate]);

    const validationForm = ({
      requiresAll = false,
      skipValidation = false,
    } = {}) => {
      let datiClienteValidationResult;
      if (!skipValidation) {
        // verifico il form cliente

        datiClienteValidationResult = useInserisciDatiClienteHook.handleSubmit({
          requiresAll: requiresAll,
        });
        if (datiClienteValidationResult == -1) {
          return -1;
        }

        if (!hasOnePreventivoLoaded) {
          return -5;
        }

        //se sono mediatore o fornitore devo caricare la proposta sempre
        //se sono azienda devo caricare la proposta solo se l'ho stampata
        if (
          (noleggio.flg_proposta ||
            user.tipologia == 'M' ||
            user.tipologia == 'F') &&
          !hasOnePropostaFirmataLoaded
        ) {
          return -6;
        }
      } else {
        //se non ho nessun campo inserito, lascio 0 e non lo mando al server
        //se ho anche solo un campo inserito, forzo a 1 cosi lo include nel formData
        datiClienteValidationResult = useInserisciDatiClienteHook.handleSubmit({
          requiresAll: requiresAll,
          fakeRun: true,
        });
        if (datiClienteValidationResult != 0) {
          datiClienteValidationResult = 1;
        }
      }

      if (
        personalizzaRataRef.current.validate(noleggio.flg_personalizza_rata) ===
        false
      ) {
        return -3;
      }

      // genero il form data per la chiamata
      const formData = new FormData();
      if (noleggio.id != null) {
        formData.append('id', noleggio.id);
      }
      if (
        noleggio.flg_step_personalizzazione_avanzato !== undefined &&
        noleggio.flg_step_personalizzazione_avanzato !== null
      ) {
        formData.append(
          'flg_step_personalizzazione_avanzato',
          noleggio.flg_step_personalizzazione_avanzato
        );
      }
      formData.append('id_stato', noleggio.id_stato);
      formData.append('id_step', noleggio.id_step);
      formData.append('durata_mesi', noleggio.durata_mesi);
      formData.append('importo', noleggio.importo);
      formData.append('flg_assicurazione', noleggio.flg_assicurazione);
      formData.append('flg_sdd', noleggio.flg_sdd);
      formData.append('spese_incasso', noleggio.flg_sdd ? 1 : 10);

      if (noleggio.id_firmatario) {
        formData.append('id_firmatario', noleggio.id_firmatario);
      }
      formData.append('flg_personalizza_rata', noleggio.flg_personalizza_rata);
      formData.append('rata_personalizzata', noleggio.rata_personalizzata);
      formData.append(
        'note_personalizzazione',
        noleggio.note_personalizzazione
      );

      if (datiClienteValidationResult == 1) {
        const clienteData = useInserisciDatiClienteHook.formData;

        if (
          !skipValidation &&
          noleggio.flg_sdd &&
          (clienteData.iban == null || clienteData.iban == '')
        ) {
          return -4;
        }

        if (clienteData != null) {
          formData.append('azienda', JSON.stringify(clienteData));
        }
      }

      for (const doc of newPreventivi) {
        formData.append('preventivi[]', doc);
      }
      for (const id of deletedPreventivi) {
        formData.append('deleted_preventivi[]', id);
      }

      for (const doc of newProposteFirmate) {
        formData.append('proposte_firmate[]', doc);
      }
      for (const id of deletedProposteFirmate) {
        formData.append('deleted_proposte_firmate[]', id);
      }

      if (noleggio.dettagli != null) {
        formData.append('dettagli', JSON.stringify(noleggio.dettagli));
      }

      return formData;
    };

    useImperativeHandle(ref, () => ({
      validationForm,
      resetDocTmp,
    }));

    useEffect(() => {
      if (triggerUpdateDati) {
        useInserisciDatiClienteHook.setFormData(noleggio.azienda);
        setTriggerUpdateDati(false);
      }
    }, [triggerUpdateDati]);

    const [stepCompletedResults, setStepCompletedResults] = useState({});
    const notRequiredStep = [5];
    // const showCaricaProposta = noleggio?.flg_proposta ?? false;
    const showCaricaProposta = true; //force a true
    const stepsCount = 5 + (showCaricaProposta ? 1 : 0);

    if (!showCaricaProposta) {
      notRequiredStep.push(6);
    } else {
      delete notRequiredStep[6];
    }

    const onStepCompletedChanged = (step, isCompleted) => {
      if (stepCompletedResults[step] === isCompleted) return;
      setStepCompletedResults(prev => {
        return { ...prev, [step]: isCompleted };
      });
    };

    useEffect(() => {
      const completed = Object.keys(stepCompletedResults).every(
        key =>
          notRequiredStep.includes(parseInt(key)) ||
          stepCompletedResults[key] === true
      );
      if (onCompilazioneCompletaChanged) {
        onCompilazioneCompletaChanged(completed);
      }
    }, [stepCompletedResults, showCaricaProposta]);

    const onSwitchChange = e => {
      if (e.target.checked) {
        setNoleggio(prev => {
          return {
            ...prev,
            flg_personalizza_rata: true,
          };
        });
        if (expandedAccordion !== stepsCount) {
          setExpandedAccordion(5);
        }
      } else {
        setNoleggio(prev => {
          return {
            ...prev,
            flg_personalizza_rata: false,
            rata_personalizzata: 0,
            note_personalizzazione: '',
          };
        });
      }
    };

    const onNewFilesChangesPreventivi = newFiles => {
      setNewPreventivi(newFiles);
    };
    const onDeleteExistingPreventivi = doc => {
      setDeletedPreventivi(prev => {
        return [...prev, doc.id];
      });
      setNoleggio(prev => {
        return {
          ...prev,
          documenti: prev.documenti.filter(d => d.id !== doc.id),
        };
      });
    };

    const onNewFilesChangesProposteFirmate = newFiles => {
      setNewProposteFirmate(newFiles);
    };
    const onDeleteExistingProposteFirmate = doc => {
      setDeletedProposteFirmate(prev => {
        return [...prev, doc.id];
      });
      setNoleggio(prev => {
        return {
          ...prev,
          documenti: prev.documenti.filter(d => d.id !== doc.id),
        };
      });
    };

    const onDownloadExisting = doc => {
      onDownloadDocumento(doc.nome, doc.id);
    };

    const [expandedAccordion, setExpandedAccordion] = useState(1);
    return (
      <Stack sx={styleStack} spacing={1}>
        <CardInserimentoStep
          noleggio={noleggio}
          setNoleggio={setNoleggio}
          total={stepsCount}
          expandedAccordion={expandedAccordion}
          setExpandedAccordion={setExpandedAccordion}
          number={1}
          title="Inserisci Dati Cliente (Conduttore)"
          subtitle=""
          component={
            <InserisciDatiCliente
              hook={useInserisciDatiClienteHook}
              noleggio={noleggio}
              setNoleggio={setNoleggio}
            />
          }
          onCompletedChanged={onStepCompletedChanged}
        />
        <CardInserimentoStep
          noleggio={noleggio}
          setNoleggio={setNoleggio}
          total={stepsCount}
          expandedAccordion={expandedAccordion}
          setExpandedAccordion={setExpandedAccordion}
          number={2}
          title="Carica Preventivo"
          subtitle="Carica il preventivo effettuato al cliente"
          component={
            <CaricaFileConElenco
              key={preventiviKey}
              allowMultiple
              documenti={
                noleggio?.documenti?.filter(doc => doc.flg_preventivo) ?? []
              }
              onNewFilesChanges={onNewFilesChangesPreventivi}
              onDownloadExisting={onDownloadExisting}
              onDeleteExisting={onDeleteExistingPreventivi}
            />
          }
          onCompletedChanged={onStepCompletedChanged}
        />
        <CardInserimentoStep
          noleggio={noleggio}
          setNoleggio={setNoleggio}
          total={stepsCount}
          expandedAccordion={expandedAccordion}
          setExpandedAccordion={setExpandedAccordion}
          number={3}
          title="Configura Beni e Servizi"
          subtitle="Censisci i beni e servizi richiesti al noleggio"
          component={
            <ConfiguraBeni
              noleggio={noleggio}
              beni={noleggio.dettagli ?? []}
              setBeni={beni => {
                setNoleggio(prev => {
                  return {
                    ...prev,
                    dettagli: beni,
                  };
                });
              }}
            />
          }
          onCompletedChanged={onStepCompletedChanged}
        />
        <CardInserimentoStep
          noleggio={noleggio}
          setNoleggio={setNoleggio}
          total={stepsCount}
          expandedAccordion={expandedAccordion}
          setExpandedAccordion={setExpandedAccordion}
          number={4}
          title="Firma Documento"
          subtitle="Inserisci la modalità di firma scelta dal Cliente Finale (è possibile modificarla in seguito)"
          component={
            <FirmaDocumento
              size={matchLg ? 4 : 6}
              noleggio={noleggio}
              setNoleggio={setNoleggio}
              showSelectFirmatario
            />
          }
          onCompletedChanged={onStepCompletedChanged}
        />
        <CardInserimentoStep
          noleggio={noleggio}
          setNoleggio={setNoleggio}
          total={stepsCount}
          expandedAccordion={expandedAccordion}
          setExpandedAccordion={setExpandedAccordion}
          number={5}
          title="Richiedi Personalizzazione Rata"
          extraContent={
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              {/* <Typography sx={{ fontSize: 12 }}>
                Il tuo Canone Mensile attuale è di:
              </Typography>
              <Typography
                color="primary"
                fontWeight={'bold'}
                sx={{ ml: 3, fontSize: 12 }}
              >
                {formatCurrency(noleggio.rataTotale)}/mese per{' '}
                {noleggio.durata_mesi} mesi
              </Typography> */}
              <Switch
                name="flg_sdd"
                checked={noleggio.flg_personalizza_rata}
                onChange={onSwitchChange}
                sx={{ ml: 3 }}
              />
            </Box>
          }
          subtitle="Ti occorre pagare una rata più bassa? Richiedi una personalizzazione del canone mensile inserendo l’importo che vorresti pagare."
          component={
            <PersonalizzazioneRata
              noleggio={noleggio}
              setNoleggio={setNoleggio}
              ref={personalizzaRataRef}
            />
          }
          contentReadOnly={!noleggio.flg_personalizza_rata}
          onCompletedChanged={onStepCompletedChanged}
        />
        {showCaricaProposta ? (
          <CardInserimentoStep
            noleggio={noleggio}
            setNoleggio={setNoleggio}
            total={stepsCount}
            expandedAccordion={expandedAccordion}
            setExpandedAccordion={setExpandedAccordion}
            number={6}
            title="Carica Proposta Firmata"
            subtitle="Carica la proposta firmata"
            component={
              <CaricaFileConElenco
                key={proposteKey}
                allowDeleteExisting={false}
                allowMultiple={false}
                documenti={
                  noleggio?.documenti?.filter(
                    doc => doc.flg_proposta && doc.flg_firmato
                  ) ?? []
                }
                onNewFilesChanges={onNewFilesChangesProposteFirmate}
                onDownloadExisting={onDownloadExisting}
                onDeleteExisting={onDeleteExistingProposteFirmate}
              />
            }
            onCompletedChanged={onStepCompletedChanged}
          />
        ) : (
          <></>
        )}
      </Stack>
    );
  }
);

export default CompilaDatiRichiestaFornitore;
