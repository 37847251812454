import api from '../../config/axiosConfig';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { handleAsyncThunk } from '../asyncWrapper';

/*
 * GET @ preventivatore/admin/utenti
 * response: [utenti]
 */
export const getUtenti = createAsyncThunk(
  'account/getUtenti',
  handleAsyncThunk(async () => {
    const res = await api.get('/preventivatore/admin/utenti');
    return res.data;
  })
);
