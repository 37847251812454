import province from '../../../data/province.json';
import {
  Autocomplete,
  Box,
  FormHelperText,
  MenuItem,
  TextField,
} from '@mui/material';

const SelectProvince = ({
  name,
  placeholder,
  value,
  onChange,
  errors,
  readOnly = false,
}) => {
  return (
    <>
      <Box>
        <Autocomplete
          autoHighlight
          disabled={readOnly}
          fullWidth
          size="small"
          name={name}
          options={province}
          sx={{
            '& .MuiButtonBase-root': {
              padding: '10px',
              height: '10px',
              width: '10px',
              marginRight: '5px',
            },
          }}
          getOptionLabel={option => option.nome}
          value={province.filter(v => v.sigla === value)[0] || null}
          onChange={(e, newVal) => {
            onChange({ target: { name: name, value: newVal?.sigla } });
          }}
          renderInput={params => (
            <TextField placeholder={placeholder} {...params} />
          )}
          getOptionKey={option => option.sigla}
          renderOption={option => (
            <MenuItem
              selected={option.key === value}
              onClick={() => {
                if (option.key === value) return;
                onChange({ target: { name: name, value: option.key } });
              }}
            >
              {province.filter(v => v.sigla === option.key)[0].nome}
            </MenuItem>
          )}
        />

        {errors && (
          <FormHelperText sx={{ color: 'error.main' }}>{errors}</FormHelperText>
        )}
      </Box>
    </>
  );
};

export default SelectProvince;
